import React from "react";
import Card from "react-bootstrap/Card";
import { LoadingCard } from "./Loading";

interface Props<T> {
  content?: ReadonlyArray<T>;
  isEmpty?: boolean;
  message?: string;
  loading?: boolean;
}

class EmptyState<T> extends React.PureComponent<Props<T>> {
  public render() {
    const { content, isEmpty, message = "No content.", loading } = this.props;

    const contentHasValues = Array.isArray(content) && content.length > 0;

    if (contentHasValues && !isEmpty) {
      return this.props.children || null;
    }

    if (loading) {
      return <LoadingCard />;
    }

    return <Card.Body>{message}</Card.Body>;
  }
}

export default EmptyState;
