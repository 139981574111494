import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import DisplayName from "../page/DisplayName";
import { Link } from "react-router-dom";

class ExchangeShare extends Component {
  constructor(props) {
    super(props);

    this.usersRef = firebase.firestore().collection("users");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");
    this.shareRef = firebase
      .firestore()
      .collection("exchange")
      .doc(this.props.match.params.id);
    this.shareOwnersRef = firebase
      .firestore()
      .collection("exchange")
      .doc(this.props.match.params.id)
      .collection("users")
      .orderBy("quantity", "desc");

    this.unsubscribeUsers = null;
    this.unsubscribeShareOwners = null;
    this.unsubscribeShareRef = null;
    this.userAccountsSubscription = null;

    this.state = {
      userMap: {},
      userAccountsMap: {},
      share: {},
      shareOwners: [],
      totalQuantity: 0
    };
  }

  componentDidMount() {
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
    this.unsubscribeShareOwners = this.shareOwnersRef.onSnapshot(
      this.onShareOwnersUpdate
    );
    this.unsubscribeShare = this.shareRef.onSnapshot(this.onShareUpdate);
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  onUsersUpdate = querySnapshot => {
    const userMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL, itemImage, itemHat } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        itemImage,
        itemHat
      };
      userMap[user.id] = user;
    });
    this.setState({
      userMap
    });
  };

  onShareUpdate = () => {
    let share = {};
    this.shareRef.get().then(doc => {
      const { name } = doc.data();
      if (name) {
        share = {
          id: doc.id,
          name
        };
        this.setState({
          share
        });
      }
    });
  };

  onShareOwnersUpdate = querySnapshot => {
    const shareOwners = [];
    let totalQuantity = 0;
    querySnapshot.forEach(doc => {
      const { quantity } = doc.data();
      if (quantity) {
        shareOwners.push({
          id: doc.id,
          quantity
        });
        totalQuantity = totalQuantity + quantity;
      }
    });
    this.setState({
      shareOwners,
      totalQuantity
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  getShareName = () => {
    if (this.state.share.name) {
      return this.state.share.name + "'s owners";
    }
    return "";
  };

  getUser = userId => {
    if (this.state.userMap[userId]) {
      if (this.state.userAccountsMap[userId]) {
        return {
          ...this.state.userMap[userId],
          displayName: this.state.userAccountsMap[userId].displayName,
          photoURL: this.state.userAccountsMap[userId].photoURL
        };
      }
      return this.state.userMap[userId];
    }
    return "";
  };

  render() {
    return (
      <div>
        <div className="panel panel-default">
          <div className="card box">
            <Link to={`/exchange`}>
              <button className="btn btn-primary">Exchange</button>
            </Link>
          </div>
          <div className="card box">
            <div className="display-title-container">
              <div className="card-title h4 display-title">
                {this.getShareName()}
              </div>
              <div className="total-owned">
                Total owned: {this.state.totalQuantity}
              </div>
            </div>
            <table className="table table-or">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Shares</th>
                </tr>
                {this.state.shareOwners.map((owner, index) => (
                  <tr key={index}>
                    <td>
                      <DisplayName user={this.getUser(owner.id)} />
                    </td>
                    <td className="">{owner.quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default ExchangeShare;
