import { faMapMarkerAlt, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestore } from "firebase";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import "../../animate.css";
import { LoadingCard } from "../page/Loading";
import { convertSnapshotToType } from "../util/TypeUtils";
import { Championship, Team } from "./Types";

interface Props {
  championship: Championship;
  winnerRef: firestore.DocumentReference;
}

interface State {
  winner: Team | null;
}

class ChampionshipsWinner extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      winner: null
    };
  }

  public async componentDidMount() {
    const { winnerRef } = this.props;

    this.setState({
      winner: convertSnapshotToType<Team>(await winnerRef.get())
    });
  }

  public async componentDidUpdate(prevProps: Props) {
    const { winnerRef } = this.props;

    if (winnerRef !== prevProps.winnerRef) {
      this.setState({
        winner: convertSnapshotToType<Team>(await winnerRef.get())
      });
    }
  }

  public render() {
    const { championship } = this.props;
    const { winner } = this.state;

    if (!winner) {
      return <LoadingCard />;
    }

    return (
      <>
        <Card.Body className="flip animated delay-2s">
          <h1 className="display-3 text-center bounceIn animated">
            <FontAwesomeIcon icon={faTrophy} className="text-secondary" />
          </h1>
          <h2 className="display-4 text-center bounceIn animated">
            {String(winner.name)}
          </h2>
        </Card.Body>
        <Card.Body className="text-center h6">
          <a
            href={`http://www.google.com/maps?q=${winner.name}+${winner.vicinity}`}
          >
            <FontAwesomeIcon icon={faMapMarkerAlt} /> {String(winner.vicinity)}
          </a>
        </Card.Body>

        <Card.Footer>
          <Row>
            <Col>
              <Link to="/championships">Back</Link>
            </Col>
            {championship.canCheckIn && (
              <Col className="col-auto tada animated delay-5s">
                <Link to="/">You can now check in here!</Link>
              </Col>
            )}
          </Row>
        </Card.Footer>
      </>
    );
  }
}

export default ChampionshipsWinner;
