import moment from "moment";
import React from "react";
import Container from "react-bootstrap/Container";
import ReactDOM from "react-dom";
import { Redirect, Route, Router, Switch } from "react-router-dom";
import App from "./App";
import Admin from "./components/admin/Admin";
import Auth from "./components/auth/Auth";
import ChampionshipsPage from "./components/championships/ChampionshipsPage";
import CoinItems from "./components/coin/CoinItems";
import CoinList from "./components/coin/CoinList";
import CoinTrade from "./components/coin/CoinTrade";
import ChampionshipAdd from "./components/cop/ChampionshipAdd";
import ChampionshipList from "./components/cop/ChampionshipList";
import ChampionshipView from "./components/cop/ChampionshipView";
import EuCountryCount from "./components/europe/EuCountryCount";
import Exchange from "./components/exchange/Exchange";
import ExchangeShare from "./components/exchange/ExchangeShare";
import ExchangeShares from "./components/exchange/ExchangeShares";
import ExchangeTrades from "./components/exchange/ExchangeTrades";
import Help from "./components/help/Help";
import Ledger from "./components/ledger/Ledger";
import ManagePage from "./components/manage/ManagePage";
import Map from "./components/map/Map";
import Navigation from "./components/nav/Navigation";
import Header from "./components/page/Header";
import PubProfile from "./components/profile/PubProfile";
import UserProfile from "./components/profile/UserProfile";
import Ranking from "./components/rankings/Ranking";
import Search from "./components/search/Search";
import TourAdd from "./components/tour/TourAdd";
import TourList from "./components/tour/TourList";
import TourView from "./components/tour/TourView";
import history from "./components/util/History";
import "./index.scss";
import "./snow.scss";

require("moment-duration-format")(moment);

ReactDOM.render(
  <Router history={history}>
    <Route component={Header} />
    <Route component={Navigation} />
    <Container>
      <Switch>
        <Route exact path="/leaderboard" component={App} />
        <Route exact path="/auth" component={Auth} />
        <Route exact path="/profile/:id" component={UserProfile} />
        <Route exact path="/location/:id" component={PubProfile} />
        <Route path="/championships" component={ChampionshipsPage} />
        <Route exact path="/search" component={Search} />
        <Route exact path="/cop" component={ChampionshipList} />
        <Route exact path="/cop/add/:id" component={ChampionshipAdd} />
        <Route exact path="/cop/view/:id/:uid" component={ChampionshipView} />
        <Route exact path="/coin/balances/" component={CoinList} />
        <Route exact path="/coin/trade/:uid" component={CoinTrade} />
        <Route exact path="/coin/items/:uid" component={CoinItems} />
        <Route
          path="/coin"
          component={() => <Redirect to="/coin/balances" />}
        />
        <Route exact path="/admin/:uid" component={Admin} />
        <Route exact path="/tour" component={TourList} />
        <Route exact path="/tour/view/:id" component={TourView} />
        <Route exact path="/tour/add/:id" component={TourAdd} />
        <Route exact path="/ledger" component={Ledger} />
        <Route exact path="/pubs" component={Ranking} />
        <Route exact path="/exchange/market" component={Exchange} />
        <Route exact path="/exchange/shares/:uid" component={ExchangeShares} />
        <Route exact path="/exchange/share/:id" component={ExchangeShare} />
        <Route exact path="/exchange/trade/:uid" component={ExchangeTrades} />
        <Route
          path="/exchange"
          component={() => <Redirect to="/exchange/market" />}
        />
        <Route exact path="/manage" component={ManagePage} />
        <Route exact path="/eu-country-count" component={EuCountryCount} />
        <Route exact path="/map" component={Map} />
        <Route exact path="/help" component={Help} />
        <Route
          exact
          path="/"
          component={() => <Redirect to="/leaderboard" />}
        />
        <Route component={() => <p>404! This page doesn't exist :(</p>} />
      </Switch>
    </Container>
  </Router>,
  document.getElementById("root")
);
