import {
  faCheck,
  faCheckDouble,
  faHandHoldingUsd,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import Col from "react-bootstrap/Col";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import { User } from "../../Types";
import PubCoin from "../coin/PubCoin";
import UserAvatar from "../page/UserAvatar";
import "../sessions/Sessions.scss";
import { Session } from "../sessions/Types";
import { DisplayOptions } from "./Leaderboard";
import "./Leaderboard.scss";

interface Props {
  position: number;
  user: User;
  session?: Session;
  time: number;
  display: DisplayOptions;
  flippedProps: object;
}

interface State {}

class LeaderboardRow extends React.PureComponent<Props, State> {
  public render() {
    const { position, user, session, time, flippedProps } = this.props;
    const doubleCoin = session && session.doubleCoin;
    const bonus = session && session.coinRate > 12;
    const shareholder = session && session.shareRate > 0;
    const checkedIn = session !== undefined;

    const duration = moment.duration(time) as any;

    return (
      <ListGroupItem
        className="leaderboard-row"
        variant={checkedIn ? "success" : undefined}
        {...flippedProps}
      >
        <Row className={classNames("flex-nowrap")}>
          <Col className="col-auto d-flex align-items-center lead">
            {position}
          </Col>
          <Col className="col-auto d-flex align-items-center p-0">
            <UserAvatar user={user} photoURL={user.photoURL} />
          </Col>
          <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
            {user.displayName}
            {session && (
              <small className="text-truncate">
                {session.locationName}
                {doubleCoin ? (
                  <FontAwesomeIcon
                    className="ml-1 text-primary animated zoomIn"
                    icon={faCheckDouble}
                  />
                ) : (
                  <FontAwesomeIcon
                    className="ml-1 text-success animated zoomIn"
                    icon={faCheck}
                  />
                )}
                {shareholder && (
                  <FontAwesomeIcon
                    className="ml-1 text-info animated zoomIn delay-1s"
                    icon={faHandHoldingUsd}
                  />
                )}
                {bonus && (
                  <FontAwesomeIcon
                    className="ml-1 text-secondary animated zoomIn delay-2s"
                    icon={faStar}
                  />
                )}
              </small>
            )}
          </Col>

          <Col className="col-auto d-flex flex-column align-items-end justify-content-center text-truncate">
            <PubCoin amount={user.coin} />
            <small className="text-truncate">{duration.format()}</small>
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}

export default LeaderboardRow;
