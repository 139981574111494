import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer } from "@fortawesome/free-solid-svg-icons";

interface Props {
  amount?: number;
  previousAmount?: number;
}

interface State {
  increase?: boolean;
  decrease?: boolean;
  amount: number;
}

class PubCoin extends React.PureComponent<Props, State> {
  private interval?: number;

  public constructor(props: Props) {
    super(props);
  }

  public render() {
    if (typeof this.props.amount !== "number") {
      return null;
    }

    return (
      <span>
        <FontAwesomeIcon icon={faBeer} /> {Math.round(this.props.amount)}
      </span>
    );
  }
}

export default PubCoin;
