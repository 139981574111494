import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
/* global google */

class PubFinder extends React.PureComponent {
  constructor(props) {
    super(props);

    this.autocompleteInput = React.createRef();
    this.autocomplete = null;

    this.state = {
      place: null
    };
  }

  componentDidMount() {
    const circle = new google.maps.Circle({
      center: { lat: 51.4809393, lng: -3.1781957 },
      radius: 2000
    });

    this.autocomplete = new google.maps.places.Autocomplete(
      this.autocompleteInput.current,
      {
        types: ["establishment"],
        bounds: circle.getBounds()
      }
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
  }

  render() {
    return (
      <Form onSubmit={this.handleFormSubmitted}>
        <Form.Row>
          <Col>
            <Form.Control ref={this.autocompleteInput} placeholder="Pub name" />
          </Col>
          <Col xs="auto">
            <Button type="submit" disabled={!this.state.place}>
              Add
            </Button>
          </Col>
        </Form.Row>
      </Form>
    );
  }

  handlePlaceChanged = test => {
    const place = this.autocomplete.getPlace();

    if (!place || !place.place_id) {
      console.error("Place ID is missing, so skipping this place.");
      return;
    }

    this.setState({
      place: {
        name: place.name || "Unknown Place",
        placeId: place.place_id || "",
        rating: place.rating || 0,
        vicinity: place.vicinity || "Unknown Place",
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      }
    });
  };

  handleFormSubmitted = event => {
    event.preventDefault();
    this.props.onPubSelected(this.state.place);
    this.setState({ place: null });

    this.autocompleteInput.current.value = "";
  };
}

export default PubFinder;
