import { Location } from "./Types";
import React from "react";
import Button from "react-bootstrap/Button";
import { auth, functions } from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  selectedLocation?: Location;
}

interface State {
  loading: boolean;
}

class CheckInButton extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = { loading: false };
  }

  public render() {
    const { selectedLocation } = this.props;
    const { loading } = this.state;
    const currentUser = auth().currentUser;

    if (!currentUser || !selectedLocation) {
      return <Button disabled>Check in</Button>;
    }

    return (
      <Button disabled={loading} onClick={this.handleCheckIn}>
        Check in {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
      </Button>
    );
  }

  private handleCheckIn = async () => {
    const { selectedLocation } = this.props;

    if (!selectedLocation) {
      return;
    }

    this.setState({ loading: true });
    navigator.geolocation.getCurrentPosition(
      async location => {
        try {
          await functions().httpsCallable("checkIn")({
            selectedLocation: selectedLocation.id,
            userLocation: {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            }
          });
        } catch (err) {
          console.error(err);
          alert(err.message);
        } finally {
          this.setState({ loading: false });
        }
      },
      err => {
        alert(
          "Unable to get your location. Make sure you've enabled GPS! " +
            err.message
        );
        this.setState({ loading: false });
      },
      {
        maximumAge: 1000,
        enableHighAccuracy: true
      }
    );
  };
}

export default CheckInButton;
