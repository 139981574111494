import React from "react";
import "./Help.css";
import Card from "react-bootstrap/Card";

class Help extends React.PureComponent {
  render() {
    return (
      <Card>
        <Card.Header as="h5">Welcome to Pub Tracker</Card.Header>
        <Card.Body>
          <div className={"help-section-header"}>
            Symbols used on this site:
          </div>
          <div>
            <div className={"help-icon"}>
              <img
                src={"http://maps.google.com/mapfiles/ms/icons/red-dot.png"}
                alt="Red map marker"
              />
            </div>
            <div className={"help-icon-description"}>
              The pub you are currently checked into
            </div>
          </div>
          <div>
            <div className={"help-icon"}>
              <img
                src={"http://maps.google.com/mapfiles/ms/icons/orange-dot.png"}
                alt="Orange map marker"
              />
            </div>
            <div className={"help-icon-description"}>
              Other users are checked into this pub
            </div>
          </div>
          <div>
            <div className={"help-icon"}>
              <img
                src={"http://maps.google.com/mapfiles/ms/icons/green-dot.png"}
                alt="Green map marker"
              />
            </div>
            <div className={"help-icon-description"}>
              You've visited this pub previously
            </div>
          </div>
          <div>
            <div className={"help-icon"}>
              <img
                src={"http://maps.google.com/mapfiles/ms/icons/blue-dot.png"}
                alt="Blue map marker"
              />
            </div>
            <div className={"help-icon-description"}>
              You haven't visited this pub yet
            </div>
          </div>
          <div>
            <div className={"help-icon"}>
              <img
                src={"http://maps.google.com/mapfiles/ms/icons/yellow-dot.png"}
                alt="Yellow map marker"
              />
            </div>
            <div className={"help-icon-description"}>
              The bonus pub of the day
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

export default Help;
