import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import { getTime, getDateTime } from "../util/Utils";
import UserAvatar from "../page/UserAvatar";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import moment from "moment";
import EmptyState from "../page/EmptyState";

class Ledger extends Component {
  constructor(props) {
    super(props);

    this.ledgerRef = firebase.firestore().collection("ledger");
    this.usersRef = firebase.firestore().collection("users");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");
    this.locationsRef = firebase.firestore().collection("locations");
    this.exchangeRef = firebase.firestore().collection("exchange");
    this.exchangeTradesRef = firebase.firestore().collection("exchangeTrades");

    this.state = {
      entries: [],
      userMap: {},
      userAccountsMap: {},
      locationMap: {},
      exhchangeMap: {},
      tradesMap: {}
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribeLedger = this.ledgerRef
      .orderBy("dateTime", "desc")
      .limit(200)
      .onSnapshot(this.onLedgerUpdate);
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
    this.locationSubscription = this.locationsRef.onSnapshot(
      this.onLocationsUpdate
    );
    this.exchangeSubscription = this.exchangeRef.onSnapshot(
      this.onExchangeUpdate
    );
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
    this.exchangeTradesSubscription = this.exchangeTradesRef.onSnapshot(
      this.onExchangeTradesUpdate
    );
  }

  componentWillUnmount() {
    this.unsubscribeLedger && this.unsubscribeLedger();
    this.unsubscribeUsers && this.unsubscribeUsers();
    this.userAccountsSubscription && this.userAccountsSubscription();
    this.locationsSubscription && this.locationsSubscription();
    this.exchangeSubscription && this.exchangeSubscription();
    this.exchangeTradesSubscription && this.exchangeTradesSubscription();
  }

  onLedgerUpdate = querySnapshot => {
    const entries = [];

    querySnapshot.forEach(doc => {
      const {
        type,
        subType,
        name,
        uid,
        initialValue,
        value,
        dateTime,
        otherData
      } = doc.data();
      const entry = {
        id: doc.id,
        type,
        subType,
        name,
        uid,
        initialValue,
        value,
        dateTime,
        otherData
      };
      entries.push(entry);
    });

    this.setState({
      loading: false,
      entries
    });
  };

  onUsersUpdate = querySnapshot => {
    const userMap = {};
    querySnapshot.forEach(doc => {
      const {
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage
      } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage
      };
      userMap[user.id] = user;
    });
    this.setState({
      userMap
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  onLocationsUpdate = querySnapshot => {
    const locationMap = {};
    querySnapshot.forEach(doc => {
      const { name } = doc.data();
      locationMap[doc.id] = { name };
      this.setState({
        locationMap
      });
    });
  };

  onExchangeUpdate = querySnapshot => {
    const exhchangeMap = {};
    querySnapshot.forEach(doc => {
      const { name } = doc.data();
      exhchangeMap[doc.id] = { name };
      this.setState({
        exhchangeMap
      });
    });
  };

  onExchangeTradesUpdate = querySnapshot => {
    let tradesMap = {};
    querySnapshot.forEach(doc => {
      const {
        active,
        completed,
        dateTime,
        price,
        quantity,
        type,
        uid,
        shareId
      } = doc.data();
      tradesMap[doc.id] = {
        active,
        completed,
        dateTime,
        price,
        quantity,
        type,
        uid,
        shareId
      };
    });
    this.setState({
      tradesMap
    });
  };

  getShareName = share => {
    if (this.state.exhchangeMap[share]) {
      return this.state.exhchangeMap[share].name;
    }
    return "";
  };

  getLocationName = loc => {
    if (this.state.locationMap[loc]) {
      return this.state.locationMap[loc].name;
    }
    return "";
  };

  getUserDisplayName = uid => {
    if (this.state.userAccountsMap[uid]) {
      return this.state.userAccountsMap[uid].displayName;
    }
    if (this.state.userMap[uid]) {
      return this.state.userMap[uid].displayName;
    }
    return "";
  };

  getDescription = entry => {
    if (entry) {
      if (
        entry.type === "Share" &&
        entry.subType === "Trade" &&
        this.state.tradesMap[entry.name] &&
        ((this.state.tradesMap[entry.name].type === "Selling" &&
          this.state.tradesMap[entry.name].uid !== entry.otherData) ||
          (this.state.tradesMap[entry.name].type === "Buying" &&
            this.state.tradesMap[entry.name].uid === entry.otherData))
      ) {
        return (
          "Sold " +
          this.state.tradesMap[entry.name].quantity +
          " shares of " +
          this.state.exhchangeMap[this.state.tradesMap[entry.name].shareId]
            .name +
          " to " +
          this.getUserDisplayName(entry.otherData)
        );
      } else if (
        entry.type === "Share" &&
        entry.subType === "Trade" &&
        this.state.tradesMap[entry.name] &&
        ((this.state.tradesMap[entry.name].type === "Selling" &&
          this.state.tradesMap[entry.name].uid === entry.otherData) ||
          (this.state.tradesMap[entry.name].type === "Buying" &&
            this.state.tradesMap[entry.name].uid !== entry.otherData))
      ) {
        return (
          "Bought " +
          this.state.tradesMap[entry.name].quantity +
          " shares of " +
          this.state.exhchangeMap[this.state.tradesMap[entry.name].shareId]
            .name +
          " from " +
          this.getUserDisplayName(entry.otherData)
        );
      } else if (
        (entry.name === "Admin" || entry.name === "API") &&
        entry.value > 0
      ) {
        return "Transferred from Admin";
      } else if (
        (entry.name === "Admin" || entry.name === "API") &&
        entry.value < 0
      ) {
        return "Transferred to Admin";
      } else if (entry.name === "Trade") {
        return "Transferred to " + this.getUserDisplayName(entry.otherData);
      } else if (entry.name === "Tip") {
        return "Tipped user " + this.getUserDisplayName(entry.otherData);
      } else if (entry.name === "Clench" && entry.value > 0) {
        return (
          "Won a bet on clench " + this.getUserDisplayName(entry.otherData)
        );
      } else if (entry.name === "Clench" && entry.value < 0) {
        return (
          "Wagered a bet on clench " + this.getUserDisplayName(entry.otherData)
        );
      } else if (
        entry.subType === "Gambling" &&
        entry.name === "Jackpot Lotto"
      ) {
        return "Jackpot Lotto winnings";
      } else if (entry.subType === "Gambling" && entry.name === "Lottery") {
        return "Lottery winnings";
      } else if (entry.subType === "Gambling" && entry.name === "Scratchcard") {
        return "Scratchcard winnings";
      } else if (entry.subType === "Gambling" && entry.name === "Tombola") {
        return "Tombola winnings";
      } else if (
        entry.subType === "Gambling" &&
        entry.name === "Tortuga Roulette"
      ) {
        return "Stolen from " + this.getUserDisplayName(entry.otherData);
      } else if (
        entry.subType === "Gambling" &&
        entry.name === "Super Tortuga"
      ) {
        return "Stolen from " + this.getUserDisplayName(entry.otherData);
      } else if (
        entry.subType === "Purchase" &&
        entry.name === "Jackpot Lotto"
      ) {
        return "Purchased " + entry.name + " ticket";
      } else if (entry.subType === "Purchase" && entry.name === "Tombola") {
        return "Purchased " + entry.name + " ticket";
      } else if (
        entry.subType === "Purchase" &&
        entry.name === "Tortuga Roulette"
      ) {
        return "Purchased " + entry.name + " ticket";
      } else if (
        entry.subType === "Purchase" &&
        entry.name === "Super Tortuga"
      ) {
        return "Purchased " + entry.name + " ticket";
      } else if (entry.subType === "Purchase" && entry.name === "Scratchcard") {
        return "Purchased " + entry.name;
      } else if (entry.type === "Exchange" && entry.subType === "Purchase") {
        return "Purchased share of " + this.getShareName(entry.otherData);
      } else if (
        entry.type === "Dividend" &&
        entry.subType === "Earned" &&
        entry.name === "Coin"
      ) {
        return "Share dividends collected";
      } else if (
        entry.type === "Share" &&
        entry.subType === "Earned" &&
        entry.name === "Coin"
      ) {
        return (
          "Earned from " + this.getLocationName(entry.otherData) + " shares"
        );
      } else if (entry.subType === "Earned" && entry.name === "Coin") {
        return (
          "Earned from " + this.getLocationName(entry.otherData) + " check in"
        );
      } else if (entry.subType === "Earned" && entry.name === "Time") {
        return "Time spent in " + this.getLocationName(entry.otherData);
      } else if (entry.subType === "Purchase") {
        return "Purchased item " + entry.name;
      }
    }
    return "";
  };

  getValue = entry => {
    if (entry) {
      if (entry.value === "Free") {
        return 0;
      } else if (
        entry.type === "Share" &&
        entry.subType === "Trade" &&
        this.state.tradesMap[entry.name] &&
        ((this.state.tradesMap[entry.name].type === "Buying" &&
          this.state.tradesMap[entry.name].uid !== entry.otherData) ||
          (this.state.tradesMap[entry.name].type === "Selling" &&
            this.state.tradesMap[entry.name].uid === entry.otherData))
      ) {
        return +entry.value * -1;
      } else if (entry.name === "Time") {
        return moment.duration(entry.value, "seconds").format();
      } else if (
        (entry.name === "Trade" ||
          entry.name === "Tip" ||
          entry.subType === "Purchase") &&
        +entry.value > 0
      ) {
        return +entry.value * -1;
      } else if (entry.value === 0) {
        return "Free";
      }
    }
    return entry.value;
  };

  deleteEntry = entry => {
    this.ledgerRef.doc(entry.id).delete();
  };

  getTotalValue = entry => {
    if (entry && entry.initialValue) {
      if (entry.name === "Time") {
        return moment.duration(entry.initialValue, "seconds").format();
      }
    }
    if (+entry.initialValue > 0) {
      return entry.initialValue;
    } else {
      return "None recorded";
    }
  };

  getUser = userId => {
    if (this.state.userMap[userId]) {
      if (this.state.userAccountsMap[userId]) {
        return {
          ...this.state.userMap[userId],
          displayName: this.state.userAccountsMap[userId].displayName,
          photoURL: this.state.userAccountsMap[userId].photoURL
        };
      }
      return this.state.userMap[userId];
    }
    return "";
  };

  getName = entry => {
    if (
      entry.type === "Share" &&
      entry.subType === "Trade" &&
      this.state.tradesMap[entry.name]
    ) {
      return "Trade";
    }
    return entry.name;
  };

  getSubType = entry => {
    if (
      entry.type === "Share" &&
      entry.subType === "Trade" &&
      this.state.tradesMap[entry.name]
    ) {
      return "Share";
    }
    return entry.subType;
  };

  render() {
    const { loading } = this.state;
    return (
      <div>
        <div className="panel panel-default">
          <Card>
            <Card.Header as="h5">Ledger</Card.Header>
            <Card.Body>
              <EmptyState
                content={this.state.entries}
                loading={loading}
                message="Nobody has purchased anything ever? Someone call Whelton!"
              >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th className="d-none d-md-table-cell">Category</th>
                      <th>Type</th>
                      <th className="align-right d-none d-md-table-cell">
                        Previous
                      </th>
                      <th className="align-right">Value</th>
                      <th className="d-none d-lg-table-cell">Desciption</th>
                      <th
                        className="d-none d-md-table-cell align-right"
                        style={{ width: 165 }}
                      >
                        Date
                      </th>
                      <th className="d-table-cell d-md-none align-right">
                        Time
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.entries.map((entry, index) => (
                      <tr key={index}>
                        <td className="align-middle">
                          <UserAvatar user={this.getUser(entry.uid)} />
                          <div>{this.getUser(entry.uid).displayName}</div>
                        </td>
                        <td className="align-middle d-none d-md-table-cell">
                          {this.getSubType(entry)}
                        </td>
                        <td className="align-middle">{this.getName(entry)}</td>
                        <td className="align-middle  align-right d-none d-md-table-cell">
                          {this.getTotalValue(entry)}
                        </td>
                        <td className="align-middle align-right">
                          {this.getValue(entry)}
                        </td>
                        <td className="align-middle d-none d-lg-table-cell">
                          {this.getDescription(entry)}
                        </td>
                        <td className="align-middle d-none d-md-table-cell">
                          {getDateTime(entry.dateTime)}
                        </td>
                        <td className="align-middle d-table-cell d-md-none">
                          {getTime(entry.dateTime)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </EmptyState>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default Ledger;
