import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import "./App.css";
import Leaderboard from "./components/leaderboard/Leaderboard";
import { LoadingCard } from "./components/page/Loading";
import SessionsCard from "./components/sessions/SessionCard";
import firebase from "./components/util/Firebase";
import Visits from "./components/leaderboard/Visits";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      currentUser: null,
      display: 'time'
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    firebase.auth().onAuthStateChanged(async currentUser => {
      this.setState({ loading: false, currentUser });
    });
  }

  toggleDisplay = (newDisplay) => {
    this.setState({
      display: newDisplay
    });
  }

  render() {
    const { loading, currentUser, display } = this.state;

    return (
      <>
        {loading ? (
          <Card>
            <LoadingCard />
          </Card>
        ) : (
          currentUser && <SessionsCard currentUser={currentUser} />
        )}
        {display === 'time' ? (
            <Leaderboard toggleDisplay={this.toggleDisplay} />
            ) : (
            <Visits toggleDisplay={this.toggleDisplay} />
        )}
      </>
    );
  }
}

export default App;
