import React, { Component } from "react";
import firebase from "../util/Firebase";
import startFirebaseUI from "./FirebaseUI";
import history from "../util/History";

class Auth extends Component {
  renderRedirect = () => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        history.push("/");
      } else {
        startFirebaseUI("#firebaseui");
      }
    });
  };

  render() {
    return (
      <div>
        {this.renderRedirect()}
        <div id="firebaseui"></div>
      </div>
    );
  }
}

export default Auth;
