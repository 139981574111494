import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

class Ranking extends Component {
  constructor(props) {
    super(props);

    this.sessionsRef = firebase.firestore().collection("sessions");
    this.locationsRef = firebase.firestore().collection("locations");

    this.state = {
      locations: [],
      locationMap: {},
      sessions: []
    };
  }

  componentDidMount() {
    this.sessionsSubscription = this.sessionsRef.onSnapshot(this.onSessionsUpdate);
    this.locationSubscription = this.locationsRef.onSnapshot(this.onLocationsUpdate);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.sessionsSubscription && this.sessionsSubscription();
    this.locationSubscription && this.locationSubscription();
  }

  handleUserUpdate = querySnapshot => {
    this.setState({
      user: querySnapshot.data()
    });
  };

  onLocationsUpdate = querySnapshot => {

    const locations = [];
    const locationMap = {};

    querySnapshot.forEach(doc => {
      const { name, day, time, enabled, vicinity } = doc.data();
      locations.push({
        id: doc.id,
        name,
        day,
        time,
        enabled,
        vicinity
      });
      locationMap[doc.id] = { name, day, time, enabled, vicinity };
    });

    for (let i = 0; i < locations.length; i++) {
      if(this.state.locationVisits && this.state.locationVisits[locations[i].id]) {
        locations[i].visits = Object.keys(this.state.locationVisits[locations[i].id]).length;
      } else {
        locations[i].visits = 0;
      }
    }

    this.setState({
      locations: locations
          .slice().filter(l => {
            return l.visits > 0
          }).sort(
              (a, b) =>
                  b.visits - a.visits
          ),
      locationMap
    });

    if(this.state.locations.length < 2) {
      this.setState({ loading: true });
      this.sessionsSubscription = this.sessionsRef.onSnapshot(this.onSessionsUpdate);
      this.locationSubscription = this.locationsRef.onSnapshot(this.onLocationsUpdate);
    }
  };

  onSessionsUpdate = querySnapshot => {

    const locationTime = {};
    const locationVisits = {};

    querySnapshot.forEach(doc => {

      const { locationRef, locationId, userId, startTime, endTime } = doc.data();

      let locId = locationId;
      if(!locId && locationRef) {
        locId = locationRef.id;
      }

      if(!locationVisits[locId]) {
        locationVisits[locId] = [];
      }
      if(locationVisits[locId] && !locationVisits[locId][userId]) {
        locationVisits[locId][userId] = true;
      }

    });

    this.setState({
      locationTime,
      locationVisits
    });
  };

  getLocation = location => {
    if (location) {
      return location.name;
    }
    return "";
  };

  render() {
    return (
      <>
        <Card className="my-3 shadow-sm">
          <Card.Header>Pub Rankings</Card.Header>
          <ListGroup variant="flush">
            {this.state.locations.map((location, index) => (
              <ListGroup.Item>
                <Row>
                  <Col className="col-auto d-flex align-items-center lead">
                    {index + 1}
                  </Col>
                  <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
                    <Link to={`/location/` + location.id}>{location.name}</Link>
                    <small>
                      {location && location.vicinity && (
                          <a
                              title={String(location.vicinity)}
                              href={`http://www.google.com/maps?q=${location.name}+${location.vicinity}`}
                          >
                            <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                            {String(location.vicinity)}
                          </a>
                      )}
                    </small>
                  </Col>
                  <Col className="col-auto d-flex flex-column align-items-end justify-content-center lead">
                    {location.visits}
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </>
    );
  }
}

export default Ranking;
