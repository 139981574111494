import React, { Component } from "react";

class EuCountryCount extends Component {
  render() {
    return (
      <div>
        <div className="panel panel-default">
          <div className="eu-country-count">28</div>
        </div>
      </div>
    );
  }
}

export default EuCountryCount;
