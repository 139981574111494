import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import { addCoinToLedger } from "../util/Utils";
import ExchangeNav from "./ExchangeNav";
import { Link } from "react-router-dom";
import PubCoin from "../coin/PubCoin";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";

class Exchange extends Component {
  constructor(props) {
    super(props);

    this.exchangeRef = firebase.firestore().collection("exchange");
    this.usersRef = firebase.firestore().collection("users");
    this.ledgerRef = firebase.firestore().collection("ledger");

    this.unsubscribeUsers = null;
    this.unsubscribeExchange = null;

    this.state = {
      exchange: [],
      userExchange: {},
      userMap: {},
      exchangeMap: {}
    };
  }

  componentDidMount() {
    this.unsubscribeExchange = this.exchangeRef
      .orderBy("price", "desc")
      .onSnapshot(this.onExchangeUpdate);
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
  }

  onUsersUpdate = querySnapshot => {
    if (firebase.auth().currentUser) {
      this.setState({
        uid: firebase.auth().currentUser.uid
      });
    }
    const userMap = {};
    querySnapshot.forEach(doc => {
      const { coin } = doc.data();
      const user = {
        id: doc.id,
        coin
      };
      userMap[user.id] = user;
    });
    this.setState({
      userMap
    });
  };

  onExchangeUpdate = querySnapshot => {
    const exchange = [];
    const exchangeMap = {};
    querySnapshot.forEach(doc => {
      const {
        name,
        price,
        previousPrice,
        initialPrice,
        highPrice,
        lowPrice,
        quantity,
        active
      } = doc.data();
      if (name) {
        exchange.push({
          id: doc.id,
          name,
          price,
          previousPrice,
          initialPrice,
          highPrice,
          lowPrice,
          quantity,
          active
        });
        exchangeMap[doc.id] = {
          name,
          price,
          previousPrice,
          initialPrice,
          highPrice,
          lowPrice,
          quantity,
          active
        };
      }
    });
    this.setState({
      exchange,
      exchangeMap
    });
  };

  onUserExchangeUpdate = querySnapshot => {
    const userExchange = {};
    querySnapshot.forEach(doc => {
      const { stockId, quantity } = doc.data();
      userExchange[doc.id] = {
        id: doc.id,
        stockId,
        quantity
      };
    });
    this.setState({
      userExchange
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  getPurchaseButton = stock => {
    if (!firebase.auth().currentUser) {
      return "";
    }

    if (stock) {
      if (stock.quantity === 0 || !stock.active) {
        return (
          <button className="btn btn-secondary" disabled>
            Buy
          </button>
        );
      } else {
        return (
          <button
            className="btn btn-primary"
            onClick={this.purchase.bind(this, stock)}
          >
            Buy
          </button>
        );
      }
    }

    return "";
  };

  purchase = stock => {
    const user = this.state.userMap[firebase.auth().currentUser.uid];

    let price = +stock.price;

    if (+user.coin - price >= 0 && stock.active) {
      this.usersRef.doc(user.id).update({
        coin: firebase.firestore.FieldValue.increment(-price)
      });

      this.exchangeRef.doc(stock.id).update({
        quantity: firebase.firestore.FieldValue.increment(-1)
      });

      const userExchangeRef = this.usersRef
        .doc(firebase.auth().currentUser.uid)
        .collection("exchange")
        .doc(stock.id);

      userExchangeRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          userExchangeRef.update({
            quantity: docSnapshot.data().quantity + 1,
            ipoCount: docSnapshot.data().ipoCount + 1
          });
        } else {
          userExchangeRef.set({
            quantity: 1,
            ipoCount: 1
          });
        }
      });

      const exchangeUserRef = this.exchangeRef
        .doc(stock.id)
        .collection("users")
        .doc(user.id);

      exchangeUserRef.get().then(docSnapshot => {
        if (docSnapshot.exists) {
          exchangeUserRef.update({
            quantity: docSnapshot.data().quantity + 1,
            ipoCount: docSnapshot.data().ipoCount + 1
          });
        } else {
          exchangeUserRef.set({
            quantity: 1,
            ipoCount: 1
          });
        }
      });

      let lowPrice = this.state.exchangeMap[stock.id].lowPrice;
      let highPrice = this.state.exchangeMap[stock.id].highPrice;

      if (!lowPrice) {
        lowPrice = price;
      }

      if (!highPrice) {
        highPrice = price;
      }

      if (price < lowPrice) {
        lowPrice = price;
      }

      if (price > highPrice) {
        highPrice = price;
      }

      this.exchangeRef.doc(stock.id).update({
        previousPrice: this.state.exchangeMap[stock.id].price,
        price: price,
        lowPrice: lowPrice,
        highPrice: highPrice
      });

      addCoinToLedger(
        this.ledgerRef,
        "Exchange",
        "Purchase",
        "Share",
        user.id,
        +user.coin,
        price,
        stock.id
      );
    }
  };

  getChange = share => {
    if (share.price && share.previousPrice) {
      const change =
        Math.round((share.price / share.previousPrice) * 100) - 100;
      if (change > 0) {
        return <span className={"positive"}>{change}%</span>;
      } else if (change < 0) {
        return <span className={"negative"}>{change}%</span>;
      }
    }
    return <span>0%</span>;
  };

  render() {
    return (
      <div>
        <div className="panel panel-default">
          <ExchangeNav
            location={this.props.location.pathname}
            uid={this.state.uid}
            user={this.state.userMap[this.state.uid]}
          />
          <Card className="my-3">
            <Card.Header as="h5">Share IPOs</Card.Header>
            <Card.Body>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Quantity</th>
                    <th className="text-right"></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.exchange.map((stock, index) => (
                    <tr
                      className={stock.quantity <= 0 ? "hide" : ""}
                      key={index}
                    >
                      <td>{stock.name}</td>
                      <td className="text-right">
                        <PubCoin amount={stock.initialPrice} />
                      </td>
                      <td className="text-right">{stock.quantity}</td>
                      <td className="text-right">
                        {this.getPurchaseButton(stock)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Header as="h5">Index</Card.Header>
            <Card.Body>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Change</th>
                    <th className="text-right">Low</th>
                    <th className="text-right">High</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.exchange.map((stock, index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/exchange/share/${stock.id}`}>
                          {stock.name}
                        </Link>
                      </td>
                      <td className="text-right">
                        <PubCoin
                          amount={stock.price}
                          previousAmount={stock.previousPrice}
                        />
                      </td>
                      <td className="text-right">{this.getChange(stock)}</td>
                      <td className="text-right">
                        <PubCoin amount={stock.lowPrice} />
                      </td>
                      <td className="text-right">
                        <PubCoin amount={stock.highPrice} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}

export default Exchange;
