import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import "../../App.css";
import firebase from "../util/Firebase";
import ReactSearchBox from 'react-search-box';
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./Search.css";
import {firestore} from "firebase";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import CheckInButton from "../sessions/CheckInButton";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";

class Search extends Component {

    constructor(props) {
        super(props);

        this.usersRef = firestore().collection("users");
        this.locationsRef = firestore().collection("locations");
        this.sessionsRef = firestore().collection("sessions");

        this.unSubLocations = this.locationsRef.onSnapshot(this.onLocationsUpdate);

        this.state = {
            locations: [],
            filteredLocations: [],
            locationData: [],
            userLocations: {},
            user: {}
        };
    }

    componentDidMount() {
        firebase.auth().onAuthStateChanged(user => {
            if (user && user.uid) {
                this.unSubUsers = this.usersRef
                    .doc(user.uid)
                    .onSnapshot(this.handleUserUpdate);
                this.unSubSessions = this.sessionsRef
                    .where("userId", "==", user.uid)
                    .onSnapshot(this.onUserLocationsUpdate);
            }
        });
    }

    componentWillUnmount() {
        this.unSubUsers && this.unSubUsers();
        this.unSubSessions && this.unSubSessions();
        this.unSubLocations && this.unSubLocations();
    }

    handleUserUpdate = querySnapshot => {
        this.setState({
            user: querySnapshot.data()
        });
    };

    onLocationsUpdate = querySnapshot => {

        const locations = [];
        const locationData = [];

        querySnapshot.forEach(doc => {
            const { name, day, time, enabled, vicinity, lat, lon } = doc.data();

            locations.push({
                id: doc.id,
                name,
                day,
                time,
                enabled,
                vicinity,
                lat,
                lon
            });

            locationData.push({
                key: doc.id,
                value: name
            });

            const filteredLocations = locations.sort((l1, l2) => {
                if(l1.name < l2.name) { return -1; }
                if(l1.name > l2.name) { return 1; }
                return 0;
            });

            this.setState({
                locations,
                filteredLocations,
                locationData
            });
        });
    };

    onUserLocationsUpdate = querySnapshot => {

        const userLocations = {};

        querySnapshot.forEach(doc => {

            const locationId = doc.data().locationRef.id;

            if(!userLocations[doc.locationRef]) {
                userLocations[locationId] = true;
            }
        });

        this.setState({
            userLocations
        });
    };

    filterLocations = (query) => {

        let filteredLocations = this.state.locations;

        let searchQueryLimiter;
        if(query && query.length > 0) {
            let lastCharOfSearchQuery = query[query.length -1];
            lastCharOfSearchQuery = String.fromCharCode(lastCharOfSearchQuery.charCodeAt() + 1)
            searchQueryLimiter = query.replace(/.$/, lastCharOfSearchQuery);
        }
        filteredLocations = this.state.locations.filter(l1 => {
            if(l1.name && query && searchQueryLimiter) {
                return l1.name.toUpperCase() >= query.toUpperCase() && l1.name.toUpperCase() <= searchQueryLimiter.toUpperCase();
            } else {
                return true;
            }
        });

        filteredLocations = filteredLocations.sort((l1, l2) => {
            if(l1.name < l2.name) { return -1; }
            if(l1.name > l2.name) { return 1; }
            return 0;
        });

        this.setState({
           filteredLocations
        });
    }

    addVisitButtonClass = (location) => {
        if (this.state.userLocations[location.id]) {
            return 'd-none';
        }
        return '';
    };

    checkInButtonClass = (location) => {
        if (!this.state.userLocations[location.id]) {
            return 'd-none';
        }
        return '';
    };

    addVisit = (location) => {

        let userLocationRef = firestore()
            .collection("users")
            .doc(this.state.user.uid)
            .collection("locations")
            .doc(location.id);

        let locationUserRef = firestore()
            .collection("locations")
            .doc(location.id)
            .collection("users")
            .doc(this.state.user.uid);

        firestore().runTransaction(async transaction => {
            const userLocationSnapshot = await transaction.get(userLocationRef);

            if (!userLocationSnapshot.exists) {
                transaction.set(userLocationRef, { Ttotal: 0 });
            } else if (!userLocationSnapshot.data().Ttotal) {
                let updatedTotal = 0;
                if (userLocationSnapshot.data().totalTime) {
                    updatedTotal = userLocationSnapshot.data().totalTime;
                }
                transaction.update(userLocationRef, { Ttotal: updatedTotal });
            }
        });

        firestore().runTransaction(async transaction => {
            const locationUserSnapshot = await transaction.get(locationUserRef);

            if (!locationUserSnapshot.exists) {
                transaction.set(locationUserRef, { Ttotal: 0 });
            } else if (!locationUserSnapshot.data().Ttotal) {
                let updatedTotal = 0;
                if (locationUserSnapshot.data().totalTime) {
                    updatedTotal = locationUserSnapshot.data().totalTime;
                }
                transaction.update(locationUserRef, { Ttotal: updatedTotal });
            }
        });

        const date = moment.utc().toDate();
        this.sessionsRef.add({}).then(docRef => {
            const newSession = this.sessionsRef.doc(docRef.id);
            newSession.set({
                locationRef: firestore().collection("locations").doc(location.id),
                userRef: firestore().collection("users").doc(this.state.user.uid),
                userId: this.state.user.uid,
                locationId: location.id,
                startTime: date,
                endTime: date,
                shareRate: 0,
                coinRate: 0,
                locationName: location.name,
                userName: this.state.user.displayName
            });
        });
    };

    render() {
        return (
            <>
                <Card>
                    <Card.Header as="h5">Pub Search</Card.Header>
                    <Card.Body>
                        <div className={"search-font"}>
                            <ReactSearchBox
                                placeholder="Please enter a location"
                                data={this.state.locationData}
                                onChange={query => this.filterLocations(query)}
                            />
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        {this.state.filteredLocations.map((location, index) => (
                            <ListGroup.Item key={location.id}>
                                <Row>
                                    <Col className="d-flex align-right align-items-center text-truncate">
                                        <Link to={`/location/${location.id}`}>{location.name}</Link>
                                        <div className={"location-address"}>
                                            <small>
                                                {location && location.vicinity && (
                                                    <a
                                                        title={String(location.vicinity)}
                                                        href={`http://www.google.com/maps?q=${location.name}+${location.vicinity}`}
                                                    >
                                                        <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                                                        {String(location.vicinity)}
                                                    </a>
                                                )}
                                            </small>
                                        </div>
                                    </Col>
                                    <Col className="d-flex col-auto">
                                        <Button onClick={this.addVisit.bind(this, location)}
                                                className={this.addVisitButtonClass(location)}
                                        >
                                            Record visit
                                        </Button>
                                    </Col>
                                    <div className={this.checkInButtonClass(location)}>
                                        <Col className="d-flex col-auto">
                                            <CheckInButton selectedLocation={location}/>
                                        </Col>
                                    </div>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </Card.Body>
                </Card>
            </>
        );
    }
}

export default Search;
