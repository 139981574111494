import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBeer } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import "../../animate.css";

interface Props {
  amount?: number;
  colours?: boolean;
  previousAmount?: number;
}

interface State {
  increase?: boolean;
  decrease?: boolean;
  amount: number;
}

class PubCoin extends React.PureComponent<Props, State> {
  private interval?: number;

  public constructor(props: Props) {
    super(props);
    this.state = { amount: 0 };
  }

  public componentDidMount() {
    if (typeof this.props.amount === "number") {
      this.setState({ amount: this.props.amount });
    }
  }

  public componentWillUnmount() {
    window.clearInterval(this.interval);
  }

  public componentDidUpdate(prevProps: Props) {
    const { amount = 0, previousAmount = prevProps.amount || 0 } = this.props;

    if (amount !== previousAmount) {
      if (previousAmount === 0) {
        this.setState({ amount });
      } else {
        this.interval = window.setInterval(this.refreshAmount, 50);
      }
    }
  }

  public render() {
    const { colours = true } = this.props;
    const { increase, decrease, amount = 0 } = this.state;

    if (typeof this.props.amount !== "number") {
      return null;
    }

    return (
      <span className={classNames("pub-coin", { colours, increase, decrease })}>
        <FontAwesomeIcon icon={faBeer} /> {Math.round(amount)}
      </span>
    );
  }

  private refreshAmount = () => {
    const current = Math.round(this.state.amount);
    const target = Math.round(Number(this.props.amount));

    if (typeof target === "number" && current !== target) {
      const difference = target - current;
      const nextAmount =
        difference > 0 ? current + 1 : difference < 0 ? current - 1 : current;

      this.setState({
        increase: difference > 0,
        decrease: difference < 0,
        amount: Math.round(nextAmount)
      });
    } else {
      this.setState({
        increase: false,
        decrease: false
      });

      window.clearInterval(this.interval);
    }
  };
}

export default PubCoin;
