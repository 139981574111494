import moment from "moment";
import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import "./UserProfile.scss";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EmptyState from "../page/EmptyState";

class PubProfile extends Component {

    constructor(props) {
        super(props);

        this.usersRef = firebase.firestore().collection("users");
        this.userAccountsRef = firebase.firestore().collection("userAccounts");
        this.locationsRef = firebase.firestore().collection("locations");

        this.locationUsersRef = this.locationsRef
            .doc(this.props.match.params.id)
            .collection("users");

        this.state = {
            userMap: {},
            userAccountsMap: {},
            locationMap: {},
            locationUsers: [],
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
        this.unsubUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
        this.userAccountsSubscription = this.userAccountsRef.onSnapshot(this.onUserAccountsUpdate);
        this.unsubLocationUsersRef = this.locationUsersRef.onSnapshot(this.onLocationUsersUpdate);
        this.unsubLocations = this.locationsRef.onSnapshot(this.onLocationsUpdate);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        this.unsubUsers && this.unsubUsers();
        this.userAccountsSubscription && this.userAccountsSubscription();
        this.unsubLocationUsersRef && this.unsubLocationUsersRef();
        this.unsubLocations && this.unsubLocations();
    }

    onLocationsUpdate = querySnapshot => {
        const locationMap = {};
        querySnapshot.forEach(doc => {
            const { name } = doc.data();
            const location = {
                id: doc.id,
                name
            }
            locationMap[location.id] = location;
        });
        this.setState({
            locationMap
        });
    }

    onUsersUpdate = querySnapshot => {
        const userMap = {};
        querySnapshot.forEach(doc => {
            const { displayName, photoURL, itemImage, itemHat } = doc.data();
            const user = {
                id: doc.id,
                displayName,
                photoURL,
                itemImage,
                itemHat
            };
            userMap[user.id] = user;
        });
        this.setState({
            userMap
        });
    };

    onUserAccountsUpdate = querySnapshot => {
        const userAccountsMap = {};
        querySnapshot.forEach(doc => {
            const { displayName, photoURL } = doc.data();
            userAccountsMap[doc.id] = { displayName, photoURL };
            this.setState({
                userAccountsMap
            });
        });
    };

    getUser = userId => {
        if (this.state.userMap[userId]) {
            if (this.state.userAccountsMap[userId]) {
                return {
                    ...this.state.userMap[userId],
                    displayName: this.state.userAccountsMap[userId].displayName,
                    photoURL: this.state.userAccountsMap[userId].photoURL
                };
            }
            return this.state.userMap[userId];
        }
        return "";
    };

    onLocationUsersUpdate = querySnapshot => {

        let locationUsers = [];

        querySnapshot.forEach(doc => {

            const { Ttotal, totalTime } = doc.data();

            let total = 0;
            if(totalTime) {
                total = total + totalTime;
            }
            if(Ttotal) {
                total = total + (Ttotal / 1000);
            }

            locationUsers.push({
                uid: doc.id,
                time: total
            });
        });

        locationUsers = locationUsers.sort((l1, l2) => {
            return l2.time - l1.time;
        });

        this.setState({
            locationUsers,
            loading: false
        });
    }

    getUserName = (user) => {
        if(user && this.state.userMap && this.state.userMap[user.uid]) {
            return this.state.userMap[user.uid].displayName;
        }
        return '';
    }

    getLocationName = (locationId) => {
        if(locationId && this.state.locationMap) {
            if(this.state.locationMap[locationId]) {
                return this.state.locationMap[locationId].name;
            }
        }
        return '';
    }

    render() {
        const { loading } = this.state;

        return (
            <>
                <h1 className="my-3 display-4 text-center">
                    {this.getLocationName(this.props.match.params.id)}
                </h1>
                <Card className="my-3 shadow-sm">
                    <Card.Header>User visits</Card.Header>
                    <EmptyState
                        content={this.state.locationUsers}
                        loading={loading}
                        message="Nobody has checked into this pub yet!"
                    >
                        <ListGroup variant="flush">
                            {this.state.locationUsers.map((user, index) => (
                                <ListGroup.Item>
                                    <Row>
                                        <Col className="col-auto d-flex align-items-center lead">
                                            {index + 1}
                                        </Col>
                                        <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
                                            <Link to={`/profile/${user.uid}`}>
                                                <p className="mb-0">
                                                    {this.getUserName(user)}
                                                </p>
                                            </Link>
                                        </Col>
                                        <Col className="col-auto d-flex flex-column align-items-end justify-content-center lead">
                                            {moment.duration(user.time, "seconds").format()}
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </EmptyState>
                </Card>
            </>
        );
    }
}

export default PubProfile;
