import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { auth } from "firebase";

class CoinNav extends React.PureComponent {
  render() {
    const currentUser = auth().currentUser;

    return (
      <Nav variant="tabs" className="my-3">
        <NavLink className="nav-link text-secondary" to="/coin/balances">
          Balances
        </NavLink>
        <NavLink
          className="nav-link text-secondary"
          to={currentUser ? `/coin/trade/${currentUser.uid}` : "auth"}
        >
          Transfer
        </NavLink>
        <NavLink
          className="nav-link text-secondary"
          to={currentUser ? `/coin/items/${currentUser.uid}` : "auth"}
        >
          Items
        </NavLink>
      </Nav>
    );
  }
}

export default CoinNav;
