import moment from "moment";
import { User } from "../../Types";
import { DisplayOptions } from "./Leaderboard";

export function getCurrentTime(user: User, display: DisplayOptions): number {
  const time = moment();
  const userWithScores = user as User & { [type: string]: number };

  switch (display) {
    case DisplayOptions.Week:
      return userWithScores[time.format("[T]YYYY[W]WW")] || 0;
    case DisplayOptions.Month:
      return userWithScores[time.format("[T]YYYY[M]MM")] || 0;
    case DisplayOptions.Year:
      return userWithScores[time.format("[T]YYYY")] || 0;
    case DisplayOptions.Total:
    default:
      return userWithScores["Ttotal"] || 0;
  }
}
