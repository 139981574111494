import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";
import { ROUND_NAMES } from "../util/Constants";
import { getTies, getRoundType } from "../util/Utils";

class ChampionshipView extends Component {
  constructor(props) {
    super(props);
    this.competitionRef = firebase
      .firestore()
      .collection("competitions")
      .doc(this.props.match.params.id);
    this.competitorsRef = this.competitionRef.collection("competitors");
    this.usersRef = this.competitionRef.collection("users");
    this.votesRef = this.usersRef.doc(this.getUid()).collection("votes");
    this.unsubscribeCompetition = null;
    this.unsubscribeCompetitors = null;
    this.unsubscribeUser = null;
    this.unsubscribeVotes = null;
    this.state = {
      competition: {},
      competitors: [],
      user: {},
      voteMap: {},
      votes: [],
      ties: []
    };
  }

  getUid() {
    if (
      firebase.auth().currentUser &&
      firebase.auth().currentUser.uid !== this.props.match.params.uid
    ) {
      return firebase.auth().currentUser.uid;
    } else {
      return this.props.match.params.uid;
    }
  }

  componentDidMount() {
    this.unsubscribeCompetition = this.competitionRef.onSnapshot(
      this.onCompetitionUpdate
    );
    this.unsubscribeCompetitors = this.competitorsRef
      .orderBy("status", "desc")
      .onSnapshot(this.onCompetitorsUpdate);
    this.unsubscribeUser = this.usersRef
      .doc(this.getUid())
      .onSnapshot(this.onUserUpdate);
    this.unsubscribeVotes = this.votesRef.onSnapshot(this.onVotesUpdate);
  }

  onCompetitionUpdate = () => {
    let competition = {};
    this.competitionRef.onSnapshot(doc => {
      const {
        created,
        creatorUid,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        votesRequired,
        round,
        advance
      } = doc.data();
      competition = {
        id: doc.id,
        created,
        creatorUid,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        votesRequired,
        round,
        advance
      };
      this.setState({
        competition
      });
      if (competition.status === 0) {
        history.push("/cop/add/" + this.props.match.params.id);
      }
    });
  };

  onCompetitorsUpdate = querySnapshot => {
    const competitors = [];
    querySnapshot.forEach(doc => {
      const {
        id,
        created,
        creatorUid,
        creatorName,
        name,
        status,
        votes,
        result,
        photoURL
      } = doc.data();
      competitors.push({
        id,
        created,
        creatorUid,
        creatorName,
        name,
        status,
        votes,
        result,
        photoURL
      });
    });
    this.setState({
      competitors
    });

    const ties = [];
    let teamCount = 0;

    let competitorsFiltered = [];

    for (let index = 0; index < competitors.length; index++) {
      if (competitors[index].status > 0) {
        competitorsFiltered.push(competitors[index]);
      }
    }

    if (competitorsFiltered.length > 1) {
      for (let index = 0; index < competitorsFiltered.length; index++) {
        if (
          competitorsFiltered[teamCount] &&
          competitorsFiltered[teamCount + 1]
        ) {
          let tie = {};
          tie["homeTeam"] = competitorsFiltered[teamCount];
          teamCount++;
          tie["awayTeam"] = competitorsFiltered[teamCount];
          teamCount++;
          ties.push(tie);
        }
      }
    }
    this.setState({
      ties
    });
  };

  onUserUpdate = () => {
    let user = {};
    this.usersRef.doc(this.getUid()).onSnapshot(doc => {
      if (doc.data()) {
        const { teamOwner } = doc.data();
        user = {
          teamOwner
        };
      }
      this.setState({
        user
      });
    });
  };

  onVotesUpdate = querySnapshot => {
    let voteMap = {};
    let votes = [];
    querySnapshot.forEach(doc => {
      const { votedFor } = doc.data();
      voteMap[doc.id] = votedFor;
      votes.push({
        id: doc.id,
        votedFor
      });
    });
    this.setState({
      votes,
      voteMap
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  getButton = (tie, type) => {
    let buttonToReturn = "";
    const votedFor = this.state.voteMap[
      tie["homeTeam"].id + tie["awayTeam"].id
    ];

    if (
      votedFor === tie[type].id ||
      this.state.competition.status === 2 ||
      this.state.competition.advance
    ) {
      buttonToReturn = (
        <button className="btn btn-success vote-btn" disabled>
          ✔
        </button>
      );
    } else if (firebase.auth().currentUser) {
      buttonToReturn = (
        <button
          className="btn btn-secondary vote-btn"
          onClick={this.vote.bind(this, tie, type)}
        >
          ▢
        </button>
      );
    }
    return buttonToReturn;
  };

  vote = (tie, type) => {
    if (
      firebase.auth().currentUser &&
      this.state.competition.status !== 2 &&
      !this.state.competition.advance
    ) {
      const votedFor = this.state.voteMap[
        tie["homeTeam"].id + tie["awayTeam"].id
      ];
      let typeToCheck = "";
      if (type === "awayTeam") {
        typeToCheck = "homeTeam";
      } else if (type === "homeTeam") {
        typeToCheck = "awayTeam";
      }
      if (tie[typeToCheck].id === votedFor) {
        let reducedVotes = tie[typeToCheck].votes - 1;
        this.competitorsRef.doc(tie[typeToCheck].id).update({
          votes: reducedVotes
        });
      }

      let increasedVotes = tie[type].votes + 1;
      this.competitorsRef.doc(tie[type].id).update({
        votes: increasedVotes
      });

      const voteRef = this.votesRef.doc(
        tie["homeTeam"].id + tie["awayTeam"].id
      );
      voteRef.set({
        votedFor: tie[type].id
      });

      this.recordMatchResult(increasedVotes, tie, tie[type]);
    }
  };

  recordMatchResult(votes, tie, team) {
    if (votes >= this.state.competition.votesRequired) {
      if (tie["homeTeam"].id === team.id) {
        this.competitorsRef.doc(tie["awayTeam"].id).update({
          result: -1
        });
      } else {
        this.competitorsRef.doc(tie["homeTeam"].id).update({
          result: -1
        });
      }
      this.competitorsRef
        .doc(team.id)
        .update({
          result: 1
        })
        .then(() => {
          this.checkForWinners();
        });
    } else {
      if (tie["homeTeam"].id === team.id) {
        this.competitorsRef.doc(tie["awayTeam"].id).update({
          result: 0
        });
      } else {
        this.competitorsRef.doc(tie["homeTeam"].id).update({
          result: 0
        });
      }
      this.competitorsRef
        .doc(team.id)
        .update({
          result: 0
        })
        .then(() => {
          this.checkForWinners();
        });
    }
  }

  checkForWinners() {
    let advance = true;
    let winnerName = "";
    let winnerOwnerName = "";
    let winnerPhotoURL = "";
    let winnerId = "";

    for (const competitor of this.state.competitors) {
      if (competitor.result === 0 && competitor.status !== 0) {
        advance = false;
        break;
      } else if (competitor.result === 1) {
        winnerName = competitor.name;
        winnerOwnerName = competitor.creatorName;
        winnerPhotoURL = competitor.photoURL;
        winnerId = competitor.creatorUid;
      }
    }
    if (advance) {
      this.competitionRef.update({
        advance: true
      });
    }

    if (this.state.competition.round <= 1 && advance) {
      this.competitionRef.update({
        winnerName: winnerName,
        winnerOwnerName: winnerOwnerName,
        winnerPhotoURL: winnerPhotoURL,
        winnerId: winnerId,
        status: 2,
        round: 0
      });
    } else if (advance) {
      this.advanceRound();
    }
  }

  advanceRound() {
    let competitorWinners = [];
    for (const competitor of this.state.competitors) {
      if (competitor.result === -1) {
        this.competitorsRef.doc(competitor.id).delete();
      } else {
        competitorWinners.push(competitor);
        this.competitorsRef.doc(competitor.id).update({
          votes: 0,
          status: 0,
          result: 0
        });
      }
    }

    for (const vote of this.state.votes) {
      this.votesRef.doc(vote.id).delete();
    }

    this.competitionRef.update({
      advance: false,
      round: getRoundType(competitorWinners)
    });

    const ties = getTies(competitorWinners);
    let teamCount = 1;
    for (let index = 0; index < ties.length; index++) {
      if (ties[index]["homeTeam"] && ties[index]["awayTeam"]) {
        this.competitorsRef.doc(ties[index]["homeTeam"]).update({
          status: teamCount
        });
        teamCount++;
        this.competitorsRef.doc(ties[index]["awayTeam"]).update({
          status: teamCount
        });
        teamCount++;
      }
    }
  }

  canAdvanceRound() {
    return this.state.competition.advance;
  }

  isWon() {
    return this.state.competition.status === 2;
  }

  isWinner(competitor) {
    if (competitor.result === 1) {
      return (
        <span role="img" aria-label="Trophy">
          🏆
        </span>
      );
    }
    return "";
  }

  getRound = round => {
    return ROUND_NAMES[round];
  };

  getPhotoURL = team => {
    if (team) {
      return team.photoURL;
    }
  };

  getName = team => {
    if (team) {
      return team.name;
    }
  };

  getVotes = team => {
    if (team) {
      return team.votes;
    }
  };

  getRules = () => {
    if (this.state.competition.status && !this.isWon()) {
      return <span>First to {this.state.competition.votesRequired} wins</span>;
    }
    return "";
  };

  getTitle = () => {
    if (this.state.competition.status) {
      return (
        <span>
          {this.state.competition.title.trim()}:{" "}
          {this.getRound(this.state.competition.round)}
        </span>
      );
    }
    return "";
  };

  render() {
    let rules = <div className="rules">{this.getRules()}</div>;
    let advanceButtonElement = "";
    if (this.state.competition.round > 0) {
      if (
        !this.isWon() &&
        firebase.auth().currentUser &&
        this.getUid() === this.state.competition.creatorUid &&
        this.canAdvanceRound()
      ) {
        advanceButtonElement = (
          <div className="card box">
            <form onSubmit={this.onSubmit}>
              <button
                type="submit"
                className="btn btn-success cop-right"
                onClick={this.advanceRound.bind(this)}
              >
                Advance to next round
              </button>
            </form>
          </div>
        );
      } else if (!this.isWon() && !this.canAdvanceRound()) {
        advanceButtonElement = (
          <div className="card box">
            <form>
              <button
                type="submit"
                className="btn btn-outline-success cop-right"
                disabled
              >
                Waiting for votes...
              </button>
            </form>
          </div>
        );
      } else if (!this.isWon()) {
        advanceButtonElement = (
          <div className="card box">
            <form>
              <button
                type="submit"
                className="btn btn-outline-success cop-right"
                disabled
              >
                Waiting to advance...
              </button>
            </form>
          </div>
        );
      }
    }

    let mainDisplay = "";
    if (!this.isWon()) {
      mainDisplay = (
        <div>
          {rules}
          <div>
            <table className="table table-or">
              <tbody className="vote-container">
                {this.state.ties.map((tie, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      <div className="">{this.getButton(tie, "homeTeam")}</div>
                    </td>
                    <td className="pl-0 pr-0">
                      <div className="home-team">
                        <img
                          className="user-img"
                          src={this.getPhotoURL(tie["homeTeam"])}
                          alt="Home team avatar"
                        />
                        <div className="team-name">
                          {this.getName(tie["homeTeam"])}{" "}
                          {this.isWinner(tie["homeTeam"])}
                        </div>
                      </div>
                    </td>
                    <td className="team-votes-wrapper">
                      <div className="team-votes">
                        {this.getVotes(tie["homeTeam"])}
                      </div>
                      -
                      <div className="team-votes">
                        {this.getVotes(tie["awayTeam"])}
                      </div>
                    </td>
                    <td className="pl-0 pr-0">
                      <div className="away-team">
                        <div className="team-name">
                          {this.getName(tie["awayTeam"])}{" "}
                          {this.isWinner(tie["awayTeam"])}
                        </div>
                        <img
                          className="user-img"
                          src={this.getPhotoURL(tie["awayTeam"])}
                          alt="Away team avatar"
                        />
                      </div>
                    </td>
                    <td className="pr-0">
                      <div className="">{this.getButton(tie, "awayTeam")}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      );
    } else {
      mainDisplay = (
        <div>
          <div className="winner-container">
            <div className="winner-name">
              <span role="img" aria-label="Trophy">
                🏆
              </span>{" "}
              {this.state.competition.winnerName}{" "}
              <span role="img" aria-label="Trophy">
                🏆
              </span>
            </div>
            <div>
              <img
                className="user-img"
                src={this.state.competition.winnerPhotoURL}
                alt="Winner avatar"
              />
              <div className="winner-creator-name">
                {this.state.competition.winnerOwnerName}
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="card nav-bar">
            <div className="nav-bar-option-wrapper">
              <Link to={`/cop`}>
                <button className="btn btn-primary">Competitions</button>
              </Link>
            </div>
          </div>
          <div className="card box">
            <div className="card-title h4 cop-title">{this.getTitle()}</div>
            {mainDisplay}
          </div>
        </div>
        {advanceButtonElement}
      </div>
    );
  }
}

export default ChampionshipView;
