import React, { Component } from "react";
import "./Scratchcard.scss";

class Scratchcard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      covered: [true, true, true, true, true, true, true, true, true],
      count: 0,
      winningsCount: 0,
      complete: false,
      alertUser: true,
      forceClose: false
    };
  }

  getValue = elementId => {
    if (this.state.covered[elementId]) {
      return "?";
    }
    return this.props.elements[elementId];
  };

  getClass = elementId => {
    if (this.state.covered[elementId]) {
      return "scratchcard-value covered";
    }
    return "scratchcard-value";
  };

  uncoverElement = elementId => {
    const updatedCovered = this.state.covered;
    updatedCovered[elementId] = false;

    let winCount = this.state.winningsCount;
    if (this.props.elements[elementId] === this.props.winnings) {
      winCount = winCount + 1;
    }

    this.setState({
      covered: updatedCovered,
      count: this.state.count + 1,
      winningsCount: winCount
    });

    if (this.state.count >= 8 || this.state.winningsCount === 3) {
      this.setState({
        complete: true,
        alertUser: false
      });
    }
  };

  closeScratchcard = () => {
    console.log(this.state.count + " " + this.state.winningsCount);
    if (this.state.count >= 8 || this.state.winningsCount === 3) {
      this.props.closeScratchcard(
        this.props.user,
        this.props.item,
        this.props.winnings,
        this.state.alertUser
      );
    }
  };

  getWinnings = () => {
    console.log("checking: " + this.state.complete);
    if (this.state.complete) {
      return this.props.winnings;
    }
    return "?";
  };

  render() {
    return (
      <div className={"scratchcard"}>
        <div
          onClick={this.closeScratchcard.bind(this)}
          className={"scratchcard-close"}
        >
          X
        </div>
        <div className={"scratchcard-title"}>
          Scratchcard: <div className={"winnings"}>{this.getWinnings()}</div>
        </div>
        <div className={"scratchcard-row"}>
          <div
            onClick={this.uncoverElement.bind(this, 0)}
            className={this.getClass(0)}
          >
            {this.getValue(0)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 1)}
            className={this.getClass(1)}
          >
            {this.getValue(1)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 2)}
            className={this.getClass(2)}
          >
            {this.getValue(2)}
          </div>
        </div>
        <div className={"scratchcard-row"}>
          <div
            onClick={this.uncoverElement.bind(this, 3)}
            className={this.getClass(3)}
          >
            {this.getValue(3)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 4)}
            className={this.getClass(4)}
          >
            {this.getValue(4)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 5)}
            className={this.getClass(5)}
          >
            {this.getValue(5)}
          </div>
        </div>
        <div className={"scratchcard-row"}>
          <div
            onClick={this.uncoverElement.bind(this, 6)}
            className={this.getClass(6)}
          >
            {this.getValue(6)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 7)}
            className={this.getClass(7)}
          >
            {this.getValue(7)}
          </div>
          <div
            onClick={this.uncoverElement.bind(this, 8)}
            className={this.getClass(8)}
          >
            {this.getValue(8)}
          </div>
        </div>
        <div className={"scratchcard-company"}>Broenson Casino Ltd.</div>
      </div>
    );
  }
}

export default Scratchcard;
