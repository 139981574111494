import { firestore } from "firebase";
import React from "react";
import Button from "react-bootstrap/Button";
import {Link} from "react-router-dom";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import { compose, withStateHandlers } from "recompose";
import firebase from "../util/Firebase";
import "./Map.scss";
import moment from "moment";

class Map extends React.PureComponent {
  constructor(props) {
    super(props);

    this.googleMap = React.createRef();

    this.usersRef = firestore().collection("users");
    this.locationsRef = firestore().collection("locations");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");
    this.sessionsRef = firestore()
      .collection("sessions")
      .where("endTime", "==", null);
    this.userSessionsRef = firestore().collection("sessions");

    this.state = {
      sessions: [],
      locations: [],
      locationMap: {},
      userLocations: {},
      userAccountsMap: {},
      userMap: {},
      locationUserMap: {},
      mapUserZoom: 7,
      mapUserCentreLon: -2.0,
      mapUserCentreLat: 52.25
    };

    this.unSubLocations = this.locationsRef.onSnapshot(this.onLocationsUpdate);
    this.unSubUserAccounts = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
    this.unSubSessions = this.sessionsRef.onSnapshot(this.onSessionsUpdate);
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(user => {
      if (user && user.uid) {
        this.usersRef.doc(user.uid).onSnapshot(this.handleUserUpdate);
        this.unsubUserSessions = this.userSessionsRef
            .where("userId", "==", user.uid)
            .onSnapshot(this.onUserLocationsUpdate);
      }
    });
  }

  componentWillUnmount() {
    if (this.unSubLocations) this.unSubLocations();
    if (this.unSubUserAccounts) this.unSubUserAccounts();
    if (this.unSubSessions) this.unSubSessions();
  }

  handleUserUpdate = snapshot => {
    if (snapshot.exists) {
      const {
        displayName,
        Ttotal,
        mapUserZoom,
        mapUserCentreLon,
        mapUserCentreLat
      } = snapshot.data();
      let user = {
        uid: snapshot.id,
        displayName,
        Ttotal,
        mapUserZoom,
        mapUserCentreLon,
        mapUserCentreLat
      };
      if (!this.state.user && (user.Ttotal || user.mapUserZoom)) {
        if (!mapUserZoom || !mapUserCentreLon || !mapUserCentreLat) {
          user.mapUserZoom = 7;
          user.mapUserCentreLon = -2.0;
          user.mapUserCentreLat = 52.25;
        }
        this.setState({
          user,
          mapUserZoom: user.mapUserZoom,
          mapUserCentreLon: user.mapUserCentreLon,
          mapUserCentreLat: user.mapUserCentreLat
        });
      }
    }
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { uid: doc.id, displayName, photoURL };
    });
    this.setState({
      userAccountsMap
    });
  };

  onUserLocationsUpdate = querySnapshot => {
    this.updateMap();

    const userLocations = {};

    querySnapshot.forEach(doc => {

      const locationId = doc.data().locationRef.id;

      if(!userLocations[doc.locationRef]) {
        userLocations[locationId] = true;
      }
    });

    this.setState({
      userLocations
    });
  };

  onSessionsUpdate = querySnapshot => {
    this.updateMap();

    const sessions = [];
    const locationUserMap = {};

    querySnapshot.forEach(doc => {
      const { locationRef, userId } = doc.data();
      sessions.push({ locationId: locationRef.id, userId });

      if (!locationUserMap[locationRef.id]) {
        locationUserMap[locationRef.id] = [];
      }

      locationUserMap[locationRef.id].push(userId);
    });

    this.setState({
      sessions,
      locationUserMap
    });
  };

  updateMap = () => {
    if (this.googleMap.current) {
      this.setState({
        mapUserZoom: this.googleMap.current.getZoom(),
        mapUserCentreLon: this.googleMap.current.getCenter().lng(),
        mapUserCentreLat: this.googleMap.current.getCenter().lat()
      });
    }
  };

  onLocationsUpdate = querySnapshot => {
    const locations = [];
    const locationMap = {};

    querySnapshot.forEach(doc => {
      const { name, day, time, enabled, vicinity, lat, lon } = doc.data();

      locations.push({
        id: doc.id,
        name,
        day,
        time,
        enabled,
        vicinity,
        lat,
        lon
      });

      locationMap[doc.id] = { name, day, time, enabled, vicinity, lat, lon };

      this.setState({
        locations,
        locationMap
      });
    });
  };

  showInfo(a) {
    this.setState({ showInfoIndex: a });
  }

  getIconColor(location) {
    const locationUsers = Array.from(
      new Set(this.state.locationUserMap[location.id])
    );

    if (locationUsers.includes(this.state.user.uid)) {
      return "http://maps.google.com/mapfiles/ms/icons/red-dot.png";
    } else if (locationUsers.length > 0) {
      return "http://maps.google.com/mapfiles/ms/icons/orange-dot.png";
    } else if (location.id === "bonus-pub") {
      return "http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
    } else if (this.state.userLocations[location.id]) {
      return "http://maps.google.com/mapfiles/ms/icons/green-dot.png";
    } else {
      return "http://maps.google.com/mapfiles/ms/icons/blue-dot.png";
    }
  }

  getZIndex(location) {
    const locationUsers = Array.from(
      new Set(this.state.locationUserMap[location.id])
    );

    if (locationUsers.includes(this.state.user.uid)) {
      return 5;
    } else if (locationUsers.length > 0) {
      return 4;
    } else if (location.id === "bonus-pub") {
      return 3;
    } else if (this.state.userLocations[location.id]) {
      return 2;
    } else {
      return 1;
    }
  }

  getUsersCheckedIn = location => {
    const locationUsers = Array.from(
      new Set(this.state.locationUserMap[location.id])
    );
    let usersToOutput = [];

    if (locationUsers) {
      for (let i = 0; i < locationUsers.length; i++) {
        if (this.state.userAccountsMap[locationUsers[i]]) {
          usersToOutput.push(
            <a
              href={
                "/profile/" + this.state.userAccountsMap[locationUsers[i]].uid
              }
            >
              {this.state.userAccountsMap[locationUsers[i]].displayName}
            </a>
          );
        }
      }
    }

    return usersToOutput;
  };

  areUsersCheckedIn = location => {
    const locationUsers = Array.from(
      new Set(this.state.locationUserMap[location.id])
    );
    if (locationUsers.length > 0) {
      return true;
    }
    return false;
  };

  displayVisitedButton = location => {
    if (this.state.userLocations[location.id]) {
      return false;
    }
    return true;
  };

  addVisit = (location) => {

    let userLocationRef = firestore()
      .collection("users")
      .doc(this.state.user.uid)
      .collection("locations")
      .doc(location.id);

    let locationUserRef = firestore()
        .collection("locations")
        .doc(location.id)
        .collection("users")
        .doc(this.state.user.uid);

    firestore().runTransaction(async transaction => {
      const userLocationSnapshot = await transaction.get(userLocationRef);

      if (!userLocationSnapshot.exists) {
        transaction.set(userLocationRef, { Ttotal: 0 });
      } else if (!userLocationSnapshot.data().Ttotal) {
        let updatedTotal = 0;
        if (userLocationSnapshot.data().totalTime) {
          updatedTotal = userLocationSnapshot.data().totalTime;
        }
        transaction.update(userLocationRef, { Ttotal: updatedTotal });
      }
    });

    firestore().runTransaction(async transaction => {
      const locationUserSnapshot = await transaction.get(locationUserRef);

      if (!locationUserSnapshot.exists) {
        transaction.set(locationUserRef, { Ttotal: 0 });
      } else if (!locationUserSnapshot.data().Ttotal) {
        let updatedTotal = 0;
        if (locationUserSnapshot.data().totalTime) {
          updatedTotal = locationUserSnapshot.data().totalTime;
        }
        transaction.update(locationUserRef, { Ttotal: updatedTotal });
      }
    });

    const date = moment.utc().toDate();
    this.userSessionsRef.add({}).then(docRef => {
      const newSession = this.userSessionsRef.doc(docRef.id);
      newSession.set({
        locationRef: firestore().collection("locations").doc(location.id),
        userRef: firestore().collection("users").doc(this.state.user.uid),
        userId: this.state.user.uid,
        locationId: location.id,
        startTime: date,
        endTime: date,
        shareRate: 0,
        coinRate: 0,
        locationName: location.name,
        userName: this.state.user.displayName
      });
    });
  };

  onZoomChanged = () => {
    this.usersRef.doc(this.state.user.uid).update({
      mapUserZoom: this.googleMap.current.getZoom()
    });
  };

  onCentreChanged = () => {
    this.usersRef.doc(this.state.user.uid).update({
      mapUserCentreLat: this.googleMap.current.getCenter().lat(),
      mapUserCentreLon: this.googleMap.current.getCenter().lng()
    });
  };

  render() {
    const MapComponent = compose(
      withStateHandlers(
        () => ({
          isOpen: false
        }),
        {
          onToggleOpen: ({ isOpen }) => () => ({
            isOpen: !isOpen
          }),
          showInfo: ({ showInfo, isOpen }) => index => ({
            isOpen: !isOpen,
            showInfoIndex: index
          })
        }
      ),
      withScriptjs,
      withGoogleMap
    )(props => (
      <GoogleMap
        defaultZoom={this.state.mapUserZoom}
        defaultCenter={{
          lat: this.state.mapUserCentreLat,
          lng: this.state.mapUserCentreLon
        }}
        ref={this.googleMap}
        onZoomChanged={this.onZoomChanged}
        onDragEnd={this.onCentreChanged}
      >
        {this.state.locations.map((location, index) => (
          <Marker
            key={location.id}
            icon={this.getIconColor(location)}
            zIndex={this.getZIndex(location)}
            position={{ lat: location.lat, lng: location.lon }}
            onClick={() => {
              props.showInfo(index);
            }}
            title={location.name}
          >
            {props.isOpen && props.showInfoIndex === index && (
              <InfoWindow onCloseClick={props.onToggleOpen}>
                <div className="info-window">
                  <div className={"info-header"}><Link to={`/location/${location.id}`}>{location.name}</Link></div>
                  <div className={"info-vicinity"}>{location.vicinity}</div>
                  <div
                    className={this.areUsersCheckedIn(location) ? "" : "d-none"}
                  >
                    <div className={"info-vicinity"}>Checked in: </div>
                    {this.getUsersCheckedIn(location).map((user, index) => (
                      <div>{user}</div>
                    ))}
                  </div>
                  <div
                    className={
                      this.displayVisitedButton(location)
                        ? "record-visit d-flex justify-content-end"
                        : "record-visit d-none"
                    }
                  >
                    <Button
                      size="sm"
                      onClick={this.addVisit.bind(this, location)}
                    >
                      Record Visit
                    </Button>
                  </div>
                </div>
              </InfoWindow>
            )}
          </Marker>
        ))}
      </GoogleMap>
    ));

    if (!this.state.user) {
      return null;
    }

    return (
      <div>
        <MapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCb8xifKA7VtGPWOE11Qb5eXA-8T8vumKM&v=3.exp&libraries=geometry,drawing,places"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `79vh` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    );
  }
}

export default Map;
