import React from "react";
import "./Navigation.css";
import { NavLink, RouteComponentProps } from "react-router-dom";
import NavDropdown from "react-bootstrap/NavDropdown";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";

interface Props extends RouteComponentProps {}

class Navigation extends React.PureComponent<Props> {
  public render() {
    return (
      <Container>
        <Nav variant="pills" className="my-3 mr-auto">
          <NavLink className="nav-link" to="/leaderboard">
            Tracker
          </NavLink>
          <NavLink className="nav-link" to="/coin">
            PubCoin
          </NavLink>
          <NavLink className="nav-link" to="/exchange">
            Exchange
          </NavLink>
          <NavDropdown
            className="ml-auto"
            title="More"
            id="nav-dropdown"
            alignRight
          >
            <NavDropdown.Item onClick={this.navigateToChampionships}>
              Championships
            </NavDropdown.Item>
            <NavDropdown.Item onClick={this.navigateToSearh}>
              Search
            </NavDropdown.Item>
            <NavDropdown.Item onClick={this.navigateToMap}>
              Map
            </NavDropdown.Item>
            <NavDropdown.Item onClick={this.navigateToPubs}>
              Pub Rankings
            </NavDropdown.Item>
            <NavDropdown.Item onClick={this.navigateToLedger}>
              Ledger
            </NavDropdown.Item>
            <NavDropdown.Item onClick={this.navigateToHelp}>
              Help
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Container>
    );
  }

  private navigateToChampionships = () =>
    this.props.history.push("/championships");

  private navigateToLedger = () => this.props.history.push("/ledger");

  private navigateToMap = () => this.props.history.push("/map");

  private navigateToHelp = () => this.props.history.push("/help");

  private navigateToSearh = () => this.props.history.push("/search");

  private navigateToPubs = () => this.props.history.push("/pubs");
}

export default Navigation;
