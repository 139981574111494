import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { User } from "../../Types";
import UserHat from "./UserHat";

interface Props {
  user: User;
  photoURL?: string;
}

interface State {}

class UserAvatar extends React.PureComponent<Props, State> {
  public render() {
    const { user, photoURL } = this.props;

    return (
      <Link
        to={`/profile/${user.uid || user.id}`}
        className={classNames("user-avatar", user.itemImage)}
      >
        <UserHat user={user} />
        <img
          className="rounded-circle display-picture"
          src={photoURL || user.photoURL}
          alt="User avatar"
        />
      </Link>
    );
  }
}

export default UserAvatar;
