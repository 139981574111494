import React from "react";
import { User } from "../../Types";
import "./UserAvatar.scss";

interface Props {
  user: User;
}

const UserHat: React.FunctionComponent<Props> = (props: Props) => {
  if (!props.user.isChampion && !props.user.itemHat) {
    return null;
  }

  if (props.user.isChampion) {
    return (
      <span className="hat" role="img" aria-label="Hat">
        👑
      </span>
    );
  }

  if (
    props.user.itemHat &&
    props.user.itemHat.match(/\.(jpeg|jpg|gif|png)$/) != null
  ) {
    return (
      <div className="hat d-flex align-items-center justify-content-center">
        <img src={props.user.itemHat} alt="hat" />
      </div>
    );
  }

  return (
    <span className="hat" role="img" aria-label="Hat">
      {props.user.itemHat}
    </span>
  );
};

export default UserHat;
