import { faClock, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestore } from "firebase";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import PubFinder from "../championships/ChampionshipsPubFinder";
import EmptyState from "../page/EmptyState";
import { Location } from "../sessions/Types";
import { convertSnapshotToType } from "../util/TypeUtils";

interface Props {}

interface State {
  loading: boolean;
  locations: ReadonlyArray<Location>;
}

class ManagePage extends React.PureComponent<Props, State> {
  private unsubscribe?: () => void;
  private locationsRef: firestore.CollectionReference;

  public constructor(props: Props) {
    super(props);

    this.locationsRef = firestore().collection(`locations`);

    this.state = {
      loading: false,
      locations: []
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.locationsRef
      .orderBy("updated", "desc")
      .onSnapshot(snapshot => {
        this.setState({
          loading: false,
          locations: snapshot.docs.map(doc =>
            convertSnapshotToType<Location>(doc)
          )
        });
      });
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  public render() {
    const { locations, loading } = this.state;

    return (
      <Card className="my-3">
        <Card.Body className="pb-0">
          <Card.Title>Add a pub</Card.Title>
          <Card.Subtitle className="text-muted">
            If the pub you select already exists this will just change the
            opening time to today.
          </Card.Subtitle>
        </Card.Body>
        <Card.Body>
          <PubFinder onPubSelected={this.handleCreatePub} />
        </Card.Body>
        <EmptyState content={locations} loading={loading}>
          <ListGroup variant="flush">
            {locations.map(location => (
              <ListGroup.Item key={location.id}>
                <div>{location.name}</div>
                <small>
                  {location.vicinity && (
                    <a
                      title={String(location.vicinity)}
                      href={`http://www.google.com/maps?q=${location.name}+${location.vicinity}`}
                    >
                      <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                      {String(location.vicinity)}
                    </a>
                  )}
                </small>
                <div className="text-muted">
                  <small>
                    <FontAwesomeIcon icon={faClock} />{" "}
                    {this.getLocationInfo(location)}
                  </small>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </EmptyState>
      </Card>
    );
  }

  private handleCreatePub = async (place: any) => {
    const now = new Date();

    const newPubRef = this.locationsRef.doc(place.placeId);

    const newPub = {
      day: now.getDay(),
      distance: 0.1,
      enabled: true,
      lat: place.lat,
      lon: place.lng,
      name: place.name,
      vicinity: place.vicinity,
      placeId: place.placeId,
      id: newPubRef.id,
      time: now.getUTCHours() - 5,
      bonus: false,
      updated: now
    };

    const pub = await newPubRef.get();

    if (pub.exists) {
      newPubRef.update(newPub);
    } else {
      newPubRef.set(newPub, { merge: true });
    }
  };

  private getLocationInfo = (location: Location) => {
    const day = Number(location.day);
    const time = Number(location.time);

    const now = moment.utc();
    const openFrom = now.clone().startOf("day");
    const openTo = openFrom
      .clone()
      .add(1, "days")
      .hours(6);

    if (day >= 0) {
      if (now.isoWeekday() > location.day) {
        openFrom.add(1, "weeks");
        openTo.add(1, "weeks");
      }

      openFrom.day(day);
      openTo.day(day + 1);
    }

    if (time >= 0) {
      openFrom.hours(time);
    }

    const locationOpen = now.isAfter(openFrom) && now.isBefore(openTo);
    if (time < 0 && day < 0) {
      return `Open every day at any time`;
    } else if (day < 0) {
      return locationOpen
        ? `Open every day from ${openFrom.format("h:mm a")} (now)`
        : `Open every day from ${openFrom.format(
            "h:mm a"
          )} (${openFrom.fromNow()})`;
    } else if (time < 0) {
      return locationOpen
        ? `Open any time today (now)`
        : `Opens ${openFrom.format(
            "dddd"
          )}s at any time (${openFrom.fromNow()})`;
    } else {
      return locationOpen
        ? `Open now!`
        : `Opens ${openFrom.calendar()} (${openFrom.fromNow()})`;
    }
  };
}

export default ManagePage;
