import React, { Component } from "react";
import "../../App.css";
import "./Coin.css";
import firebase from "../util/Firebase";
import CoinNav from "./CoinNav";
import FlipMove from "react-flip-move";
import DisplayName from "../page/DisplayName";
import PubCoin from "./PubCoin";
import Card from "react-bootstrap/Card";
import EmptyState from "../page/EmptyState";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

class CoinList extends Component {
  constructor(props) {
    super(props);

    this.usersRef = firebase.firestore().collection("users");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");

    this.unsubUsers = null;
    this.unsubUserAccounts = null;

    this.state = {
      users: [],
      userMap: {},
      userAccounts: {},
      items: [],
      amount: 0,
      userTransfer: "",
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubUsers = this.usersRef
      .orderBy("coin", "desc")
      .onSnapshot(this.onUsersUpdate);
    this.unsubUserAccounts = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  componentWillUnmount() {
    if (this.unsubUsers) this.unsubUsers();
    if (this.unsubUserAccounts) this.unsubUserAccounts();
  }

  onUsersUpdate = querySnapshot => {
    if (firebase.auth().currentUser) {
      this.setState({
        uid: firebase.auth().currentUser.uid
      });
    }

    const users = [];
    const userMap = {};
    querySnapshot.forEach(doc => {
      const {
        coin,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage
      } = doc.data();

      if (typeof coin === "number") {
        const user = {
          id: doc.id,
          coin,
          displayName,
          photoURL,
          isChampion,
          itemHat,
          itemImage
        };
        users.push(user);
        userMap[user.id] = user;
      }
    });

    this.setState({
      loading: false,
      users,
      userMap
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccounts = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccounts[doc.id] = { displayName, photoURL };
      this.setState({
        userAccounts
      });
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  getCoin = id => {
    if (this.state.userMap[id]) {
      return this.state.userMap[id].coin;
    }
  };

  getDisplayNameClass(user) {
    if (user.itemHat || user.isChampion) {
      return "mt-m10";
    } else {
      return "";
    }
  }

  getUserFromAccount = user => {
    if (this.state.userAccounts[user.uid]) {
      user.displayName = this.state.userAccounts[user.uid].displayName;
      user.photoURL = this.state.userAccounts[user.uid].photoURL;
    }
    return user;
  };

  render() {
    const { users, loading } = this.state;

    return (
      <>
        <CoinNav
          location={this.props.location.pathname}
          uid={this.state.uid}
          user={this.state.userMap[this.state.uid]}
        />
        <Card>
          <Card.Header as="h5">User balances</Card.Header>
          <EmptyState
            content={users}
            loading={loading}
            message="Turns out you can't time the market after all."
          >
            <ListGroup variant="flush">
              <FlipMove typeName={null}>
                {this.state.users.map(user => (
                  <ListGroup.Item key={user.id || user.uid}>
                    <Row>
                      <Col className="d-flex align-right align-items-center">
                        <DisplayName user={this.getUserFromAccount(user)} />
                      </Col>
                      <Col className="col-auto d-flex align-right align-items-center lead">
                        <PubCoin amount={user.coin} />
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </FlipMove>
            </ListGroup>
          </EmptyState>
        </Card>
      </>
    );
  }
}

export default CoinList;
