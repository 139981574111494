import { RouteComponentProps } from "react-router";
import React from "react";
import Card from "react-bootstrap/Card";
import ChampionshipsRound from "./ChampionshipsRound";
import { Championship } from "./Types";
import { firestore, auth } from "firebase";
import { convertSnapshotToType } from "../util/TypeUtils";
import ChampionshipsCreate from "./ChampionshipsCreate";
import ChampionshipsWinner from "./ChampionshipsWinner";
import { LoadingCard } from "../page/Loading";
import { Link } from "react-router-dom";
import { ROUND_NAMES } from "../util/Constants";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

interface Props extends RouteComponentProps<{ championshipId: string }> {}

interface State {
  championship?: Championship;
  lastChampionship?: Championship;
  showLastChampionship?: boolean;
  loading?: boolean;
}

class ChampionshipsView extends React.PureComponent<Props, State> {
  private championshipRef: firestore.DocumentReference;
  private unsubChampionships?: () => void;

  constructor(props: Props) {
    super(props);

    const {
      match: {
        params: { championshipId }
      }
    } = props;

    this.championshipRef = firestore().doc(`championships/${championshipId}`);

    this.state = {};
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubChampionships = this.championshipRef.onSnapshot(
      this.handleChampionshipUpdate
    );
  }

  public componentWillUnmount() {
    if (this.unsubChampionships) this.unsubChampionships();
  }

  public render() {
    const { loading, showLastChampionship } = this.state;

    const championship = showLastChampionship
      ? this.state.lastChampionship
      : this.state.championship;

    if (!championship && loading) {
      return (
        <Card>
          <Card.Body>
            <Card.Title>Championship</Card.Title>
            <Card.Subtitle className="text-muted">Loading...</Card.Subtitle>
          </Card.Body>
          <LoadingCard />
        </Card>
      );
    }

    if (!championship) {
      return (
        <Card>
          <Card.Body>
            <Card.Title>Championship</Card.Title>
            <Card.Subtitle className="text-muted">Uhoh...</Card.Subtitle>
          </Card.Body>
          <Card.Body className="text-danger">
            Something went wrong! Couldn't find this championship. Please{" "}
            <Link to="/championships">go back</Link>.
          </Card.Body>
        </Card>
      );
    }

    return (
      <Card className="my-3">
        <Card.Body>
          <Card.Title>Championship: {championship.name}</Card.Title>
          <Card.Subtitle className="text-muted">
            {this.subtitle(championship)}
          </Card.Subtitle>
        </Card.Body>

        {this.cardContent(championship)}
      </Card>
    );
  }

  private subtitle = (championship: Championship) => {
    if (championship.winner) {
      return "And the winner is...";
    }

    if (championship.round < 1) {
      return "Add a pub to the list and wait for the championship to begin. You can only add one pub - make sure it is unique!";
    }

    const helpText = championship.teamCount
      ? `${Math.ceil(championship.teamCount / 2)} votes required to win`
      : "";

    return (
      <Row>
        <Col>{this.getRoundText(championship)}</Col>
        <Col className="col-auto text-right">{helpText}</Col>
      </Row>
    );
  };

  private getRoundText = (championship: Championship) => {
    if (championship.totalRounds) {
      const roundsLeft = championship.totalRounds - championship.round;

      if (ROUND_NAMES[roundsLeft + 1]) {
        return ROUND_NAMES[roundsLeft + 1];
      }
    }

    return `Round ${championship.round}${
      championship.totalRounds
        ? " of " + Math.max(championship.totalRounds, championship.round)
        : ""
    }`;
  };

  private cardContent = (championship: Championship) => {
    const currentUser = auth().currentUser;

    if (!currentUser) {
      return <Card.Body>Please log in first.</Card.Body>;
    }

    if (championship.winner) {
      return (
        <ChampionshipsWinner
          championship={championship}
          winnerRef={championship.winner}
        />
      );
    }

    if (this.state.loading) {
      return <LoadingCard />;
    }

    if (championship.round < 1) {
      return (
        <ChampionshipsCreate
          championship={championship}
          round={championship.round}
          currentUser={currentUser}
        />
      );
    }

    return (
      <ChampionshipsRound
        key={championship.round}
        championship={championship}
        round={championship.round}
      />
    );
  };

  private handleChampionshipUpdate = (snapshot: firestore.DocumentSnapshot) => {
    const championship = convertSnapshotToType<Championship>(snapshot);

    const showLastChampionship =
      this.state.championship &&
      championship.round > 0 &&
      this.state.championship.round !== championship.round;

    this.setState({
      loading: false,
      showLastChampionship,
      lastChampionship: this.state.championship,
      championship
    });

    setTimeout(() => this.setState({ showLastChampionship: false }), 3000);
  };
}

export default ChampionshipsView;
