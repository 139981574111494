import {
  faCheck,
  faCheckDouble,
  faHandHoldingUsd,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestore } from "firebase";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Flipped, Flipper } from "react-flip-toolkit";
import { User, UserAccount } from "../../Types";
import EmptyState from "../page/EmptyState";
import { Session } from "../sessions/Types";
import { convertSnapshotToType, convertToMoment } from "../util/TypeUtils";
import { getCurrentTime } from "./Helpers";
import LeaderboardRow from "./LeaderboardRow";
import Button from "react-bootstrap/Button";

export enum DisplayOptions {
  Total = "total",
  Week = "week",
  Month = "month",
  Year = "year"
}

interface Props {
  toggleDisplay: any;
}

interface State {
  users: ReadonlyArray<User>;
  sessions: ReadonlyArray<Session>;
  userAccounts: { [id: string]: UserAccount };
  display: DisplayOptions;
  loading: boolean;
}

class Leaderboard extends React.PureComponent<Props, State> {
  private usersRef: firestore.CollectionReference = firestore().collection(
    "users"
  );
  private userAccountsRef: firestore.CollectionReference = firestore().collection(
    "userAccounts"
  );
  private sessionsRef: firestore.CollectionReference = firestore().collection(
    "sessions"
  );

  private unsubUsers?: () => void;
  private unsubUserAccounts?: () => void;
  private unsubSessions?: () => void;

  private interval?: number;

  public constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      sessions: [],
      userAccounts: {},
      display: DisplayOptions.Year,
      loading: false
    };
  }

  getUserFromAccount = (user: User) => {
    if (this.state.userAccounts[user.uid]) {
      user.displayName = this.state.userAccounts[user.uid].displayName;
      user.photoURL = this.state.userAccounts[user.uid].photoURL;
    }
    return user;
  };

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubUsers = this.usersRef
      .where(
        "updated",
        ">",
        moment()
          .utc()
          .subtract(1, "month")
          .toDate()
      )
      .onSnapshot(this.updateUsers);
    this.unsubUserAccounts = this.userAccountsRef.onSnapshot(
      this.updateUserAccounts
    );
    this.unsubSessions = this.sessionsRef
      .where("endTime", "==", null)
      .onSnapshot(this.updateSessions);
    this.interval = window.setInterval(this.sortAndSetUsers, 1000);
  }

  public componentWillUnmount() {
    this.unsubUsers && this.unsubUsers();
    this.unsubUserAccounts && this.unsubUserAccounts();
    this.unsubSessions && this.unsubSessions();
    window.clearInterval(this.interval);
  }

  public render() {
    const { users, display, loading = true, sessions } = this.state;

    const flipKey = users.reduce((acc: string, user) => acc + user.uid, "");

    return (
      <Card className="leaderboard my-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-0">Leaderboard - Time</h5>
            </Col>
            <Col className="col-auto">
              <Button
                  onClick={this.props.toggleDisplay.bind(this, "visits")}
                  size="sm"
              >
                Visits
              </Button>
            </Col>
            <Col className="col-auto">
              <Form.Control
                size="sm"
                value={display}
                onChange={this.handleDisplayChange}
                as="select"
              >
                <option value={DisplayOptions.Total}>Total</option>
                <option value={DisplayOptions.Year}>Year</option>
                <option value={DisplayOptions.Month}>Month</option>
                <option value={DisplayOptions.Week}>Week</option>
              </Form.Control>
            </Col>
          </Row>
        </Card.Header>
        <EmptyState
          content={users}
          loading={loading}
          message="Pretty empty in here huh? Try checking in."
        >
          <Flipper flipKey={flipKey} spring="gentle">
            <ListGroup variant="flush">
              {users.map(this.getUser).map((user, index) => (
                <Flipped key={user.uid} flipId={user.uid} stagger>
                  {flippedProps => (
                    <LeaderboardRow
                      session={sessions.find(
                        s => s.userId === user.id || s.userId === user.uid
                      )}
                      position={index + 1}
                      user={this.getUserFromAccount(user)}
                      time={this.calculateUserMillis(user)}
                      display={display}
                      flippedProps={flippedProps}
                    />
                  )}
                </Flipped>
              ))}
            </ListGroup>
          </Flipper>
        </EmptyState>
        <Card.Footer>
          <Row>
            <Col xs="6" sm="auto" className="text-center">
              {" "}
              <FontAwesomeIcon className="text-success" icon={faCheck} />{" "}
              Checked in
            </Col>
            <Col xs="6" sm="auto" className="text-center">
              <FontAwesomeIcon className="text-primary" icon={faCheckDouble} />{" "}
              Double coin
            </Col>
            <Col xs="6" sm="auto" className="text-center">
              <FontAwesomeIcon className="text-info" icon={faHandHoldingUsd} />{" "}
              Shareholder
            </Col>
            <Col xs="6" sm="auto" className="text-center">
              <FontAwesomeIcon className="text-secondary" icon={faStar} /> Bonus
              pub
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  }

  private handleDisplayChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({
      display: e.target.value as DisplayOptions
    });

  private getUser = (user: User) => ({
    ...user,
    ...(this.state.userAccounts[user.id]
      ? this.state.userAccounts[user.id]
      : {})
  });

  private updateUsers = (snapshot: firestore.QuerySnapshot) => {
    const users = snapshot.docs.map(doc => convertSnapshotToType<User>(doc));
    this.setState({ loading: false });
    this.sortAndSetUsers(users);
  };

  private updateSessions = (snapshot: firestore.QuerySnapshot) =>
    this.setState({
      sessions: snapshot.docs.map(doc => convertSnapshotToType<Session>(doc))
    });

  private updateUserAccounts = (snapshot: firestore.QuerySnapshot) => {
    const userAccounts: { [id: string]: UserAccount } = snapshot.docs.reduce(
      (
        map: { [id: string]: UserAccount },
        doc: firestore.QueryDocumentSnapshot
      ) => ({ ...map, [doc.id]: doc.data() as UserAccount }),
      {}
    );

    this.setState({ loading: false, userAccounts });
  };

  private sortAndSetUsers = (updatedUserList?: ReadonlyArray<User>) => {
    const users = updatedUserList ? updatedUserList : this.state.users;

    this.setState({
      users: users
        .slice()
        .sort(
          (a: User, b: User) =>
            this.calculateUserMillis(b) - this.calculateUserMillis(a)
        )
    });
  };

  private calculateUserMillis = (user: User): number => {
    const { sessions, display } = this.state;

    const session = sessions.find(
      s => s.userId === user.id || s.userId === user.uid
    );
    const duration = getCurrentTime(user, display);

    if (!session) {
      return duration;
    }

    const startTime = convertToMoment(session.startTime);
    const sessionDuration = moment().diff(startTime);

    return duration + sessionDuration;
  };
}

export default Leaderboard;
