import React from "react";
import Card from "react-bootstrap/Card";
import { firestore } from "firebase";
import { convertSnapshotToType } from "../util/TypeUtils";
import { Team } from "./Types";
import ListGroup from "react-bootstrap/ListGroup";
import PubFinder from "./ChampionshipsPubFinder";
import EmptyState from "../page/EmptyState";
import { Championship } from "./Types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import NextRoundButton from "./ChampionshipNextRound";
import FlipMove from "react-flip-move";

interface Props {
  championship: Championship;
  round: number;
  currentUser: firebase.User;
}

interface State {
  teams: ReadonlyArray<Team>;
  newTeam: Team | null;
  loading: boolean;
}

class ChampionshipsCreate extends React.PureComponent<Props, State> {
  private championshipRef: firestore.DocumentReference;
  private teamsRef: firestore.CollectionReference;
  private unsubTeams?: () => void;

  public constructor(props: Props) {
    super(props);

    const { championship } = props;

    this.championshipRef = firestore().doc(`championships/${championship.id}`);
    this.teamsRef = this.championshipRef.collection(`competitors`);

    this.state = {
      teams: [],
      newTeam: null,
      loading: false
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubTeams = this.teamsRef.onSnapshot(this.handleTeamsUpdate);
  }

  public componentWillUnmount() {
    if (this.unsubTeams) this.unsubTeams();
  }

  public render() {
    const { championship } = this.props;
    const { teams = [], loading } = this.state;

    const minTeams = 2;
    const minTeamsForCheckin = new Date().getUTCDay() === 4 ? 3 : 6;
    const teamCount = teams.length;

    const requiredTeams = Math.max(minTeams - teamCount, 0);
    const requiredTeamsForCheckin = Math.max(minTeamsForCheckin - teamCount, 0);

    const helpText = requiredTeams
      ? `Add ${requiredTeams} more ${
          requiredTeams > 1 ? "pubs" : "pub"
        } to start...`
      : requiredTeamsForCheckin
      ? `Add ${requiredTeamsForCheckin} more ${
          requiredTeamsForCheckin > 1 ? "pubs" : "pub"
        } to enable check in...`
      : `Waiting for ${championship.creatorName} to start...`;

    return (
      <>
        <Card.Body className="pt-0">
          <PubFinder onPubSelected={this.handleCreateTeam} />
        </Card.Body>
        <EmptyState content={teams} loading={loading} message={"No pubs yet."}>
          <ListGroup variant="flush">
            <FlipMove typeName={null}>
              {teams.map(team => (
                <ListGroup.Item key={team.placeId || team.id}>
                  <div>{team.name}</div>
                  <div className="text-muted">Added by {team.creatorName}</div>
                </ListGroup.Item>
              ))}
            </FlipMove>
          </ListGroup>
        </EmptyState>
        <Card.Footer>
          <Row>
            <Col className="d-flex align-items-center">
              <div
                key={requiredTeams + requiredTeamsForCheckin}
                className="animated flash"
              >
                {helpText}
              </div>
            </Col>
            <Col className="col-auto d-flex align-items-center">
              <NextRoundButton
                championship={championship}
                disabled={requiredTeams > 0}
              />
            </Col>
          </Row>
        </Card.Footer>
      </>
    );
  }

  private handleTeamsUpdate = (snapshot: firestore.QuerySnapshot) => {
    this.setState({
      loading: false,
      teams: snapshot.docs.map(doc => convertSnapshotToType<Team>(doc))
    });
  };

  private handleCreateTeam = async (place: any) => {
    const now = new Date();
    const competitorRef = this.teamsRef.doc(this.props.currentUser.uid);

    const team: Team = {
      id: competitorRef.id,
      creator: this.props.currentUser.uid,
      creatorName: this.props.currentUser.displayName || "Unknown User",
      creatorPhotoURL: this.props.currentUser.photoURL,
      created: now,
      updated: now,
      name: place.name,
      placeId: place.placeId,
      rating: place.rating,
      vicinity: place.vicinity,
      lat: place.lat,
      lng: place.lng,
      competing: true
    };

    competitorRef.set(team);

    this.setState({
      newTeam: null
    });
  };
}

export default ChampionshipsCreate;
