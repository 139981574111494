import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";
import { getTies, getRoundType } from "../util/Utils";

class Championship extends Component {
  constructor(props) {
    super(props);
    this.competitionRef = firebase
      .firestore()
      .collection("competitions")
      .doc(this.props.match.params.id);
    this.competitorsRef = this.competitionRef.collection("competitors");
    this.usersRef = this.competitionRef.collection("users");
    this.unsubscribeCompetition = null;
    this.unsubscribeCompetitors = null;
    this.unsubscribeUsers = null;
    this.state = {
      competition: {},
      competitors: [],
      users: [],
      name: ""
    };
  }

  componentDidMount() {
    this.unsubscribeCompetition = this.competitionRef.onSnapshot(
      this.onCompetitionUpdate
    );
    this.unsubscribeCompetitors = this.competitorsRef
      .orderBy("created", "asc")
      .onSnapshot(this.onCompetitorsUpdate);
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
  }

  onCompetitionUpdate = () => {
    let competition = {};
    this.competitionRef.onSnapshot(doc => {
      const {
        created,
        creatorUid,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        round,
        advance
      } = doc.data();
      competition = {
        id: doc.id,
        created,
        creatorUid,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        round,
        advance
      };
      this.setState({
        competition
      });
      if (competition.status !== 0) {
        history.push(
          "/cop/view/" +
            this.props.match.params.id +
            "/" +
            firebase.auth().currentUser.uid
        );
      }
    });
  };

  onCompetitorsUpdate = querySnapshot => {
    const competitors = [];
    querySnapshot.forEach(doc => {
      const {
        id,
        created,
        creatorUid,
        creatorName,
        name,
        status,
        votes,
        result,
        photoURL
      } = doc.data();
      competitors.push({
        id,
        created,
        creatorUid,
        creatorName,
        name,
        status,
        votes,
        result,
        photoURL
      });
    });
    this.setState({
      competitors
    });
  };

  onUsersUpdate = querySnapshot => {
    const users = [];
    querySnapshot.forEach(doc => {
      const { teamOwner, voteStatus } = doc.data();
      users.push({
        teamOwner,
        voteStatus
      });
    });
    this.setState({
      users
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();

    let { name } = this.state;
    if (name) {
      const currentTime = Math.round(new Date().getTime() / 1000);

      this.competitorsRef.add({}).then(docRef => {
        const newCompetitorRef = this.competitorsRef.doc(docRef.id);
        newCompetitorRef
          .set({
            id: docRef.id,
            name,
            creatorUid: firebase.auth().currentUser.uid,
            creatorName: firebase.auth().currentUser.displayName,
            created: currentTime,
            status: 0,
            votes: 0,
            result: 0,
            photoURL: firebase.auth().currentUser.photoURL
          })
          .catch(error => {
            console.error("Error creating competitor: ", error);
          });
      });

      const newUserRef = this.usersRef.doc(firebase.auth().currentUser.uid);
      newUserRef
        .set({
          teamOwner: true
        })
        .catch(error => {
          console.error("Error adding competition's user: ", error);
        });

      this.setState({
        name: ""
      });
    }
  };

  startCompetition = () => {
    if (this.state.competitors.length > 1) {
      const ties = getTies(this.state.competitors);
      let teamCount = 1;
      for (let index = 0; index < ties.length; index++) {
        if (ties[index]["homeTeam"] && ties[index]["awayTeam"]) {
          this.competitorsRef.doc(ties[index]["homeTeam"]).update({
            status: teamCount
          });
          teamCount++;
          this.competitorsRef.doc(ties[index]["awayTeam"]).update({
            status: teamCount
          });
          teamCount++;
        }
      }

      const numberOfUsers = this.state.users.length;
      let votesRequired = 0;
      if (numberOfUsers % 2 === 0) {
        votesRequired = numberOfUsers / 2 + 1;
      } else {
        votesRequired = (numberOfUsers + 1) / 2;
      }

      this.competitionRef.update({
        status: 1,
        votesRequired: votesRequired,
        round: getRoundType(this.state.competitors)
      });
    }
  };

  getCompetitionTitle = () => {
    if (this.state.competition.title) {
      return <span>{this.state.competition.title.trim()}: Add competitor</span>;
    }
    return <span>Add competitor</span>;
  };

  render() {
    const { name } = this.state;

    let createButton = "";
    if (firebase.auth().currentUser) {
      createButton = (
        <button type="submit" className="btn btn-primary">
          Add competitor
        </button>
      );
    } else {
      createButton = (
        <button className="btn btn-primary" disabled>
          Please login
        </button>
      );
    }

    let startButtonElement = "";
    if (
      firebase.auth().currentUser &&
      firebase.auth().currentUser.uid === this.state.competition.creatorUid &&
      this.state.competitors.length > 1
    ) {
      startButtonElement = (
        <div className="card box">
          <div>
            <button
              className="btn btn-success non-form-button cop-right"
              onClick={this.startCompetition}
            >
              Start competition
            </button>
          </div>
        </div>
      );
    } else if (
      firebase.auth().currentUser &&
      firebase.auth().currentUser.uid === this.state.competition.creatorUid
    ) {
      startButtonElement = (
        <div className="card box">
          <div>
            <button
              className="btn btn-success non-form-button cop-right"
              onClick={this.startCompetition}
              disabled
            >
              Start competition
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="card nav-bar">
            <div className="nav-bar-option-wrapper">
              <Link to={`/cop`}>
                <button className="btn btn-primary">Competitions</button>
              </Link>
            </div>
          </div>
          <div className="card box">
            <label for="name">
              <div className="h4">{this.getCompetitionTitle()}</div>
            </label>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  placeholder="Competitor name"
                />
              </div>
              {createButton}
            </form>
          </div>
          <div className="card box">
            <div className="card-title h4 display-title">Competitors</div>
            <div>
              <table className="table table-or">
                <tbody>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Owner</th>
                  </tr>
                  {this.state.competitors.map((competitor, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{competitor.name}</td>
                      <td>
                        <img
                          className="user-img"
                          src={competitor.photoURL}
                          alt="Competitor avatar"
                        />
                        {competitor.creatorName}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          {startButtonElement}
        </div>
      </div>
    );
  }
}

export default Championship;
