import React from "react";
import "../nav/Navigation.css";
import { NavLink } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { auth } from "firebase";

class ExchangeNav extends React.PureComponent {
  render() {
    const currentUser = auth().currentUser;

    return (
      <Nav variant="tabs" className="my-3">
        <NavLink className="nav-link text-secondary" to="/exchange/market">
          Market
        </NavLink>
        <NavLink
          className="nav-link text-secondary"
          to={currentUser ? `/exchange/shares/${currentUser.uid}` : "auth"}
        >
          Portfolio
        </NavLink>
        <NavLink
          className="nav-link text-secondary"
          to={currentUser ? `/exchange/trade/${currentUser.uid}` : "auth"}
        >
          Trade
        </NavLink>
      </Nav>
    );
  }
}

export default ExchangeNav;
