import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import { secondsToString } from "../util/Utils";
import Card from "react-bootstrap/Card";
import UserAvatar from "../page/UserAvatar";
import PubCoin from "../coin/PubCoin";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import "./UserProfile.scss";
import PubCoinBasic from "../coin/PubCoinBasic";
import { LoadingCard } from "../page/Loading";
import {Link} from "react-router-dom";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.locationSubscription = null;
    this.userLocationSubscription = null;
    this.unsubscribeExchange = null;
    this.unsubscribeUserExchange = null;
    this.userAccountsSubscription = null;

    this.usersRef = firebase.firestore().collection("users");
    this.locationsRef = firebase.firestore().collection("locations");
    this.exchangeRef = firebase.firestore().collection("exchange");
    this.userExchangeRef = this.usersRef
      .doc(this.props.match.params.id)
      .collection("exchange");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");

    this.state = {
      locations: [],
      userLocations: [],
      locationMap: {},
      userAccountsMap: {},
      location: "",
      checkingLocation: false,
      exchangeMap: {},
      userExchange: [],
      totalShareQuantity: 0
    };
  }

  componentDidMount() {
    firebase
      .firestore()
      .collection("users")
      .doc(this.props.match.params.id)
      .onSnapshot(this.handleUserUpdate);

    this.unsubscribeExchange = this.exchangeRef.onSnapshot(
      this.onExchangeUpdate
    );
    this.unsubscribeUserExchange = this.userExchangeRef
      .orderBy("quantity", "desc")
      .onSnapshot(this.onUserExchangeUpdate);

    this.locationSubscription = this.locationsRef
      .orderBy("name", "asc")
      .onSnapshot(this.onLocationsUpdate);
    this.userLocationSubscription = this.usersRef
      .doc(this.props.match.params.id)
      .collection("locations")
      .orderBy("Ttotal", "desc")
      .onSnapshot(this.onUsersUpdate);
    this.interval = setInterval(
      () => this.setState({ time: Date.now() }),
      1000
    );

    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  handleUserUpdate = querySnapshot => {
    this.setState({
      user: querySnapshot.data()
    });
  };

  onExchangeUpdate = querySnapshot => {
    const exchangeMap = {};
    querySnapshot.forEach(doc => {
      const { name, price, quantity } = doc.data();
      if (name) {
        exchangeMap[doc.id] = {
          name,
          price,
          quantity
        };
      }
    });
    this.setState({
      exchangeMap
    });
  };

  onUserExchangeUpdate = querySnapshot => {
    const userExchange = [];
    let portfolioTotal = 0;
    let totalShareQuantity = 0;
    querySnapshot.forEach(doc => {
      const { stockId, quantity } = doc.data();
      userExchange.push({
        id: doc.id,
        stockId,
        quantity
      });
      totalShareQuantity = totalShareQuantity + quantity;
    });
    for (let userShare of userExchange) {
      portfolioTotal =
        portfolioTotal + +this.getSharePrice(userShare) * +userShare.quantity;
    }
    this.setState({
      userExchange,
      portfolioTotal,
      totalShareQuantity
    });
  };

  onUsersUpdate = querySnapshot => {
    const userLocations = [];
    querySnapshot.forEach(doc => {
      const { totalTime, dayTime, monthTime, yearTime } = doc.data();
      userLocations.push({
        id: doc.id,
        totalTime,
        dayTime,
        monthTime,
        yearTime
      });
    });
    this.setState({
      userLocations
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  onLocationsUpdate = querySnapshot => {
    const locations = [];
    const locationMap = {};
    querySnapshot.forEach(doc => {
      const { name, day, time, enabled } = doc.data();
      locations.push({
        id: doc.id,
        name,
        day,
        time,
        enabled
      });
      locationMap[doc.id] = { name, day, time, enabled };
      this.setState({
        locations,
        locationMap
      });
    });
  };

  getLocation = location => {
    if (location) {
      return location.name;
    }
    return "";
  };

  getSharePrice = share => {
    if (share && this.state.exchangeMap[share.id]) {
      return this.state.exchangeMap[share.id].price;
    }
    return "";
  };

  getShareName = share => {
    if (share) {
      return this.state.exchangeMap[share.id].name;
    }
    return "";
  };

  getTotal = share => {
    if (share) {
      return +this.getSharePrice(share) * +share.quantity;
    }
    return 0;
  };

  getUserFromAccount = user => {
    if (this.state.userAccountsMap[user.uid]) {
      user.displayName = this.state.userAccountsMap[user.uid].displayName;
      user.photoURL = this.state.userAccountsMap[user.uid].photoURL;
    }
    return user;
  };

  render() {
    const { user } = this.state;

    if (!user) {
      return <LoadingCard />;
    }

    return (
      <>
        <Row className="my-3 user-profile-avatar d-block d-md-none">
          <Col xs={{ span: 6, offset: 3 }}>
            <UserAvatar
              user={user}
              photoURL={this.getUserFromAccount(user).photoURL}
            />
          </Col>
        </Row>
        <h1 className="my-3 display-4 text-center">
          {this.getUserFromAccount(user).displayName}
        </h1>
        <Card className="my-3">
          <Card.Body className="lead">
            Balance
            <span className="float-right">
              <PubCoin amount={user.coin} />
            </span>
          </Card.Body>
        </Card>
        <Card className="my-3">
          <Card.Body className="lead">
            Total shares
            <span className="float-right">{this.state.totalShareQuantity}</span>
          </Card.Body>
        </Card>
        <Card className="my-3">
          <Card.Body className="lead">
            Portfolio value
            <span className="float-right">
              <PubCoinBasic amount={this.state.portfolioTotal} />
            </span>
          </Card.Body>
        </Card>
        <Card className="my-3 shadow-sm">
          <div>
            <Card.Header>Portfolio</Card.Header>
          </div>
          <div>
            <table className="table table-or">
              <tbody>
                <tr>
                  <th>Name</th>
                  <th className="text-right">Price</th>
                  <th className="text-right">Quantity</th>
                  <th className="text-right">Value</th>
                </tr>
                {this.state.userExchange.map((stock, index) => (
                  <tr key={index}>
                    <td>{this.getShareName(stock)}</td>
                    <td className="text-right">
                      <PubCoin amount={this.getSharePrice(stock)} />
                    </td>
                    <td className="text-right">{stock.quantity}</td>
                    <td className="text-right">
                      <PubCoin amount={this.getTotal(stock)} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
        <Card className="my-3 shadow-sm">
          <Card.Header>Pub visits</Card.Header>
          <ListGroup variant="flush">
            {this.state.userLocations.map((userLocation, index) => (
              <ListGroup.Item>
                <Link to={`/location/${userLocation.id}`}>
                  <p className="mb-0">
                    {this.getLocation(this.state.locationMap[userLocation.id])}
                  </p>
                </Link>
                <small>{secondsToString(userLocation.totalTime)}</small>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card>
      </>
    );
  }
}

export default UserProfile;
