import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";

class TourList extends Component {
  constructor(props) {
    super(props);
    this.toursRef = firebase.firestore().collection("tours");
    this.state = {
      tours: []
    };
  }

  componentDidMount() {
    this.unsubscribeTours = this.toursRef.onSnapshot(this.onToursUpdate);
  }

  onToursUpdate = querySnapshot => {
    const tours = [];
    querySnapshot.forEach(doc => {
      const {
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        title,
        status,
        date,
        kml
      } = doc.data();
      tours.push({
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        title,
        date,
        status,
        kml
      });
    });
    this.setState({
      tours
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
    let { title } = this.state;

    if (title) {
      const currentTime = Math.round(new Date().getTime() / 1000);

      this.toursRef.add({}).then(docRef => {
        const newTourRef = this.toursRef.doc(docRef.id);
        newTourRef
          .set({
            id: docRef.id,
            creatorUid: firebase.auth().currentUser.uid,
            creatorName: firebase.auth().currentUser.displayName,
            creatorPhotoURL: firebase.auth().currentUser.photoURL,
            created: currentTime,
            status: 0,
            title: title,
            kml: ""
          })
          .catch(error => {
            console.error("Error creating tour: ", error);
          });
        history.push("/tour/add/" + docRef.id);
      });
    }
  };

  getStatus(status) {
    if (status === "setup") {
      return (
        <span className="status-setup">
          <strong></strong>Setup
        </span>
      );
    } else if (status === "in_progress") {
      return <span className="status-in-progress">In Progress</span>;
    } else {
      return <span className="status-finished">Finished</span>;
    }
  }

  getLink(tour) {
    if (tour.status !== "setup" && firebase.auth().currentUser) {
      return "/tour/view/" + tour.id;
    } else if (firebase.auth().currentUser) {
      return "/tour/add/" + tour.id;
    } else {
      return "/auth";
    }
  }

  render() {
    const { title } = this.state;

    let createButton = "";
    if (firebase.auth().currentUser && this.state.title) {
      createButton = (
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      );
    } else if (firebase.auth().currentUser) {
      createButton = (
        <button type="submit" className="btn btn-primary" disabled>
          Create
        </button>
      );
    } else {
      createButton = (
        <button className="btn btn-primary" disabled>
          Please login
        </button>
      );
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="card box">
            <label for="title">
              <div className="h4">Create tour</div>
            </label>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={this.onChange}
                  placeholder="Tour name"
                />
              </div>
              {createButton}
            </form>
          </div>
          <div className="card box">
            <div className="card-title h4 display-title">Tours</div>
            <div>
              <table className="table table-or">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>&nbsp;</th>
                  </tr>
                  {this.state.tours.map((tour, index) => (
                    <tr key={index}>
                      <td>{tour.date}</td>
                      <td>
                        <img
                          alt={tour.creatorName}
                          className="user-img"
                          src={tour.creatorPhotoURL}
                        />
                        <Link to={`${this.getLink(tour)}`}>{tour.title}</Link>
                      </td>
                      <td>{this.getStatus(tour.status)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TourList;
