import React, { Component } from "react";
import "../../App.css";
import UserAvatar from "./UserAvatar";

class DisplayName extends Component {
  getDisplayNameClass(user) {
    if (user.itemHat || user.isChampion) {
      return "mt-m10";
    } else {
      return "";
    }
  }

  render() {
    const { user } = this.props;

    if (!user.uid && user.id) {
      user.uid = user.id;
    }

    return (
      <span className="d-flex align-items-center">
        <UserAvatar user={user} photoURL={user.photoURL} />{" "}
        <span className="col-auto">{this.props.user.displayName}</span>
      </span>
    );
  }
}

export default DisplayName;
