import { MONTH_NAMES, DAY_NAMES } from "./Constants";
import moment from "moment";

// Used for Tracker

export const secondsToString = seconds => {
  var numYears = Math.floor(seconds / 31536000);
  var numDays = Math.floor((seconds % 31536000) / 86400);
  var numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  var numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  var numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;

  var returnString = "";

  if (numYears && numYears !== 1) {
    returnString = returnString + numYears + " years";
  } else if (numYears) {
    returnString = returnString + numYears + " year";
  }

  if (numDays && numYears) {
    returnString = returnString + ", ";
  } else {
    returnString = returnString + " ";
  }

  if (numDays && numDays !== 1) {
    returnString = returnString + numDays + " days";
  } else if (numDays) {
    returnString = returnString + numDays + " day";
  }

  if (numDays && numHours) {
    returnString = returnString + ", ";
  } else {
    returnString = returnString + " ";
  }

  if (numHours && numHours !== 1) {
    returnString = returnString + numHours + " hours";
  } else if (numHours) {
    returnString = returnString + numHours + " hour";
  }

  if (numMinutes && numHours) {
    returnString = returnString + ", ";
  } else {
    returnString = returnString + " ";
  }

  if (numMinutes && numMinutes !== 1) {
    returnString = returnString + numMinutes + " minutes";
  } else if (numMinutes) {
    returnString = returnString + numMinutes + " minute";
  }

  if (numMinutes && numSeconds) {
    returnString = returnString + ", ";
  } else {
    returnString = returnString + " ";
  }

  if (numSeconds && numSeconds !== 1) {
    returnString = returnString + Math.round(numSeconds) + " seconds";
  } else if (numSeconds) {
    returnString = returnString + Math.round(numSeconds) + " second";
  }

  if (!returnString || returnString.trim() === "") {
    returnString = returnString + "0 seconds";
  }

  return returnString;
};

export const getDateTime = created => {
  if (+created > 0) {
    var date = new Date(0);
    date.setUTCSeconds(+created);
    return parseDateTime(new Date(date));
  }
  return "Unknown";
};

export const getTime = created => {
  if (+created > 0) {
    var date = new Date(0);
    date.setUTCSeconds(+created);
    return parseTime(new Date(date));
  }
  return "Unknown";
};

export const getDate = created => {
  if (+created > 0) {
    var date = new Date(0);
    date.setUTCSeconds(+created);
    return parseDate(new Date(date));
  }
  return "Unknown";
};

export const validCheckIn = (lastCheckInTime, lastCheckOutTime) => {
  const currentDate = new Date();
  const checkInDate = new Date(lastCheckInTime * 1000);
  const currentDateMinus3Hours = new Date(currentDate.getTime() - 7200);
  return (
    checkInDate.getUTCDate() === currentDateMinus3Hours.getUTCDate() &&
    checkInDate.getUTCMonth() === currentDateMinus3Hours.getUTCMonth() &&
    checkInDate.getUTCFullYear() === currentDateMinus3Hours.getUTCFullYear() &&
    (lastCheckInTime > lastCheckOutTime || lastCheckOutTime === 0)
  );
};

export const getDayName = day => {
  return DAY_NAMES[day];
};

export const getLocationInfo = selectedLocation => {
  if (!selectedLocation) {
    return "Please select a location";
  }

  const day = Number(selectedLocation.day);
  const time = Number(selectedLocation.time);

  const checkinFrom = moment()
    .utc()
    .day(day)
    .hour(time)
    .startOf("Hour")
    .local();

  if (time < 0 && day < 0) {
    return `Open every day at any time.`;
  } else if (day < 0) {
    return `Open every day from ${checkinFrom.format("h:mm a")}`;
  } else if (time < 0) {
    return `Open ${checkinFrom.format("dddd")}s at any time`;
  } else {
    return `Open ${checkinFrom.calendar()} (${moment().to(checkinFrom)})`;
  }
};

export const checkLocationEnabled = selectedLocation => {
  const location = selectedLocation;
  if (location) {
    if (!location.enabled) {
      return false;
    }
    const day = +location["day"];
    if (day !== -1 && day !== new Date().getUTCDay()) {
      return false;
    }
    const time = +location["time"];
    if (time !== -1 && time > new Date().getUTCHours()) {
      return false;
    }
  } else {
    return false;
  }
  return true;
};

export const calculateTime = (user, time, display) => {
  const currentDate = new Date();
  const checkInDate = new Date(user.lastCheckInTime * 1000);

  let timeValue = 0;
  if (display === "total") {
    timeValue = user.totalTime;
  } else if (display === "day") {
    timeValue = user.dayTime;
    if (
      currentDate.getUTCDate() !== checkInDate.getUTCDate() ||
      currentDate.getUTCMonth() !== checkInDate.getUTCMonth() ||
      currentDate.getUTCFullYear() !== checkInDate.getUTCFullYear()
    ) {
      timeValue = 0;
    }
  } else if (display === "month") {
    timeValue = user.monthTime;
    if (
      currentDate.getUTCMonth() !== checkInDate.getUTCMonth() ||
      currentDate.getUTCFullYear() !== checkInDate.getUTCFullYear()
    ) {
      timeValue = 0;
    }
  } else if (display === "year") {
    timeValue = user.yearTime;
    if (currentDate.getUTCFullYear() !== checkInDate.getUTCFullYear()) {
      timeValue = 0;
    }
  } else if (display === "thursday") {
    timeValue = user.thursdayTime;
    if (currentDate.getUTCFullYear() !== checkInDate.getUTCFullYear()) {
      timeValue = 0;
    }
  }
  if (validCheckIn(user.lastCheckInTime, user.lastCheckOutTime)) {
    return timeValue + Math.floor(time / 1000 - user.lastCheckInTime);
  } else {
    return timeValue;
  }
};

// Used for COP

export const parseDateTime = stringDate => {
  const date = new Date(Date.parse(stringDate));
  let minutes = date.getMinutes();
  let minutesString = date.getMinutes().toString();
  if (minutes < 10) {
    minutesString = "0" + minutes.toString();
  }
  let hours = date.getHours();
  let hoursString = date.getHours().toString();
  if (hours < 10) {
    hoursString = "0" + hours.toString();
  }
  const datestring =
    date.getDate() +
    " " +
    MONTH_NAMES[date.getMonth()] +
    " " +
    date.getFullYear() +
    " - " +
    hoursString +
    ":" +
    minutesString;
  return datestring;
};

export const parseTime = stringDate => {
  const date = new Date(Date.parse(stringDate));
  let minutes = date.getMinutes();
  let minutesString = date.getMinutes().toString();
  if (minutes < 10) {
    minutesString = "0" + minutes.toString();
  }
  let hours = date.getHours();
  let hoursString = date.getHours().toString();
  if (hours < 10) {
    hoursString = "0" + hours.toString();
  }
  const datestring = hoursString + ":" + minutesString;
  return datestring;
};

export const parseDate = stringDate => {
  const date = new Date(Date.parse(stringDate));
  const datestring =
    date.getDate() +
    " " +
    MONTH_NAMES[date.getMonth()] +
    " " +
    date.getFullYear();
  return datestring;
};

export const getTies = listOfTeams => {
  const roundType = getRoundType(listOfTeams);
  let teamDiff = listOfTeams.length - Math.pow(2, roundType - 1);
  if (teamDiff === 0) {
    teamDiff = listOfTeams.length;
  }
  const listOfTeamsClone = [...listOfTeams];
  const listOfTies = [];
  for (var i = 0; i < teamDiff; i++) {
    let tie = {};
    if (listOfTeamsClone.length > 1) {
      tie["homeTeam"] = getRandomTeam(listOfTeamsClone).id;
      tie["awayTeam"] = getRandomTeam(listOfTeamsClone).id;
    }
    listOfTies.push(tie);
  }
  return listOfTies;
};

export const getRoundType = listOfTeams => {
  const sizeOfList = listOfTeams.length;
  let round = 0;
  while (round < sizeOfList) {
    if (Math.pow(2, round) >= sizeOfList) {
      break;
    } else {
      round++;
    }
  }
  return round;
};

export const getRandomTeam = listOfTeams => {
  const indexToRemove = Math.floor(Math.random() * listOfTeams.length);
  const teamToReturn = listOfTeams[indexToRemove];
  listOfTeams.splice(indexToRemove, 1);
  return teamToReturn;
};

export const addCoinToLedger = (
  ledgerRef,
  type,
  subType,
  name,
  uid,
  initialValue,
  value,
  otherData
) => {
  ledgerRef.add({}).then(docRef => {
    const currentTime = Math.round(new Date().getTime() / 1000);
    const entryRef = ledgerRef.doc(docRef.id);
    entryRef
      .set({
        id: docRef.id,
        type,
        subType,
        name,
        uid,
        initialValue,
        value,
        otherData,
        dateTime: currentTime
      })
      .catch(error => {
        console.error("Error creating ledger entry: ", error);
      });
  });
};
