import { firestore } from "firebase";
import moment, { Moment } from "moment";

/**
 * Typifies a firestore snapshot. This is bad and should be done per type, but a useful workaround to speed dev up.
 *
 * @param snapshot the snapshot to convert
 */
export function convertSnapshotToType<T>(
  snapshot: firestore.DocumentSnapshot
): T {
  const data: unknown = {
    id: snapshot.id,
    ...snapshot.data()
  };

  return data as T;
}

export function convertToDate(timestamp: Date | firestore.Timestamp): Date {
  if (timestamp instanceof Date) {
    return timestamp;
  }

  return timestamp.toDate();
}

export function convertToMoment(timestamp: Date | firestore.Timestamp): Moment {
  return moment(convertToDate(timestamp));
}

export function lowercase(text: string): string {
  return text.replace(/^\w/, c => c.toLowerCase());
}
