import React from "react";
import { firestore, auth } from "firebase";
import ListGroup from "react-bootstrap/ListGroup";
import { Match, Championship } from "./Types";
import { convertSnapshotToType } from "../util/TypeUtils";
import ChampionshipMatch from "./Match";
import EmptyState from "../page/EmptyState";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NextRoundButton from "./ChampionshipNextRound";
import FlipMove from "react-flip-move";

interface Props {
  championship: Championship;
  round: number;
}

interface State {
  loading: boolean;
  matches: ReadonlyArray<Match>;
}

class ChampionshipsRound extends React.PureComponent<Props, State> {
  private championshipRef: firestore.DocumentReference;
  private roundRef: firestore.DocumentReference;
  private matchesRef: firestore.CollectionReference;
  private unsubMatches?: () => void;

  public constructor(props: Props) {
    super(props);

    const { championship } = props;

    this.championshipRef = firestore().doc(`championships/${championship.id}`);
    this.roundRef = this.championshipRef
      .collection(`rounds`)
      .doc(String(championship.round));
    this.matchesRef = this.roundRef.collection("matches");

    this.state = {
      loading: false,
      matches: []
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubMatches = this.matchesRef.onSnapshot(this.handleMatchesUpdate);
  }

  public componentWillUnmount() {
    if (this.unsubMatches) this.unsubMatches();
  }

  public render() {
    const { championship } = this.props;
    const { matches = [], loading = false } = this.state;

    const currentUser = auth().currentUser;

    if (!currentUser) {
      return null;
    }

    const helpText = "Vote for the pubs you'd like to win";

    const allMatchesComplete = this.allMatchesComplete(championship, matches);

    return (
      <>
        <EmptyState content={matches} loading={loading}>
          <ListGroup variant="flush">
            <FlipMove typeName={null}>
              {matches.map(match => (
                <ChampionshipMatch
                  key={match.id}
                  match={match}
                  championship={championship}
                  currentUser={currentUser.uid}
                  handleVote={this.handleVote}
                />
              ))}
            </FlipMove>
          </ListGroup>
        </EmptyState>

        <Card.Footer>
          <Row>
            <Col className="d-flex align-items-center">{helpText}</Col>
            <Col className="col-auto d-flex align-items-center">
              <NextRoundButton
                championship={championship}
                disabled={loading || !allMatchesComplete}
              />
            </Col>
          </Row>
        </Card.Footer>
      </>
    );
  }

  private allMatchesComplete = (
    championship: Championship,
    matches: ReadonlyArray<Match>
  ) => {
    const votesRequired = championship.teamCount
      ? championship.teamCount / 2
      : 1;

    const homeWins = matches
      .filter(m => m.homeVotes.length >= votesRequired)
      .filter(m => m.homeVotes.length > m.awayVotes.length);
    const awayWins = matches
      .filter(m => m.awayVotes.length >= votesRequired)
      .filter(m => m.awayVotes.length > m.homeVotes.length);

    return homeWins.length + awayWins.length === matches.length;
  };

  private handleMatchesUpdate = (snapshot: firestore.QuerySnapshot) => {
    this.setState({
      loading: false,
      matches: snapshot.docs.map(doc => convertSnapshotToType<Match>(doc))
    });
  };

  private handleVote = (matchId: string, team: string) => {
    const currentUser = auth().currentUser;

    if (!currentUser) {
      return Promise.reject();
    }

    return firestore().runTransaction(async transaction => {
      const uid = currentUser.uid;

      const matchRef = this.matchesRef.doc(matchId);
      const matchSnapshot = await transaction.get(this.matchesRef.doc(matchId));

      const match = convertSnapshotToType<Match>(matchSnapshot);

      if (match.winner) return;

      const awayVotes = match.awayVotes.filter((user: string) => user !== uid);
      const homeVotes = match.homeVotes.filter((user: string) => user !== uid);

      if (team === "home") {
        transaction.update(matchRef, {
          homeVotes: [...homeVotes, uid],
          awayVotes
        });
      } else {
        transaction.update(matchRef, {
          awayVotes: [...awayVotes, uid],
          homeVotes
        });
      }
    });
  };
}

export default ChampionshipsRound;
