import React, { Component } from "react";
import "../../App.css";
import "./Championship.css";
import firebase from "../util/Firebase";
import { Link } from "react-router-dom";
import { parseDate } from "../util/Utils";
import history from "../util/History";

class ChampionshipList extends Component {
  constructor(props) {
    super(props);

    this.ref = firebase.firestore().collection("competitions");
    this.usersRef = firebase.firestore().collection("users");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");

    this.competitionsUnsubscribe = null;
    this.unsubscribeUsers = null;
    this.userAccountsSubscription = null;

    this.state = {
      competitions: [],
      title: "",
      userMap: {},
      userAccountsMap: {}
    };
  }

  componentDidMount() {
    this.competitionsUnsubscribe = this.ref
      .orderBy("created", "desc")
      .onSnapshot(this.onCompetitionsUpdate);
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  onCompetitionsUpdate = querySnapshot => {
    let competitions = [];

    querySnapshot.forEach(doc => {
      const {
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        round,
        votesRequired,
        advance
      } = doc.data();
      competitions.push({
        id: doc.id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        title,
        winnerName,
        winnerOwnerName,
        winnerPhotoURL,
        winnerId,
        status,
        round,
        votesRequired,
        advance
      });
    });

    competitions = competitions.filter(c => {
      return (
        c.created > 1565220921 &&
        ((c.status !== 2 && c.created > Date.now() / 1000 - 604800) ||
          c.status === 2)
      );
    });

    this.setState({
      competitions
    });
  };

  onUsersUpdate = querySnapshot => {
    const userMap = {};
    querySnapshot.forEach(doc => {
      const {
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage
      } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage
      };
      userMap[user.id] = user;
    });
    this.setState({
      userMap
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
    let { title } = this.state;

    if (title) {
      const currentTime = Math.round(new Date().getTime() / 1000);

      this.ref.add({}).then(docRef => {
        const newCompetitionRef = this.ref.doc(docRef.id);
        newCompetitionRef
          .set({
            id: docRef.id,
            title,
            creatorUid: firebase.auth().currentUser.uid,
            creatorName: firebase.auth().currentUser.displayName,
            creatorPhotoURL: firebase.auth().currentUser.photoURL,
            created: currentTime,
            status: 0,
            winnerName: "",
            winnerOwnerName: "",
            winnerPhotoURL: "",
            winnerId: "",
            round: -1,
            votesRequired: 0,
            advance: false
          })
          .catch(error => {
            console.error("Error creating competition: ", error);
          });
        const newUsersRef = newCompetitionRef
          .collection("users")
          .doc(firebase.auth().currentUser.uid);
        newUsersRef
          .set({
            teamOwner: true,
            voted: false
          })
          .catch(error => {
            console.error("Error adding competition's user: ", error);
          });
        history.push("/cop/add/" + docRef.id);
      });
    }
  };

  getDate(created) {
    if (+created > 0) {
      var date = new Date(0);
      date.setUTCSeconds(+created);
      return parseDate(new Date(date));
    }
    return "Unknown";
  }

  getStatus(status, competition) {
    if (status === 0) {
      return (
        <span className="status-setup">
          <strong></strong>Setup
        </span>
      );
    } else if (status === 1) {
      return <span className="status-voting">Voting</span>;
    } else {
      return (
        <span className="status-winner">
          <span role="img" aria-label="cup">
            🏆
          </span>{" "}
          <img
            className="winner-img user-img"
            src={competition.winnerPhotoURL}
            alt="Winner avatar"
          />{" "}
          {competition.winnerName}
        </span>
      );
    }
  }

  getLink(status, id) {
    if (status > 0 && firebase.auth().currentUser) {
      return "/cop/view/" + id + "/" + firebase.auth().currentUser.uid;
    } else if (firebase.auth().currentUser) {
      return "/cop/add/" + id;
    } else {
      return "/auth";
    }
  }

  getUser = userId => {
    if (this.state.userMap[userId]) {
      if (this.state.userAccountsMap[userId]) {
        return {
          ...this.state.userMap[userId],
          displayName: this.state.userAccountsMap[userId].displayName,
          photoURL: this.state.userAccountsMap[userId].photoURL
        };
      }
      return this.state.userMap[userId];
    }
    return "";
  };

  render() {
    const { title } = this.state;

    let createButton = "";
    if (firebase.auth().currentUser && this.state.title) {
      createButton = (
        <button type="submit" className="btn btn-primary">
          Create
        </button>
      );
    } else if (firebase.auth().currentUser) {
      createButton = (
        <button type="submit" className="btn btn-primary" disabled>
          Create
        </button>
      );
    } else {
      createButton = (
        <button className="btn btn-primary" disabled>
          Please login
        </button>
      );
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="card box">
            <label for="title">
              <div className="h4">Create competition</div>
            </label>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={this.onChange}
                  placeholder="Competition title"
                />
              </div>
              {createButton}
            </form>
          </div>
          <div className="card box">
            <div className="card-title h4 display-title">Competitions</div>
            <div>
              <table className="table table-or">
                <tbody>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Status</th>
                  </tr>
                  {this.state.competitions.map((competition, index) => (
                    <tr key={index}>
                      <td>{this.getDate(competition.created)}</td>
                      <td>
                        <img
                          className="user-img"
                          src={competition.creatorPhotoURL}
                          alt="User avatar"
                        />
                        <Link
                          to={`${this.getLink(
                            competition.status,
                            competition.id
                          )}`}
                        >
                          {competition.title}
                        </Link>
                      </td>
                      <td>{this.getStatus(competition.status, competition)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChampionshipList;
