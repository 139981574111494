import { RouteComponentProps, Switch, Route } from "react-router";
import React from "react";
import ChampionshipsCreate from "./ChampionshipsCreate";
import ChampionshipsView from "./ChampionshipsView";
import ChampionshipsList from "./ChampionshipsList";

type Props = RouteComponentProps;

class ChampionshipsPage extends React.PureComponent<Props> {
  public render() {
    const { match } = this.props;

    return (
      <>
        <Switch>
          <Route
            path={`${match.path}/create`}
            component={ChampionshipsCreate}
          />
          <Route
            path={`${match.path}/:championshipId`}
            component={ChampionshipsView}
          />
          <Route component={ChampionshipsList} />
        </Switch>
      </>
    );
  }
}

export default ChampionshipsPage;
