import { firestore } from "firebase";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { User, UserAccount } from "../../Types";
import { Session } from "../sessions/Types";
import { convertSnapshotToType } from "../util/TypeUtils";
import Button from "react-bootstrap/Button";
import VisitsRow from "./VisitsRow";

export enum DisplayOptions {
  Total = "total",
  Week = "week",
  Month = "month",
  Year = "year"
}

interface Props {
  toggleDisplay: any;
}

interface State {
  users: ReadonlyArray<User>;
  sessions: ReadonlyArray<Session>;
  userAccounts: { [id: string]: UserAccount };
  userVisits: { [id: string]: {} };
  display: DisplayOptions;
  loading: boolean;
}

class Visits extends React.PureComponent<Props, State> {
  private usersRef: firestore.CollectionReference = firestore().collection(
    "users"
  );
  private userAccountsRef: firestore.CollectionReference = firestore().collection(
    "userAccounts"
  );
  private sessionsRef: firestore.CollectionReference = firestore().collection(
    "sessions"
  );

  private unsubUsers?: () => void;
  private unsubUserAccounts?: () => void;
  private unsubSessions?: () => void;

  private interval?: number;

  public constructor(props: Props) {
    super(props);

    this.state = {
      users: [],
      sessions: [],
      userAccounts: {},
      userVisits: {},
      display: DisplayOptions.Year,
      loading: false
    };
  }

  getUserFromAccount = (user: User) => {
    if (this.state.userAccounts[user.uid]) {
      user.displayName = this.state.userAccounts[user.uid].displayName;
      user.photoURL = this.state.userAccounts[user.uid].photoURL;
    }
    return user;
  };

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubSessions = this.sessionsRef.onSnapshot(this.updateSessions);
    this.unsubUsers = this.usersRef.onSnapshot(this.updateUsers);
    this.unsubUserAccounts = this.userAccountsRef.onSnapshot(this.updateUserAccounts);
  }

  public componentWillUnmount() {
    this.unsubUsers && this.unsubUsers();
    this.unsubUserAccounts && this.unsubUserAccounts();
    this.unsubSessions && this.unsubSessions();
    window.clearInterval(this.interval);
  }

  public render() {
    const { users, display, loading = true, sessions } = this.state;

    return (
      <Card className="leaderboard my-3">
        <Card.Header>
          <Row>
            <Col>
              <h5 className="mb-0">Leaderboard - Visits</h5>
            </Col>
            <Col className="col-auto">
              <Button
                  onClick={this.props.toggleDisplay.bind(this, "time")}
                  size="sm"
              >
                Time
              </Button>
            </Col>
          </Row>
        </Card.Header>
        <ListGroup>
            {users.map(this.getUser).map((user, index) => (
              <VisitsRow
                session={sessions.find(
                  s => s.userId === user.id || s.userId === user.uid
                )}
                position={index + 1}
                user={this.getUserFromAccount(user)}
                visits={user.visits}
                display={display}
              />
            ))}
          </ListGroup>
      </Card>
    );
  }

  private handleDisplayChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({
      display: e.target.value as DisplayOptions
    });

  private getUser = (user: User) => ({
    ...user,
    ...(this.state.userAccounts[user.id]
      ? this.state.userAccounts[user.id]
      : {})
  });

  private updateUsers = (snapshot: firestore.QuerySnapshot) => {
    const users = snapshot.docs.map(doc => convertSnapshotToType<User>(doc));
    this.setState({ loading: false });
    this.sortAndSetUsers(users);
  };

  private updateSessions = (snapshot: firestore.QuerySnapshot) => {

    const userVisits = {} as { [any: string]: { [any: string]: {} } };

    snapshot.docs.map(doc => {

      const session = doc.data() as Session;

      if(!userVisits[session.userId]) {
        // @ts-ignore
        userVisits[session.userId] = [];
      }
      if(userVisits[session.userId] && doc.data().locationRef
          && !userVisits[session.userId][doc.data().locationRef.id]) {
        userVisits[session.userId][doc.data().locationRef.id] = true;
      }
    });

    this.setState({
      sessions: snapshot.docs.map(doc => convertSnapshotToType<Session>(doc)),
      userVisits
    });
  }

  private updateUserAccounts = (snapshot: firestore.QuerySnapshot) => {
    const userAccounts: { [id: string]: UserAccount } = snapshot.docs.reduce(
      (
        map: { [id: string]: UserAccount },
        doc: firestore.QueryDocumentSnapshot
      ) => ({ ...map, [doc.id]: doc.data() as UserAccount }),
      {}
    );

    this.setState({ loading: false, userAccounts });
  };

  private sortAndSetUsers = (updatedUserList?: ReadonlyArray<User>) => {
    const users = updatedUserList ? updatedUserList : this.state.users;

    users.forEach( user => {
      if(this.state.userVisits[user.uid]) {
        user.visits = Object.keys(this.state.userVisits[user.uid]).length;
      } else {
        user.visits = 0;
      }
    });

    this.setState({
      users: users
        .slice().filter(u => {
          return u.visits > 0
        }).sort(
          (a: User, b: User) =>
            b.visits - a.visits
        )
    });

    if(this.state.users.length < 2) {
      this.setState({ loading: true });
      this.unsubSessions = this.sessionsRef.onSnapshot(this.updateSessions);
      this.unsubUsers = this.usersRef.onSnapshot(this.updateUsers);
      this.unsubUserAccounts = this.userAccountsRef.onSnapshot(this.updateUserAccounts);
    }
  };
}

export default Visits;
