import moment from "moment";
import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import "../../App.css";
import EmptyState from "../page/EmptyState";
import firebase from "../util/Firebase";
import history from "../util/History";
import { addCoinToLedger } from "../util/Utils";
import "./Coin.css";
import CoinNav from "./CoinNav";
import PubCoin from "./PubCoin";
import Scratchcard from "./scratchcard/Scratchcard";
import PubCoinBasic from "./PubCoinBasic";

class CoinItems extends Component {
  constructor(props) {
    super(props);

    this.globalRef = firebase.firestore().collection("global");
    this.itemsRef = firebase.firestore().collection("items");
    this.usersRef = firebase.firestore().collection("users");
    this.ledgerRef = firebase.firestore().collection("ledger");
    this.userItemsRef = firebase
      .firestore()
      .collection("users")
      .doc(this.props.match.params.uid)
      .collection("items");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");
    this.sessionsRef = firebase.firestore().collection("sessionsRef");

    this.unsubscribeUsers = null;
    this.unsubscribeUserItems = null;
    this.unsubscribeItems = null;
    this.unsubscribeGlobal = null;
    this.userAccountsSubscription = null;

    this.state = {
      users: [],
      userMap: {},
      userAccountsMap: {},
      items: [],
      userItems: {},
      playingScratchcard: false,
      playingTombola: false,
      playingTortuga: false,
      playingSuperTortuga: false,
      playingLotto: false,
      coinAverage: 0,
      userCount: 0,
      sPayIn: 0,
      sPayOut: 0,
      lottoJackpot: 150,
      scratchcardActive: false,
      shareTaxBand1: 52,
      shareTaxBand2: 103,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribeItems = this.itemsRef
      .orderBy("order", "asc")
      .onSnapshot(this.onItemsUpdate);
    this.unsubscribeUsers = this.usersRef
      .where(
        "updated",
        ">",
        moment()
          .utc()
          .subtract(1, "month")
          .toDate()
      )
      .onSnapshot(this.onUsersUpdate);
    this.unsubscribeUserItems = this.userItemsRef.onSnapshot(
      this.onUserItemsUpdate
    );
    this.unsubscribeGlobal = this.globalRef.onSnapshot(this.onGlobalUpdate);
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  componentWillUnmount() {
    this.unsubscribeUserItems && this.unsubscribeUserItems();
    this.unsubscribeItems && this.unsubscribeItems();
    this.unsubscribeUsers && this.unsubscribeUsers();
    this.unsubscribeGlobal && this.unsubscribeGlobal();
    this.userAccountsSubscription && this.userAccountsSubscription();
  }

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  median = values => {
    if (values.length === 0) return 0;

    values.sort(function(a, b) {
      return a - b;
    });

    var half = Math.floor(values.length / 2);

    if (values.length % 2) return values[half];

    return (values[half - 1] + values[half]) / 2.0;
  };

  onUsersUpdate = querySnapshot => {
    if (this.props.match.params.uid === "undefined") {
      history.push("/auth");
    }

    const users = [];
    const userMap = {};
    let coinArray = [];
    let userCount = 0;

    querySnapshot.forEach(doc => {
      const {
        coin,
        displayName,
        photoURL,
        lastCheckInTime,
        lastCheckOutTime,
        session,
        portfolioQuantity
      } = doc.data();

      if (coin && typeof coin === "number") {
        coinArray.push(coin);
        userCount = userCount + 1;
      }

      const user = {
        id: doc.id,
        coin,
        displayName,
        photoURL,
        lastCheckInTime,
        lastCheckOutTime,
        session,
        portfolioQuantity
      };
      if (
        coin &&
        firebase.auth().currentUser &&
        firebase.auth().currentUser.uid !== doc.id
      ) {
        users.push(user);
      }
      userMap[user.id] = user;
    });

    this.setState({
      users,
      userMap,
      userCount,
      coinAverage: Math.ceil(this.median(coinArray))
    });
  };

  onItemsUpdate = querySnapshot => {
    let items = [];
    querySnapshot.forEach(doc => {
      const {
        name,
        description,
        price,
        purchase,
        active,
        type,
        limit,
        value,
        expires
      } = doc.data();
      if (name) {
        items.push({
          id: doc.id,
          name,
          description,
          price,
          purchase,
          active,
          type,
          limit,
          value,
          expires
        });
      }
    });
    this.setState({
      items,
      loading: false
    });
  };

  onUserItemsUpdate = querySnapshot => {
    const userItems = {};
    querySnapshot.forEach(doc => {
      const { purchased, purchaseTime, enabled } = doc.data();
      userItems[doc.id] = {
        id: doc.id,
        purchased,
        purchaseTime,
        enabled
      };
    });
    this.setState({
      userItems
    });
    let updatedItems = this.state.items.filter(i => {
      return (
        (this.state.userItems["halloween"] &&
          this.state.userItems["halloween"].purchased) ||
        !i.expires ||
        i.expires < Date.now - 1000
      );
    });
    this.setState({
      items: updatedItems
    });
  };

  onGlobalUpdate = querySnapshot => {
    let sPayIn = 0;
    let sPayOut = 0;
    let newLottoJackpot = 150;
    let newShareTaxBand1 = 52;
    let newShareTaxBand2 = 103;

    querySnapshot.forEach(doc => {
      if (doc.id === "scratchcard") {
        const { payIn, payOut } = doc.data();
        sPayIn = payIn;
        sPayOut = payOut;
      } else if (doc.id === "lotto") {
        const { lottoJackpot } = doc.data();
        newLottoJackpot = lottoJackpot;
      } else if (doc.id === "tax") {
        const { shareTaxBand1, shareTaxBand2 } = doc.data();
        newShareTaxBand1 = shareTaxBand1;
        newShareTaxBand2 = shareTaxBand2;
      }
    });

    this.setState({
      sPayIn,
      sPayOut,
      lottoJackpot: newLottoJackpot,
      shareTaxBand1: newShareTaxBand1,
      shareTaxBand2: newShareTaxBand2
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  getActionType = item => {
    if (!firebase.auth().currentUser) {
      return "";
    }

    const user = this.state.userMap[this.props.match.params.uid];
    const isCheckedIn = Boolean(user && user.session);

    if (item && user) {
      if (!this.isPurchased(item) && (!item.active || isCheckedIn)) {
        return (
          <button className="btn btn-secondary btn-sm w-100" disabled>
            {this.getPrice(item)}
          </button>
        );
      } else if (item.purchase === "constant") {
        if (this.isPurchased(item) && !this.isEnabled(item) && isCheckedIn) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Enable
            </button>
          );
        } else if (
          this.isPurchased(item) &&
          this.isEnabled(item) &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-danger btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Disable
            </button>
          );
        } else if (!this.isEnabled(item) && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Enable
            </button>
          );
        } else if (!isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Disable
            </button>
          );
        }
      } else if (item.type === "gambling") {
        if (
          item.id === "scratchcard" &&
          this.isPurchased(item) &&
          !this.state.playingScratchcard &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Play
            </button>
          );
        } else if (item.id === "scratchcard" && this.state.playingScratchcard) {
          return (
            <button className="btn btn-success playing btn-sm w-100" disabled>
              <span role="img" aria-label="hourglass">
                {" "}
                ⌛
              </span>
            </button>
          );
        } else if (item.id === "scratchcard" && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Play
            </button>
          );
        } else if (
          item.id === "tombola" &&
          this.isPurchased(item) &&
          !this.state.playingTombola &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Play
            </button>
          );
        } else if (item.id === "tombola" && this.state.playingTombola) {
          return (
            <button className="btn btn-success playing btn-sm w-100" disabled>
              <span role="img" aria-label="hourglass">
                {" "}
                ⌛
              </span>
            </button>
          );
        } else if (item.id === "tombola" && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Play
            </button>
          );
        } else if (
          item.id === "tortuga" &&
          this.isPurchased(item) &&
          !this.state.playingTortuga &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Play
            </button>
          );
        } else if (item.id === "tortuga" && this.state.playingTortuga) {
          return (
            <button className="btn btn-success playing btn-sm w-100" disabled>
              <span role="img" aria-label="hourglass">
                {" "}
                ⌛
              </span>
            </button>
          );
        } else if (item.id === "tortuga" && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Play
            </button>
          );
        } else if (
          item.id === "supertortuga" &&
          this.isPurchased(item) &&
          !this.state.playingSuperTortuga &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Play
            </button>
          );
        } else if (
          item.id === "supertortuga" &&
          this.state.playingSuperTortuga
        ) {
          return (
            <button className="btn btn-success playing btn-sm w-100" disabled>
              <span role="img" aria-label="hourglass">
                {" "}
                ⌛
              </span>
            </button>
          );
        } else if (item.id === "supertortuga" && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Play
            </button>
          );
        } else if (
          item.id === "lotto" &&
          this.isPurchased(item) &&
          !this.state.playingLotto &&
          isCheckedIn
        ) {
          return (
            <button
              className="btn btn-success btn-sm w-100"
              onClick={this.use.bind(this, item)}
            >
              Play
            </button>
          );
        } else if (item.id === "lotto" && this.state.playingLotto) {
          return (
            <button className="btn btn-success playing btn-sm w-100" disabled>
              <span role="img" aria-label="hourglass">
                {" "}
                ⌛
              </span>
            </button>
          );
        } else if (item.id === "lotto" && !isCheckedIn) {
          return (
            <button className="btn btn-secondary btn-sm w-100" disabled>
              Play
            </button>
          );
        }
      } else if (
        this.isPurchased(item) &&
        !this.isEnabled(item) &&
        isCheckedIn
      ) {
        return (
          <button
            className="btn btn-success btn-sm w-100"
            onClick={this.use.bind(this, item)}
          >
            Use
          </button>
        );
      } else if (
        this.isPurchased(item) &&
        this.isEnabled(item) &&
        isCheckedIn
      ) {
        return (
          <button className="btn btn-success btn-sm w-100" disabled>
            Active
          </button>
        );
      } else if (!isCheckedIn) {
        return (
          <button className="btn btn-secondary btn-sm w-100" disabled>
            Use
          </button>
        );
      }
    }

    return "";
  };

  getPurchaseButton = item => {
    if (!firebase.auth().currentUser) {
      return "";
    }

    const user = this.state.userMap[this.props.match.params.uid];
    const isCheckedIn = Boolean(user && user.session);

    if (item && user) {
      if (!item.active || !isCheckedIn) {
        return (
          <button className="btn btn-secondary btn-sm w-100" disabled>
            {this.getPrice(item)}
          </button>
        );
      } else if (this.isPurchased(item) || this.isLimited(item)) {
        return (
          <button className="btn btn-primary btn-sm w-100" disabled>
            {this.getPrice(item)}
          </button>
        );
      } else if (!this.isPurchased(item)) {
        return (
          <button
            className="btn btn-primary btn-sm w-100"
            onClick={this.purchase.bind(this, item)}
          >
            {this.getPrice(item)}
          </button>
        );
      }
    }

    return "";
  };

  isLimited = item => {
    if (!this.state.userItems[item.id]) {
      return false;
    }
    if (
      item.limit &&
      this.state.userItems[item.id].purchaseTime + 86400 >
        Math.round(new Date().getTime() / 1000)
    ) {
      return true;
    } else {
      return false;
    }
  };

  isPurchased = item => {
    if (this.state.userItems.length === 0) {
      return false;
    }
    if (this.state.userItems[item.id]) {
      return this.state.userItems[item.id].purchased;
    } else {
      return false;
    }
  };

  purchase = item => {
    const user = this.state.userMap[firebase.auth().currentUser.uid];

    let price = +item.price;

    if (
      item.id === "scratchcard" &&
      user.portfolioQuantity >= this.state.shareTaxBand2
    ) {
      price = price + 2;
    } else if (
      item.id === "scratchcard" &&
      user.portfolioQuantity >= this.state.shareTaxBand1
    ) {
      price = price + 1;
    } else if (price === -1) {
      price = Math.ceil(this.state.coinAverage / 10);
    } else if (price === -2) {
      price = 1 + Math.floor(user.coin * 0.02);
    } else if (price === -11) {
      price = Math.ceil(this.state.coinAverage / 5);
    }

    if (+user.coin - price >= 0) {
      this.usersRef.doc(user.id).update({
        coin: firebase.firestore.FieldValue.increment(-price)
      });

      if (item.id === "lotto") {
        this.globalLotto(price);
      }

      const currentTime = Math.round(new Date().getTime() / 1000);

      this.userItemsRef
        .doc(item.id)
        .get()
        .then(docSnapshot => {
          if (docSnapshot.exists) {
            this.userItemsRef.doc(item.id).update({
              purchased: true,
              purchaseTime: currentTime
            });
          } else {
            this.userItemsRef.doc(item.id).set({
              purchased: true,
              purchaseTime: currentTime
            });
          }
        });

      addCoinToLedger(
        this.ledgerRef,
        "Coin",
        "Purchase",
        item.name,
        user.id,
        +user.coin,
        price,
        0
      );
    }
  };

  isEnabled = item => {
    if (this.state.userItems[item.id]) {
      return this.state.userItems[item.id].enabled;
    }
    return false;
  };

  use = async item => {
    const user = this.state.userMap[firebase.auth().currentUser.uid];

    if (this.isPurchased(item)) {
      if (item.id === "checkout") {
        if (!this.isEnabled(item)) {
          this.userItemsRef.doc(item.id).update({
            enabled: true
          });
          this.usersRef.doc(user.id).update({
            itemCheckout: true
          });
        }
      } else if (item.id === "doublecoins") {
        if (!this.isEnabled(item)) {
          this.userItemsRef.doc(item.id).update({
            enabled: true
          });
          this.usersRef.doc(user.id).update({
            itemDoubleCoins: true
          });
          await user.session.update({ doubleCoin: true });
        }
      } else if (item.type === "hat") {
        if (this.isEnabled(item)) {
          this.usersRef.doc(user.id).update({
            itemHat: ""
          });
          this.userItemsRef.doc(item.id).update({
            enabled: false
          });
        } else {
          this.usersRef.doc(user.id).update({
            itemHat: item.value
          });
          this.userItemsRef.doc("tophat").update({
            enabled: false
          });
          this.userItemsRef.doc("strawhat").update({
            enabled: false
          });
          this.userItemsRef.doc("baseballcap").update({
            enabled: false
          });
          this.userItemsRef.doc(item.id).update({
            enabled: true
          });
        }
      } else if (item.type === "image") {
        if (this.isEnabled(item)) {
          this.usersRef.doc(user.id).update({
            itemImage: ""
          });
          this.userItemsRef.doc(item.id).update({
            enabled: false
          });
        } else {
          this.usersRef.doc(user.id).update({
            itemImage: item.value
          });
          this.userItemsRef.doc(item.id).update({
            enabled: true
          });
        }
      } else if (item.id === "scratchcard") {
        this.setState({
          playingScratchcard: true
        });
        this.playScratchcard(user, item);
      } else if (item.id === "tombola") {
        this.setState({
          playingTombola: true
        });
        setTimeout(() => {
          this.playTombola(user, item);
        }, 1800);
      } else if (item.id === "tortuga") {
        this.setState({
          playingTortuga: true
        });
        setTimeout(() => {
          this.playTortuga(user, item);
        }, 1800);
      } else if (item.id === "supertortuga") {
        this.setState({
          playingSuperTortuga: true
        });
        setTimeout(() => {
          this.playSuperTortuga(user, item);
        }, 1800);
      } else if (item.id === "lotto") {
        this.setState({
          playingLotto: true
        });
        setTimeout(() => {
          this.playLotto(user, item);
        }, 1800);
      }
    }
  };

  playLotto = (user, item) => {
    const random = Math.round(Math.random() * 150);
    if (random > 149) {
      this.addPrizeWinnings(
        "Jackpot Lotto",
        user,
        this.state.lottoJackpot,
        true
      );
      this.globalLottoReset();
    } else {
      alert("You win some you lose some...");
    }
    this.setPurchaseFalse(item);
    setTimeout(() => {
      this.setState({
        playingLotto: false
      });
    }, 50);
  };

  playScratchcard = (user, item) => {
    this.setPurchaseFalse(item);

    const random = Math.round(Math.random() * 100);

    let winnings = 0;

    if (random > 99) {
      winnings = 100;
    } else if (random > 97) {
      winnings = 30;
    } else if (random > 94) {
      winnings = 20;
    } else if (random > 89) {
      winnings = 10;
    } else if (random > 79) {
      winnings = 3;
    }

    this.setState({
      user: user,
      item: item,
      winnings: winnings,
      elements: this.getElementsForScratchcard(winnings),
      scratchcardActive: true
    });
  };

  getElementsForScratchcard = winnings => {
    const elements = [];
    const randomElementList = [3, 10, 20, 30, 100, 3, 10, 20, 30, 100];
    const randomElementListProcessed = [];
    for (let i = 0; i < randomElementList.length; i++) {
      if (randomElementList[i] !== winnings) {
        randomElementListProcessed.push(randomElementList[i]);
      }
    }
    if (winnings > 0) {
      elements.push(winnings);
      elements.push(winnings);
      elements.push(winnings);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
    } else {
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
      this.addRandomElement(elements, randomElementListProcessed);
    }
    return this.shuffle(elements);
  };

  addRandomElement = (elements, randomElementListProcessed) => {
    let length = randomElementListProcessed.length;
    let randomNumber = Math.round(Math.random() * (length - 1));
    elements.push(randomElementListProcessed.splice(randomNumber, 1)[0]);
  };

  shuffle = array => {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  setScratchcardInactive = () => {
    this.setState({
      scratchcardActive: false
    });
  };

  globalScratchcard(payIn, payOut) {
    this.globalRef.doc("scratchcard").update({
      payIn: this.state.sPayIn + payIn,
      payOut: this.state.sPayOut + payOut
    });
  }

  globalLotto(coin) {
    this.globalRef.doc("lotto").update({
      lottoJackpot: this.state.lottoJackpot + coin
    });
  }

  globalLottoReset() {
    this.globalRef.doc("lotto").update({
      lottoJackpot: 150
    });
  }

  playTombola = (user, item) => {
    const random = Math.round(Math.random() * 100);
    if (random > 95) {
      this.addPrizeWinnings("Tombola", user, 25, true);
    } else if (random > 90) {
      this.addPrizeWinnings("Tombola", user, 15, true);
    } else if (random > 80) {
      this.addPrizeWinnings("Tombola", user, 10, true);
    } else if (random > 70) {
      this.addPrizeWinnings("Tombola", user, 8, true);
    } else if (random > 50) {
      this.addPrizeWinnings("Tombola", user, 5, true);
    } else if (random > 30) {
      this.addPrizeWinnings("Tombola", user, 3, true);
    } else {
      this.addPrizeWinnings("Tombola", user, 1, true);
    }
    this.setPurchaseFalse(item);
    setTimeout(() => {
      this.setState({
        playingTombola: false
      });
    }, 50);
  };

  addPrizeWinnings = (type, user, coin, alertUser) => {
    this.usersRef.doc(user.id).update({
      coin: firebase.firestore.FieldValue.increment(coin)
    });
    addCoinToLedger(
      this.ledgerRef,
      "Coin",
      "Gambling",
      type,
      user.id,
      +user.coin,
      coin,
      0
    );
    if (alertUser) {
      if (coin === 1) {
        alert("Congratulations, you've won " + coin + " coin!");
      } else {
        alert("Congratulations, you've won " + coin + " coins!");
      }
    }
  };

  playTortuga = (user, item) => {
    const usersToSelect = this.state.users.filter(
      u => u.coin && u.id !== user.id
    );
    const userSelected =
      usersToSelect[Math.floor(Math.random() * usersToSelect.length)];

    const coinsStolen = Math.floor(userSelected.coin / 10);

    alert(
      "You have stolen " +
        coinsStolen +
        " coins from " +
        userSelected.displayName +
        "!"
    );
    this.usersRef.doc(user.id).update({
      coin: firebase.firestore.FieldValue.increment(coinsStolen)
    });
    this.usersRef.doc(userSelected.id).update({
      coin: firebase.firestore.FieldValue.increment(-coinsStolen)
    });
    addCoinToLedger(
      this.ledgerRef,
      "Coin",
      "Gambling",
      "Tortuga Roulette",
      user.id,
      +user.coin,
      coinsStolen,
      userSelected.id
    );
    this.setPurchaseFalse(item);
    setTimeout(() => {
      this.setState({
        playingTortuga: false
      });
    }, 50);
  };

  playSuperTortuga = (user, item) => {
    const usersToSelect = this.state.users.filter(
      u => u.coin && u.id !== user.id && Boolean(u.session)
    );

    if (usersToSelect.length > 0) {
      const userSelected =
        usersToSelect[Math.floor(Math.random() * usersToSelect.length)];

      const coinsStolen = Math.floor(userSelected.coin / 5);

      alert(
        "You have stolen " +
          coinsStolen +
          " coins from " +
          this.getUser(userSelected).displayName +
          "!"
      );
      this.usersRef.doc(user.id).update({
        coin: +user.coin + coinsStolen
      });
      this.usersRef.doc(userSelected.id).update({
        coin: +userSelected.coin - coinsStolen
      });
      addCoinToLedger(
        this.ledgerRef,
        "Coin",
        "Gambling",
        item.name,
        user.id,
        +user.coin,
        coinsStolen,
        userSelected.id
      );
      this.setPurchaseFalse(item);
    }
    setTimeout(() => {
      this.setState({
        playingSuperTortuga: false
      });
    }, 50);
  };

  setPurchaseFalse = item => {
    this.userItemsRef.doc(item.id).update({
      purchased: false
    });
  };

  getPrice = item => {
    const user = this.state.userMap[firebase.auth().currentUser.uid];

    if (
      item.id === "scratchcard" &&
      user.portfolioQuantity >= this.state.shareTaxBand2
    ) {
      return (
        <span>
          <PubCoin amount={item.price} />{" "}
          <div className={"share-tax-display-coin"}>+ 2</div>
        </span>
      );
    } else if (
      item.id === "scratchcard" &&
      user.portfolioQuantity >= this.state.shareTaxBand1
    ) {
      return (
        <span>
          <PubCoin amount={item.price} />{" "}
          <div className={"share-tax-display-coin"}>+ 1</div>
        </span>
      );
    } else if (user && item.price === -2) {
      return <PubCoin amount={1 + Math.floor(user.coin * 0.02)} />;
    } else if (item.price === -2) {
      return <PubCoin amount={1} />;
    } else if (item.price === -1) {
      return <PubCoin amount={Math.ceil(this.state.coinAverage / 10)} />;
    } else if (item.price === -11) {
      return <PubCoin amount={Math.ceil(this.state.coinAverage / 5)} />;
    } else if (item.price === 0) {
      return <span>Free</span>;
    } else {
      return <PubCoin amount={item.price} />;
    }
  };

  getUser = user => {
    if (this.state.userAccountsMap[user.id]) {
      user.displayName = this.state.userAccountsMap[user.id].displayName;
      user.photoURL = this.state.userAccountsMap[user.id].photoURL;
    }
    return user;
  };

  getButton = item => {
    if (this.isPurchased(item)) {
      return this.getActionType(item);
    } else {
      return this.getPurchaseButton(item);
    }
  };

  getDescription = item => {
    if (item.id === "scratchcard") {
      return (
        item.description +
        " (" +
        Math.floor((this.state.sPayOut / this.state.sPayIn) * 100) +
        "% payout)"
      );
    } else if (item.id === "lotto") {
      return (
        <span>
          <div className="pub-coin-desc">{item.description} of </div>
          <PubCoinBasic amount={this.state.lottoJackpot} />
        </span>
      );
    }
    return item.description;
  };

  getScratchCard = () => {
    if (this.state.scratchcardActive) {
      return (
        <Scratchcard
          closeScratchcard={this.closeScratchcard}
          user={this.state.user}
          item={this.state.item}
          winnings={this.state.winnings}
          elements={this.state.elements}
        />
      );
    }
    return "";
  };

  closeScratchcard = (user, item, winnings, alertUser) => {
    this.globalScratchcard(item.price, winnings);
    if (winnings > 0) {
      this.addPrizeWinnings("Scratchcard", user, winnings, alertUser);
    } else if (alertUser) {
      alert("You win some you lose some...");
    }
    this.setState({
      playingScratchcard: false
    });
    this.setScratchcardInactive();
  };

  render() {
    const { loading } = this.state;

    return (
      <div>
        {this.getScratchCard()}
        <div className="panel panel-default">
          <CoinNav
            location={this.props.location.pathname}
            uid={this.props.match.params.uid}
            user={this.state.userMap[this.props.match.params.uid]}
          />
          <Card className="my-3">
            <Card.Header as="h5">Items</Card.Header>
            <ListGroup variant="flush">
              <EmptyState
                content={this.state.items}
                loading={loading}
                message="There are no items for sale. Check back later."
              >
                {this.state.items.map(item => (
                  <ListGroup.Item key={item.name}>
                    <Row>
                      <Col>
                        <div>{item.name}</div>
                        <small>{this.getDescription(item)}</small>
                      </Col>
                      <Col
                        xs={3}
                        className="d-flex justify-content-stretch align-items-center"
                      >
                        {this.getButton(item)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </EmptyState>
            </ListGroup>
          </Card>
        </div>
      </div>
    );
  }
}

export default CoinItems;
