import ListGroup from "react-bootstrap/ListGroup";
import React from "react";
import { Match, Championship } from "./Types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import "./ChampionshipsMatch.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faSquare,
  faMedal
} from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import "../../animate.css";

interface Props {
  match: Match;
  championship: Championship;
  currentUser: string;
  handleVote(matchId: string, team: string): Promise<void>;
}

interface State {
  userVoting: boolean;
  userVotingHome?: boolean;
  userVotingAway?: boolean;
  homeVotes: number;
  awayVotes: number;
}

class ChampionshipMatch extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      userVoting: false,
      homeVotes: props.match.homeVotes.length,
      awayVotes: props.match.awayVotes.length
    };
  }

  public componentDidUpdate(prevProps: Props) {
    const { userVoting } = this.state;

    if (!userVoting && this.props.match !== prevProps.match) {
      this.setState({
        homeVotes: this.props.match.homeVotes.length,
        awayVotes: this.props.match.awayVotes.length
      });
    }
  }

  public render() {
    const { match, championship, currentUser } = this.props;
    const {
      userVoting,
      userVotingHome,
      userVotingAway,
      homeVotes,
      awayVotes
    } = this.state;

    const totalVotes = homeVotes + awayVotes;
    const votesRequired = championship.teamCount
      ? championship.teamCount / 2
      : 1;
    const ratio = totalVotes ? (homeVotes / totalVotes) * 100 : 50;

    const homeWinning = homeVotes >= votesRequired && homeVotes > awayVotes;
    const awayWinning = awayVotes >= votesRequired && awayVotes > homeVotes;

    const userVotedHome = match.homeVotes.includes(currentUser);
    const userVotedAway = match.awayVotes.includes(currentUser);

    const homeWinConfirmed = match.winner === "home";
    const awayWinConfirmed = match.winner === "away";
    const complete = homeWinConfirmed || awayWinConfirmed;

    return (
      <ListGroup.Item
        className={classNames("match", {
          homeWinning,
          awayWinning,
          homeWinConfirmed,
          awayWinConfirmed,
          complete
        })}
      >
        <Row className="d-flex">
          <Col
            className={classNames(
              "d-flex align-items-center justify-content-start",
              {
                winner: homeWinConfirmed,
                heartBeat: homeWinConfirmed,
                loser: awayWinConfirmed
              }
            )}
          >
            {homeWinConfirmed && <FontAwesomeIcon icon={faMedal} />}{" "}
            {match.homeName}
          </Col>
          <Col className="text-center lead px-0 col-auto d-flex align-items-center">
            {homeVotes} - {awayVotes}
          </Col>
          <Col
            className={classNames(
              "d-flex text-right align-items-center justify-content-end",
              {
                winner: awayWinConfirmed,
                heartBeat: awayWinConfirmed,
                loser: homeWinConfirmed
              }
            )}
          >
            {match.awayName}{" "}
            {awayWinConfirmed && <FontAwesomeIcon icon={faMedal} />}{" "}
          </Col>
        </Row>
        <ButtonGroup className="match-tracker mt-3">
          <Button
            className="home"
            onClick={this.handleHomeVote}
            disabled={userVotedHome || userVoting || complete}
          >
            <FontAwesomeIcon
              icon={userVotedHome ? faCheckSquare : faSquare}
              className={classNames({ "rubberBand animated": userVotingHome })}
            />
          </Button>
          <ProgressBar now={ratio} />
          <Button
            className="away"
            onClick={this.handleAwayVote}
            disabled={userVotedAway || userVoting || complete}
          >
            <FontAwesomeIcon
              icon={userVotedAway ? faCheckSquare : faSquare}
              className={classNames({ "rubberBand animated": userVotingAway })}
            />
          </Button>
        </ButtonGroup>
      </ListGroup.Item>
    );
  }

  private handleHomeVote = async () => {
    const { match, currentUser } = this.props;

    const userVotedAway = this.props.match.awayVotes.includes(currentUser);

    this.setState({
      userVoting: true,
      userVotingHome: true,
      homeVotes: match.homeVotes.length + 1,
      awayVotes: userVotedAway
        ? match.awayVotes.length - 1
        : match.awayVotes.length
    });

    await this.props.handleVote(this.props.match.id, "home");

    this.resumeTrackingVotes();
  };

  private handleAwayVote = async () => {
    const { match, currentUser } = this.props;

    const userVotedHome = match.homeVotes.includes(currentUser);

    this.setState({
      userVoting: true,
      userVotingAway: true,
      homeVotes: userVotedHome
        ? match.homeVotes.length - 1
        : match.homeVotes.length,
      awayVotes: match.awayVotes.length + 1
    });

    await this.props.handleVote(this.props.match.id, "away");

    this.resumeTrackingVotes();
  };

  private resumeTrackingVotes = () =>
    setTimeout(
      () =>
        this.setState({
          userVoting: false,
          userVotingHome: false,
          userVotingAway: false,
          homeVotes: this.props.match.homeVotes.length,
          awayVotes: this.props.match.awayVotes.length
        }),
      500
    );
}

export default ChampionshipMatch;
