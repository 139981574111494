import { auth, firestore } from "firebase";
import moment from "moment";
import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import ListGroup from "react-bootstrap/ListGroup";
import FlipMove from "react-flip-move";
import { RouteComponentProps } from "react-router";
import EmptyState from "../page/EmptyState";
import { convertSnapshotToType } from "../util/TypeUtils";
import ChampionshipsListItem from "./ChampionshipsListItem";
import { Championship } from "./Types";

interface Props extends RouteComponentProps {}

interface State {
  championships: ReadonlyArray<Championship>;
  championshipName?: string;
  loading?: boolean;
}

class ChampionshipsList extends React.PureComponent<Props, State> {
  private championshipsRef: firestore.CollectionReference = firestore().collection(
    "championships"
  );
  private unsubChampionships: () => void = () => {};

  constructor(props: Props) {
    super(props);

    this.state = {
      championships: []
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubChampionships = this.championshipsRef
      .orderBy("created", "desc")
      .limit(10)
      .onSnapshot(this.handleChampionshipsUpdate);
  }

  public componentWillUnmount() {
    this.unsubChampionships();
  }

  public render() {
    const { championshipName = "", championships, loading } = this.state;

    return (
      <Card className="my-3">
        <Card.Header as="h5">Championships</Card.Header>
        <Card.Header>
          <Form onSubmit={this.handleCreateChampionship}>
            <Form.Row>
              <Col>
                <Form.Control
                  placeholder="Championship name"
                  value={this.state.championshipName}
                  onChange={this.handleChampionshipNameChange}
                />
              </Col>
              <Col xs="auto">
                <Button type="submit" disabled={!championshipName}>
                  Create
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Card.Header>
        <EmptyState
          content={championships}
          loading={loading}
          message="No championships! Add one and lets get drunk."
        >
          <ListGroup variant="flush">
            <FlipMove typeName={null}>
              {championships.map(championship => (
                <ChampionshipsListItem
                  key={championship.id}
                  championship={championship}
                  onItemClick={this.handleChampionshipSelected}
                />
              ))}
            </FlipMove>
          </ListGroup>
        </EmptyState>
      </Card>
    );
  }

  private handleChampionshipsUpdate = (snapshot: firestore.QuerySnapshot) => {
    this.setState({
      loading: false,
      championships: snapshot.docs.map(doc =>
        convertSnapshotToType<Championship>(doc)
      )
    });
  };

  private handleCreateChampionship = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const currentUser = auth().currentUser;

    if (!currentUser) {
      // TODO: improve this by showing some UI
      return;
    }

    this.setState({ loading: true });

    const {
      championshipName = `Championship ${moment().format("lll")}`
    } = this.state;

    const now = new Date();
    const championshipRef = firestore()
      .collection("championships")
      .doc();

    const championship: Championship = {
      id: championshipRef.id,
      creator: currentUser.uid,
      creatorName: currentUser.displayName || "Unknown User",
      creatorPhotoURL: currentUser.photoURL,
      created: now,
      updated: now,
      name: championshipName,
      round: 0,
      locked: false
    };

    await championshipRef.set(championship);

    this.setState({
      loading: false,
      championshipName: ""
    });

    this.props.history.push(`${this.props.match.url}/${championshipRef.id}`);
  };

  private handleChampionshipNameChange = (
    event: React.FormEvent<
      ReplaceProps<"input", BsPrefixProps<"input"> & FormControlProps>
    >
  ) => this.setState({ championshipName: event.currentTarget.value });

  private handleChampionshipSelected = (championship: Championship) => {
    this.props.history.push(`${this.props.match.url}/${championship.id}`);
  };
}

export default ChampionshipsList;
