import {
  faBeer,
  faCheck,
  faCheckDouble,
  faClock,
  faHandHoldingUsd,
  faStar
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { firestore, User } from "firebase";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { LoadingCard } from "../page/Loading";
import { convertSnapshotToType, convertToMoment } from "../util/TypeUtils";
import CreateSessionCard from "./CreateSessionCard";
import SessionActions from "./SessionActions";
import "./Sessions.scss";
import { Session } from "./Types";

require("moment-duration-format")(moment);

interface Props {
  currentUser: User;
}

interface State {
  loading: boolean;
  expired: boolean;
  session?: Session;
  earnings: number;
  basicEarnings: number;
  shareEarnings: number;
}

class SessionsCard extends React.PureComponent<Props, State> {
  private unsubscribe?: () => void;
  private interval?: number;

  public constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      expired: false,
      earnings: 0,
      basicEarnings: 0,
      shareEarnings: 0
    };
  }

  public componentDidMount() {
    const { currentUser } = this.props;

    this.setState({ loading: true });
    this.unsubscribe = firestore()
      .collection("sessions")
      .where("userId", "==", currentUser.uid)
      .where("endTime", "==", null)
      .onSnapshot(snap => {
        if (snap.docs.length > 0) {
          this.setState({
            session: convertSnapshotToType<Session>(snap.docs[0]),
            expired: false
          });

          this.interval = window.setInterval(this.calculateEarnings, 10);
        } else {
          this.setState({ session: undefined });
        }

        this.setState({
          loading: false
        });
      });
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    window.clearInterval(this.interval);
  }

  public render() {
    const {
      loading,
      session,
      earnings,
      basicEarnings,
      shareEarnings
    } = this.state;

    if (loading) {
      return (
        <Card className="my-3">
          <LoadingCard />
        </Card>
      );
    }

    if (!session) {
      return <CreateSessionCard />;
    }

    const startTime = convertToMoment(session.startTime);
    const duration: any = moment.duration(
      Math.max(moment().diff(startTime), 1000)
    );

    const shareRateText = session.shareRate && Math.min(session.shareRate, 24);

    const doubleCoin = session && session.doubleCoin;
    const bonus = session && session.coinRate > 12;
    const shareholder = session && session.shareRate > 0;
    const checkedIn = session !== undefined;

    return (
      <Card
        className={classNames("sessions-card animated slow", "my-3", {
          doubleCoin,
          bonus,
          shareholder,
          checkedIn
        })}
      >
        <Card.Body>
          <Card.Title className="text-truncate">
            {session.locationName}{" "}
            {doubleCoin ? (
              <FontAwesomeIcon
                className="ml-1 text-primary animated zoomIn"
                icon={faCheckDouble}
              />
            ) : (
              <FontAwesomeIcon
                className="ml-1 text-success animated zoomIn"
                icon={faCheck}
              />
            )}
            {shareholder && (
              <FontAwesomeIcon
                className="ml-1 text-info animated zoomIn delay-1s"
                icon={faHandHoldingUsd}
              />
            )}
            {bonus && (
              <FontAwesomeIcon
                className="ml-1 text-secondary animated zoomIn delay-2s"
                icon={faStar}
              />
            )}
          </Card.Title>
          <Card.Subtitle className="mb-2">
            Earning {session.coinRate} coins per hour{" "}
            {session.doubleCoin && (
              <span className="doublecoin-text animated slow">× 2 bonus</span>
            )}
            {shareRateText > 0 && (
              <span className="text-muted">
                {" "}
                + {shareRateText} share coins per full hour
              </span>
            )}
          </Card.Subtitle>

          <Row className="py-3">
            <Col className="text-center h4 m-0">
              <FontAwesomeIcon icon={faClock} /> {duration.format()}
            </Col>
            <Col xs={session.shareRate ? 7 : 6} className="text-center h4 m-0">
              <FontAwesomeIcon icon={faBeer} /> {basicEarnings.toFixed(2)}
              {shareEarnings > 0 && (
                <small className="text-muted">
                  {" "}
                  + <FontAwesomeIcon icon={faBeer} /> {shareEarnings}
                </small>
              )}
            </Col>
          </Row>
        </Card.Body>
        <SessionActions session={session} earnings={earnings} />
      </Card>
    );
  }

  private calculateEarnings = () => {
    const { session } = this.state;

    if (!session) {
      return window.clearInterval(this.interval);
    }

    const startTime = convertToMoment(session.startTime);
    const duration = moment.duration(moment().diff(startTime));

    const basicEarnings = this.calculateBasicCoins(session, duration);
    const shareEarnings = this.calculateShareCoins(session, duration);

    this.setState({
      basicEarnings,
      shareEarnings,
      earnings: basicEarnings + shareEarnings
    });
  };

  private calculateBasicCoins(
    session: Session,
    duration: moment.Duration
  ): number {
    const basicEarnings = duration.asHours() * session.coinRate;

    if (session.doubleCoin) {
      return basicEarnings * 2;
    }

    return basicEarnings;
  }

  private calculateShareCoins(
    session: Session,
    duration: moment.Duration
  ): number {
    const coinsPerHour = Math.min(session.shareRate, 24);

    return Math.floor(duration.asHours()) * coinsPerHour;
  }
}

export default SessionsCard;
