import { faBeer, faPoundSign } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Table from "react-bootstrap/Table";
import "../../App.css";
import EmptyState from "../page/EmptyState";
import UserAvatar from "../page/UserAvatar";
import firebase from "../util/Firebase";
import history from "../util/History";
import { addCoinToLedger, parseDateTime } from "../util/Utils";
import "./Coin.css";
import CoinNav from "./CoinNav";
import PubCoin from "./PubCoin";
import PubCoinBasic from "./PubCoinBasic";

class CoinTrade extends Component {
  constructor(props) {
    super(props);

    this.ledgerRef = firebase.firestore().collection("ledger");
    this.tradesRef = firebase.firestore().collection("trades");
    this.usersRef = firebase.firestore().collection("users");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");

    this.state = {
      users: [],
      userMap: {},
      userAccountsMap: {},
      trades: [],
      userTransfer: "none",
      amount: "",
      valuation: "",
      coinRate: 0,
      loading: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribeTrades = this.tradesRef
      .orderBy("dateTime", "desc")
      .onSnapshot(this.onTradesUpdate);
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  componentWillUnmount() {
    this.unsubscribeTrades && this.unsubscribeTrades();
    this.unsubscribeUsers && this.unsubscribeUsers();
    this.userAccountsSubscription && this.userAccountsSubscription();
  }

  onUsersUpdate = querySnapshot => {
    if (this.props.match.params.uid === "undefined") {
      history.push("/auth");
    }

    const users = [];
    const userMap = {};

    querySnapshot.forEach(doc => {
      const {
        coin,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        updated
      } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        coin,
        updated
      };
      if (
        firebase.auth().currentUser &&
        firebase.auth().currentUser.uid !== doc.id
      ) {
        users.push(user);
      }
      userMap[user.id] = user;
    });

    this.setState({
      users: users.sort((a, b) =>
        a.updated && b.updated
          ? a.updated.seconds < b.updated.seconds
            ? 1
            : -1
          : a.updated
          ? -1
          : 1
      ),
      userMap
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccountsMap = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccountsMap[doc.id] = { displayName, photoURL };
      this.setState({
        userAccountsMap
      });
    });
  };

  onTradesUpdate = querySnapshot => {
    const trades = [];

    let totalCoin = 0;
    let totalPrice = 0;
    let totalCount = 0;

    querySnapshot.forEach(doc => {
      const { toUID, fromUID, coin, dateTime, value } = doc.data();
      trades.push({
        id: doc.id,
        toUID,
        fromUID,
        coin,
        dateTime,
        value
      });

      if (coin && value && totalCount < 3) {
        totalCoin = totalCoin + coin;
        totalPrice = totalPrice + value;
        totalCount++;
      }
    });

    console.log(totalCoin);
    console.log(totalPrice);

    this.setState({
      trades,
      loading: false,
      coinRate: Math.round(totalCoin / totalPrice)
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  transfer = e => {
    e.preventDefault();
    if (this.state.amount && this.state.amount > 0) {
      const toUser = this.state.userMap[this.state.userTransfer];
      const fromUser = this.state.userMap[firebase.auth().currentUser.uid];

      if (toUser.id && fromUser && +fromUser.coin - +this.state.amount >= 0) {
        this.usersRef.doc(toUser.id).update({
          coin: firebase.firestore.FieldValue.increment(
            Number(this.state.amount)
          )
        });

        this.usersRef.doc(fromUser.id).update({
          coin: firebase.firestore.FieldValue.increment(
            -Number(this.state.amount)
          )
        });

        this.addTrade(
          toUser.id,
          Number(this.state.amount),
          Number(this.state.valuation)
        );

        addCoinToLedger(
          this.ledgerRef,
          "Coin",
          "Transfer",
          "Trade",
          fromUser.id,
          +fromUser.coin,
          +this.state.amount,
          toUser.id
        );
      }
    }
    this.setState({
      userTransfer: "none",
      amount: ""
    });
  };

  addTrade = (toUID, coin, valuation) => {
    this.tradesRef.add({}).then(docRef => {
      const currentTime = Math.round(new Date().getTime() / 1000);
      const newTradeRef = this.tradesRef.doc(docRef.id);
      newTradeRef
        .set({
          id: docRef.id,
          fromUID: firebase.auth().currentUser.uid,
          toUID: toUID,
          coin: coin,
          dateTime: currentTime,
          value: valuation || 0
        })
        .catch(error => {
          console.error("Error creating trade: ", error);
        });
    });
    this.setState({
      userTransfer: "none",
      amount: "",
      valuation: ""
    });
  };

  getUserDisplayName = uid => {
    if (this.state.userMap[uid]) {
      return this.state.userMap[uid].displayName;
    }
    return "";
  };

  getUserPhotoURL = uid => {
    if (this.state.userMap[uid]) {
      return this.state.userMap[uid].photoURL;
    }
    return "";
  };

  getUserItemImage = uid => {
    if (this.state.userMap[uid]) {
      return this.state.userMap[uid].itemImage;
    }
    return "";
  };

  getDisplayNameClass = uid => {
    if (this.state.userMap[uid]) {
      if (
        this.state.userMap[uid].itemHat ||
        this.state.userMap[uid].isChampion
      ) {
        return "mt-m10";
      }
    }
    return "";
  };

  getDate(created) {
    if (+created > 0) {
      var date = new Date(0);
      date.setUTCSeconds(+created);
      return parseDateTime(new Date(date));
    }
    return "Unknown";
  }

  getUser = userId => {
    if (this.state.userMap[userId]) {
      if (this.state.userAccountsMap[userId]) {
        return {
          ...this.state.userMap[userId],
          displayName: this.state.userAccountsMap[userId].displayName,
          photoURL: this.state.userAccountsMap[userId].photoURL
        };
      }
      return this.state.userMap[userId];
    }
    return "";
  };

  getTradeValue = trade => {
    if (trade.value) {
      return "£" + trade.value;
    }
    return "£0";
  };

  render() {
    const { loading } = this.state;

    return (
      <>
        <CoinNav
          location={this.props.location.pathname}
          uid={this.props.match.params.uid}
          user={this.state.userMap[this.props.match.params.uid]}
        />

        <Card className="my-3">
          <Card.Header as="h5">Transfer</Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faBeer} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        name="amount"
                        value={this.state.amount}
                        onChange={this.onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Relative value</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faPoundSign} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        placeholder="0.00"
                        name="valuation"
                        value={this.state.valuation}
                        onChange={this.onChange}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      name="userTransfer"
                      value={this.state.userTransfer}
                      onChange={this.onChange}
                    >
                      <option value="none">Select recipent...</option>
                      {this.state.users.map(user => (
                        <option
                          key={user.id || user.uid}
                          value={user.id || user.uid}
                        >
                          {user.displayName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col className="col-auto">
                  <Button
                    onClick={this.transfer.bind(this)}
                    disabled={
                      !this.state.amount || this.state.userTransfer === "none"
                    }
                  >
                    Transfer
                  </Button>
                </Col>
              </Form.Row>
            </Form>
          </Card.Body>
          <Card.Footer className="text-right">
            Exchange rate: £1 = <PubCoin amount={this.state.coinRate} />
          </Card.Footer>
        </Card>
        <Card className="my-3">
          <Card.Header as="h5">Transfer ledger</Card.Header>
          <Card.Body>
            <EmptyState
              content={this.state.trades}
              loading={loading}
              message="Nobody has traded any coins yet, weird."
            >
              <Table responsive>
                <thead>
                  <tr>
                    <th className="">From</th>
                    <th className="">To</th>
                    <th className="text-right">
                      <FontAwesomeIcon icon={faBeer} /> Coins
                    </th>
                    <th className="d-none d-md-table-cell text-right align-middle">
                      <FontAwesomeIcon icon={faPoundSign} /> Value
                    </th>
                    <th className="d-none d-sm-table-cell text-right align-middle">
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.trades.map((trade, index) => (
                    <tr key={trade.id || index}>
                      <td>
                        <UserAvatar user={this.getUser(trade.fromUID)} />
                        <div>{this.getUser(trade.fromUID).displayName}</div>
                      </td>
                      <td>
                        <UserAvatar user={this.getUser(trade.toUID)} />
                        <div>{this.getUser(trade.toUID).displayName}</div>
                      </td>
                      <td className="text-right align-middle">
                        <PubCoinBasic amount={trade.coin} />
                      </td>
                      <td className="d-none d-md-table-cell text-right align-middle">
                        {this.getTradeValue(trade)}
                      </td>
                      <td className="d-none d-sm-table-cell text-right align-middle">
                        {this.getDate(trade.dateTime)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </EmptyState>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default CoinTrade;
