import React, { Component } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import "../../App.css";
import UserAvatar from "./UserAvatar";

class DisplayName extends Component {
  getDisplayNameClass(user) {
    if (user.itemHat || user.isChampion) {
      return "mt-m10";
    } else {
      return "";
    }
  }

  render() {
    const { user } = this.props;

    if (!user.uid && user.id) {
      user.uid = user.id;
    }

    return (
      <Row className="align-middle" style={{ minWidth: "180px" }}>
        <Col className="col-auto d-flex align-items-center pr-0">
          <UserAvatar user={user} photoURL={user.photoURL} />{" "}
        </Col>
        <Col className="d-flex align-items-center">
          {this.props.user.displayName}
        </Col>
      </Row>
    );
  }
}

export default DisplayName;
