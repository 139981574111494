import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faBeer,
  faCandyCane,
  faGift,
  faGifts,
  faHollyBerry,
  faSleigh
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Card from "react-bootstrap/Card";
import "./Loading.scss";

class Loading extends React.PureComponent {
  public render() {
    return (
      <span className="spinner m-3">
        <FontAwesomeIcon className="beer1" icon={this.randomIcon()} />
        <FontAwesomeIcon className="beer2" icon={this.randomIcon()} />
      </span>
    );
  }

  private randomIcon(): IconProp {
    const icons: ReadonlyArray<IconProp> = [
      faBeer,
      faSleigh,
      faGifts,
      faGift,
      faHollyBerry,
      faCandyCane

      // faGlassCheers,
      // faGlassMartini,
      // faGlassMartiniAlt,
      // faWineBottle,
      // faGlassWhiskey,
      // faCoffee,
      // faWineGlass,
      // faWineGlassAlt,
      // faCocktail
    ];

    return icons[Math.floor(Math.random() * icons.length)];
  }
}

export class LoadingCard extends React.PureComponent<{}, { show: boolean }> {
  public render() {
    return (
      <Card.Body className="d-flex justify-content-center align-items-center p-5 animated zoomIn slower">
        <Loading />
      </Card.Body>
    );
  }
}

export default Loading;
