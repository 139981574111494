// Firebase constants

// Prod
export const API_KEY = "AIzaSyBaGBRBCez5OKqxJ5127yk5dPQptAh78oA";
export const AUTH_DOMAIN = "championship-2201a.firebaseapp.com";
export const DATABASE_URL = "https://championship-2201a.firebaseio.com";
export const PROJECT_ID = "championship-2201a";
export const STORAGE_BUCKET = "championship-2201a.appspot.com";
export const MESSAGING_SENDER_ID = "554308375999";
export const APP_ID = "1:554308375999:web:3a4e2e6fea986ef2";
export const MEASUREMENT_ID = "G-B3HBPZWBKR";
export const FUNCTIONS_URL =
  "https://us-central1-championship-2201a.cloudfunctions.net";


// Test
// export const API_KEY = "AIzaSyC9N7hjJAooj5rwYZn-gdsw1W-p4UTmx5Y";
// export const AUTH_DOMAIN = "championship-test.firebaseapp.com";
// export const DATABASE_URL = "https://championship-test.firebaseio.com";
// export const PROJECT_ID = "championship-test";
// export const STORAGE_BUCKET = "championship-test.appspot.com";
// export const MESSAGING_SENDER_ID = "446551066986";
// export const APP_ID = "1:446551066986:web:64ecc081e2d97e0cd9fd13";
// export const FUNCTIONS_URL = "https://us-central1-championship-test.cloudfunctions.net";
// export const MEASUREMENT_ID = "G-B3HBPZWBKR";
// // export const FUNCTIONS_URL = "https://localhost:5001";

// General String constants
export const SITE_TITLE = "Pub Tracker";

// Lists of constants
export const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
export const DAY_NAMES = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];
export const ROUND_NAMES = [
  "Winner",
  "Final",
  "Semi Final",
  "Quarter Final",
  "Round of 16",
  "Round of 32",
  "Round of 64"
];
