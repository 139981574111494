import {
  faCog,
  faSignInAlt,
  faSignOutAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { firestore, User } from "firebase";
import React, { PureComponent } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import PubCoin from "../coin/PubCoin";
import { SITE_TITLE } from "../util/Constants";
import firebase from "../util/Firebase";
import history from "../util/History";

interface Props {}

interface State {
  currentUser?: User | null;
  userProfile?: { coin?: number; isAdmin?: boolean; uid?: string };
}

class Header extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public componentDidMount() {
    firebase.auth().onAuthStateChanged(async currentUser => {
      this.setState({ currentUser });

      if (!currentUser) {
        return;
      }

      const userProfile = {
        uid: currentUser.uid,
        displayName: currentUser.displayName,
        photoURL: currentUser.photoURL,
        updated: new Date()
      };

      const userAccountRef = firestore().doc(`users/${currentUser.uid}`);
      const userRef = firestore().doc(`users/${currentUser.uid}`);

      userAccountRef.set(userProfile, { merge: true });
      userRef.set(userProfile, { merge: true });

      firebase
        .firestore()
        .collection("users")
        .doc(currentUser.uid)
        .onSnapshot(this.handleUserUpdate);
    });
  }

  public render() {
    const { currentUser, userProfile } = this.state;

    return (
      <Navbar expand bg="light" variant="dark" sticky="top">
        <Container>
          <Navbar.Brand href="/">{SITE_TITLE}</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              {userProfile && userProfile.isAdmin && (
                <Nav.Item>
                  <Link to={`/admin/${userProfile.uid}`} className="nav-link">
                    <FontAwesomeIcon icon={faCog} spin />
                  </Link>
                </Nav.Item>
              )}
              {currentUser && (
                <Nav.Item>
                  <Link
                    to={`/profile/${currentUser.uid}/${currentUser.displayName}`}
                    className="nav-link"
                  >
                    {currentUser.displayName}
                  </Link>
                </Nav.Item>
              )}
              {userProfile && (
                <Nav.Item>
                  <Link to="/coin" className="nav-link">
                    <PubCoin amount={userProfile.coin || 0} />
                  </Link>
                </Nav.Item>
              )}
              {currentUser && (
                <Nav.Item>
                  <Button
                    onClick={this.logout}
                    className="nav-link"
                    variant="link"
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </Button>
                </Nav.Item>
              )}
              {!currentUser && (
                <Nav.Item>
                  <Link to="/auth" className="nav-link">
                    Login <FontAwesomeIcon icon={faSignInAlt} />
                  </Link>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  private logout = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        this.setState({ userProfile: undefined });
        history.push("/");
      });

  private handleUserUpdate = (snapshot: firestore.DocumentSnapshot) =>
    this.setState({ userProfile: snapshot.data() });
}

export default Header;
