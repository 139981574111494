import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import ExchangeNav from "./ExchangeNav";
import "./Exchange.css";
import { addCoinToLedger, getDate, getDateTime } from "../util/Utils";
import PubCoin from "../coin/PubCoin";
import PubCoinBasic from "../coin/PubCoinBasic";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class ExchangeShares extends Component {
  constructor(props) {
    super(props);

    this.globalRef = firebase.firestore().collection("global");
    this.exchangeRef = firebase.firestore().collection("exchange");
    this.userExchangeRef = firebase
      .firestore()
      .collection("users")
      .doc(this.props.match.params.uid)
      .collection("exchange");
    this.userRef = firebase
      .firestore()
      .collection("users")
      .doc(this.props.match.params.uid);
    this.ledgerRef = firebase.firestore().collection("ledger");

    this.unsubscribeUserExchange = null;
    this.unsubscribeExchange = null;
    this.unsubscribeUser = null;
    this.unsubscribeGlobal = null;

    this.state = {
      user: {},
      exchangeMap: {},
      userExchange: [],
      portfolioTotal: 0,
      rate: 0.01,
      lottoJackpot: 150,
      portfolioQuantity: 0,
      shareTaxBand1: 52,
      shareTaxBand2: 103
    };
  }

  componentDidMount() {
    this.unsubscribeExchange = this.exchangeRef.onSnapshot(
      this.onExchangeUpdate
    );
    this.unsubscribeUserExchange = this.userExchangeRef
      .orderBy("quantity", "desc")
      .onSnapshot(this.onUserExchangeUpdate);
    this.unsubscribeUser = this.userRef.onSnapshot(this.onUserUpdate);
    this.unsubscribeGlobal = this.globalRef.onSnapshot(this.onGlobalUpdate);
  }

  onExchangeUpdate = querySnapshot => {
    const exchangeMap = {};
    querySnapshot.forEach(doc => {
      const { name, price, quantity } = doc.data();
      if (name) {
        exchangeMap[doc.id] = {
          name,
          price,
          quantity
        };
      }
    });
    this.setState({
      exchangeMap
    });
  };

  onUserExchangeUpdate = querySnapshot => {
    const userExchange = [];
    let portfolioTotal = 0;
    let portfolioQuantity = 0;
    querySnapshot.forEach(doc => {
      const { stockId, quantity } = doc.data();
      userExchange.push({
        id: doc.id,
        stockId,
        quantity
      });
      portfolioQuantity = portfolioQuantity + quantity;
    });
    for (let userShare of userExchange) {
      portfolioTotal =
        portfolioTotal + +this.getSharePrice(userShare) * +userShare.quantity;
    }
    this.setState({
      userExchange,
      portfolioTotal,
      portfolioQuantity
    });
  };

  onUserUpdate = () => {
    let user = {};
    this.userRef.get().then(doc => {
      if (doc.data()) {
        const { coin, lastDividendDate } = doc.data();
        user = {
          id: doc.id,
          coin,
          lastDividendDate
        };
      }
      this.setState({
        user
      });
    });
  };

  onGlobalUpdate = querySnapshot => {
    let newLottoJackpot = 150;
    let newDividendRate = 0.01;
    let newShareTaxBand1 = 52;
    let newShareTaxBand2 = 103;

    querySnapshot.forEach(doc => {
      if (doc.id === "dividend") {
        const { rate } = doc.data();
        newDividendRate = rate;
      } else if (doc.id === "lotto") {
        const { lottoJackpot } = doc.data();
        newLottoJackpot = lottoJackpot;
      } else if (doc.id === "tax") {
        const { shareTaxBand1, shareTaxBand2 } = doc.data();
        newShareTaxBand1 = shareTaxBand1;
        newShareTaxBand2 = shareTaxBand2;
      }
    });
    this.setState({
      rate: newDividendRate,
      lottoJackpot: newLottoJackpot,
      shareTaxBand1: newShareTaxBand1,
      shareTaxBand2: newShareTaxBand2
    });
  };

  getShareName = share => {
    if (share) {
      return this.state.exchangeMap[share.id].name;
    }
    return "";
  };

  getSharePrice = share => {
    if (share && this.state.exchangeMap[share.id]) {
      return this.state.exchangeMap[share.id].price;
    }
    return "";
  };

  getTotal = share => {
    if (share) {
      return +this.getSharePrice(share) * +share.quantity;
    }
    return 0;
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  collectDividend = () => {
    if (this.isCollectEnabled()) {
      const userCoin = +this.state.user.coin;
      this.userRef.update({
        coin: firebase.firestore.FieldValue.increment(
          Number(this.getValueToCollect())
        ),
        lastDividendDate: Math.floor(Date.now() / 1000),
        portfolioQuantity: this.state.portfolioQuantity
      });
      if (+this.getValueToLotto() > 0) {
        this.globalRef.doc("lotto").update({
          lottoJackpot: this.state.lottoJackpot + +this.getValueToLotto()
        });
      }
      addCoinToLedger(
        this.ledgerRef,
        "Dividend",
        "Earned",
        "Coin",
        this.state.user.id,
        userCoin,
        +this.getValueToCollect(),
        0
      );
    }
  };

  getLastCollected = () => {
    if (this.state.user.lastDividendDate) {
      return getDateTime(this.state.user.lastDividendDate);
    }
    return "Never collected";
  };

  getValueToCollect = () => {
    if (this.state.portfolioTotal > 0) {
      let dailyTotal = this.state.portfolioTotal * this.getDividendRate();
      dailyTotal = Math.ceil(dailyTotal);

      //Temp ultra high taxation until shares are fixed
      dailyTotal = dailyTotal * 0.05;

      if (this.state.portfolioQuantity >= this.state.shareTaxBand2) {
        dailyTotal = dailyTotal * 0.0125;
      } else if (this.state.portfolioQuantity >= this.state.shareTaxBand1) {
        dailyTotal = dailyTotal * 0.025;
      }

      return 0;
    }
    return 0;
  };

  getValueToLotto = () => {
    if (this.state.user.lastDividendDate) {
      const lastCollection =
        Date.now() / 1000 - this.state.user.lastDividendDate;
      return Math.floor(lastCollection / 172800) * this.getValueToCollect();
    }
    return 0;
  };

  getDividendRate = () => {
    return this.state.rate;
  };

  getCollectButton = () => {
    if (this.isCollectEnabled()) {
      return (
        <button
          onClick={this.collectDividend.bind(this)}
          className={"btn btn-primary float-right btn-sm"}
        >
          Collect
        </button>
      );
    }
    return (
      <button className={"btn btn-secondary float-right btn-sm"} disabled>
        Collect
      </button>
    );
  };

  isCollectEnabled = () => {
    return (
      getDate(Date.now() / 1000) !== getDate(this.state.user.lastDividendDate)
    );
  };

  getTaxRate = () => {
    if (this.state.portfolioQuantity >= this.state.shareTaxBand2) {
      return "Band 2";
    } else if (this.state.portfolioQuantity >= this.state.shareTaxBand1) {
      return "Band 1";
    }
    return "";
  };

  getTaxRateClass = () => {
    if (this.state.portfolioQuantity >= this.state.shareTaxBand1) {
      return "d-block";
    }
    return "d-none";
  };

  getShareTax = () => {
    if (this.state.portfolioQuantity >= this.state.shareTaxBand2) {
      return " (-75%)";
    } else if (this.state.portfolioQuantity >= this.state.shareTaxBand1) {
      return " (-50%)";
    }
    return "";
  };

  render() {
    return (
      <div>
        <div className="panel panel-default">
          <ExchangeNav
            location={this.props.location.pathname}
            uid={this.props.match.params.uid}
            user={this.state.user}
          />
          <Card className="my-3">
            <Card.Body className="lead">
              Total shares
              <span className="float-right">
                {this.state.portfolioQuantity}
              </span>
            </Card.Body>
          </Card>
          <Card className={this.getTaxRateClass() + " my-3"}>
            <Card.Body className="lead">
              Tax rate
              <span className="float-right">{this.getTaxRate()}</span>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Header>
              <Row>
                <Col as="h5" className="mb-0">
                  Portfolio
                </Col>
                <Col className="col-auto">
                  <PubCoinBasic amount={this.state.portfolioTotal} />
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              <Table responsive size="sm">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-right">Price</th>
                    <th className="text-right">Quantity</th>
                    <th className="text-right">Value</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userExchange.map((stock, index) => (
                    <tr key={index}>
                      <td>{this.getShareName(stock)}</td>
                      <td className="text-right">
                        <PubCoin amount={this.getSharePrice(stock)} />
                      </td>
                      <td className="text-right">{stock.quantity}</td>
                      <td className="text-right">
                        <PubCoin amount={this.getTotal(stock)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Card className="my-3">
            <Card.Header as="h5">Dividends</Card.Header>
            <Card.Body>
              <Row>
                <Col className="align-right">Daily rate:</Col>
                <Col xs={5}>{this.getDividendRate() * 100}%</Col>
              </Row>
              <Row>
                <Col className="align-right">Coins to collect:</Col>
                <Col xs={5}>
                  <PubCoinBasic amount={this.getValueToCollect()} />
                  <div className={"share-tax-display"}>
                    {this.getShareTax()}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="align-right">Coins to lotto:</Col>
                <Col xs={5}>
                  <PubCoinBasic amount={this.getValueToLotto()} />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="d-flex align-items-center justify-content-between">
              <span>Last collected: {this.getLastCollected()}</span>
              {this.getCollectButton()}
            </Card.Footer>
          </Card>
        </div>
      </div>
    );
  }
}

export default ExchangeShares;
