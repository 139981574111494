import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";
import { addCoinToLedger } from "../util/Utils";

class Admin extends Component {
  constructor(props) {
    super(props);

    this.ledgerRef = firebase.firestore().collection("ledger");
    this.tradesRef = firebase.firestore().collection("trades");
    this.usersRef = firebase.firestore().collection("users");
    this.adminRef = this.usersRef.doc(this.props.match.params.uid);

    this.unsubscribeTrades = null;
    this.unsubscribeAdmin = null;

    this.state = {
      admin: {},
      users: [],
      userMap: {},
      trades: [],
      userTransfer: "none",
      amount: 0,
      lotteryAmount: 0,
      isAdmin: false
    };
  }

  componentDidMount() {
    this.unsubscribeTrades = this.tradesRef
      .orderBy("dateTime", "desc")
      .onSnapshot(this.onTradesUpdate);
    this.unsubscribeUsers = this.usersRef
      .orderBy("coin", "desc")
      .onSnapshot(this.onUsersUpdate);
    this.unsubscribeAdmin = this.adminRef.onSnapshot(this.onAdminUpdate);
  }

  onAdminUpdate = () => {
    this.adminRef.get().then(doc => {
      if (!doc.data() || (doc.data() && !doc.data().isAdmin)) {
        history.push("/");
      }
    });
    this.setState({
      isAdmin: true
    });
  };

  onUsersUpdate = querySnapshot => {
    const users = [];
    const userMap = {};
    let userCount = 0;
    querySnapshot.forEach(doc => {
      const {
        coin,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        isAdmin
      } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        isAdmin
      };
      if (+coin > -1) {
        userCount++;
        users.push(user);
      }
      userMap[user.id] = user;
    });
    this.setState({
      userCount,
      users,
      userMap
    });
  };

  onTradesUpdate = querySnapshot => {
    const trades = [];
    querySnapshot.forEach(doc => {
      const { toUID, fromUID, coin, dateTime } = doc.data();
      trades.push({
        id: doc.id,
        toUID,
        fromUID,
        coin,
        dateTime
      });
    });
    this.setState({
      trades
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  getDisplayName = userId => {
    if (this.state.userMap[userId]) {
      return this.state.userMapp[userId].displayName;
    }
    return "";
  };

  transfer = () => {
    if (this.state.isAdmin) {
      const toUser = this.state.userMap[this.state.userTransfer];

      if (toUser && +toUser.coin + +this.state.amount >= 0) {
        this.usersRef.doc(toUser.id).update({
          coin: firebase.firestore.FieldValue.increment(
            Number(this.state.amount)
          )
        });
        addCoinToLedger(
          this.ledgerRef,
          "Coin",
          "Transfer",
          "Admin",
          toUser.id,
          +toUser.coin,
          +this.state.amount,
          0
        );
      }

      alert("Adjusted balance of " + this.getDisplayName(toUser.id) + "!");

      this.setState({
        userTransfer: "none",
        amount: 0
      });
    }
  };

  getCoin = userId => {
    if (this.state.userMap[userId]) {
      return this.state.userMap[userId].coin;
    }
    return 0;
  };

  lottery = () => {
    if (this.state.isAdmin) {
      const random = Math.round(Math.random() * this.state.userCount - 1);
      const userSelected = this.state.users[random];

      this.usersRef.doc(userSelected.id).update({
        coin: firebase.firestore.FieldValue.increment(
          Number(this.state.lotteryAmount)
        )
      });
      addCoinToLedger(
        this.ledgerRef,
        "Coin",
        "Gambling",
        "Lottery",
        userSelected.id,
        +this.getCoin(userSelected.id),
        +this.state.lotteryAmount,
        0
      );

      alert("Lottery won by " + userSelected.displayName + "!");

      this.setState({
        lotteryAmount: 0
      });
    }
  };

  render() {
    return (
      <div>
        <div className="panel panel-default">
          <div className="card box">
            <div className="title h4">Transfer</div>
            <div>
              <div className="transfer-text"></div>
              <div className="transfer-amount-input">
                <input
                  type="number"
                  className="form-control"
                  name="amount"
                  value={this.state.amount}
                  onChange={this.onChange}
                  placeholder="0"
                />
              </div>
              <div className="transfer-text">to</div>
              <div className="transfer-user">
                <select
                  name="userTransfer"
                  value={this.state.userTransfer}
                  onChange={this.onChange}
                >
                  <option value="none">Select...</option>
                  {this.state.users.map(user => (
                    <option value={user.id}>{user.displayName}</option>
                  ))}
                </select>
              </div>
              <div className="transfer-button">
                <button
                  className="btn btn-primary float-right"
                  onClick={this.transfer.bind(this)}
                >
                  Transfer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="card box">
          <div className="title h4">Lottery</div>
          <div>
            <div className="transfer-text"></div>
            <div className="transfer-amount-input">
              <input
                type="number"
                className="form-control"
                name="lotteryAmount"
                value={this.state.lotteryAmount}
                onChange={this.onChange}
                placeholder="0"
              />
            </div>
            <div className="transfer-button">
              <button
                className="btn btn-primary float-right"
                onClick={this.lottery.bind(this)}
              >
                Lottery
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Admin;
