import React, { Component } from "react";
import "../../App.css";
import firebase from "../util/Firebase";
import ExchangeNav from "./ExchangeNav";
import "./Exchange.css";
import DisplayNameSpan from "../page/DisplayNameSpan";
import { addCoinToLedger, getDate } from "../util/Utils";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { faBeer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Col from "react-bootstrap/Col";

class ExchangeTrades extends Component {
  constructor(props) {
    super(props);

    this.ledgerRef = firebase.firestore().collection("ledger");
    this.exchangeTradesRef = firebase.firestore().collection("exchangeTrades");
    this.exchangeRef = firebase.firestore().collection("exchange");
    this.usersRef = firebase.firestore().collection("users");
    this.userExchangeRef = this.usersRef
      .doc(this.props.match.params.uid)
      .collection("exchange");
    this.userAccountsRef = firebase.firestore().collection("userAccounts");

    this.unsubscribeUserExchange = null;
    this.unsubscribeExchange = null;
    this.unsubscribeUsers = null;
    this.unsubscribeExchangeTrades = null;
    this.userAccountsSubscription = null;

    this.state = {
      exchangeTrades: {},
      exchangeMap: {},
      exchangeList: [],
      userShares: [],
      portfolioTotal: 0,
      userAccounts: {},
      userMap: {},
      quantity: 0,
      price: 0,
      type: "selling",
      shareToTrade: "none",
      sells: [],
      buys: [],
      userShareMap: {}
    };
  }

  componentDidMount() {
    this.unsubscribeExchange = this.exchangeRef.onSnapshot(
      this.onExchangeUpdate
    );
    this.unsubscribeUserExchange = this.userExchangeRef.onSnapshot(
      this.onUserExchangeUpdate
    );
    this.unsubscribeUsers = this.usersRef.onSnapshot(this.onUsersUpdate);
    this.unsubscribeExchangeTrades = this.exchangeTradesRef
      .orderBy("dateTime", "desc")
      .limit(200)
      .onSnapshot(this.onExchangeTradesUpdate);
    this.userAccountsSubscription = this.userAccountsRef.onSnapshot(
      this.onUserAccountsUpdate
    );
  }

  onExchangeUpdate = querySnapshot => {
    const exchangeMap = {};
    const exchangeList = [];
    querySnapshot.forEach(doc => {
      const { name, price, quantity } = doc.data();
      if (name) {
        exchangeList.push({
          id: doc.id,
          name,
          price,
          quantity
        });
        exchangeMap[doc.id] = {
          name,
          price,
          quantity
        };
      }
    });
    this.setState({
      exchangeMap,
      exchangeList
    });
  };

  onUserExchangeUpdate = querySnapshot => {
    const userShares = [];
    const userShareMap = {};
    querySnapshot.forEach(doc => {
      const { stockId, quantity } = doc.data();
      userShares.push({
        id: doc.id,
        stockId,
        quantity
      });
      userShareMap[doc.id] = {
        stockId,
        quantity
      };
    });
    this.setState({
      userShares,
      userShareMap
    });
  };

  onUsersUpdate = querySnapshot => {
    if (firebase.auth().currentUser) {
      this.setState({
        uid: firebase.auth().currentUser.uid
      });
    }

    const userMap = {};
    querySnapshot.forEach(doc => {
      const {
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        coin
      } = doc.data();
      const user = {
        id: doc.id,
        displayName,
        photoURL,
        isChampion,
        itemHat,
        itemImage,
        coin
      };
      userMap[user.id] = user;
    });
    this.setState({
      userMap
    });
  };

  onUserAccountsUpdate = querySnapshot => {
    const userAccounts = {};
    querySnapshot.forEach(doc => {
      const { displayName, photoURL } = doc.data();
      userAccounts[doc.id] = { displayName, photoURL };
      this.setState({
        userAccounts
      });
    });
  };

  onExchangeTradesUpdate = querySnapshot => {
    let sells = [];
    let buys = [];

    querySnapshot.forEach(doc => {
      const {
        active,
        completed,
        dateTime,
        price,
        quantity,
        type,
        uid,
        shareId
      } = doc.data();

      const trade = {
        id: doc.id,
        active,
        completed,
        dateTime,
        price,
        quantity,
        type,
        uid,
        shareId
      };

      if (type === "Buying" && !completed) {
        sells.push(trade);
      } else if (!completed) {
        buys.push(trade);
      }
    });

    buys = buys.sort((t1, t2) => {
      const ratio1 = t1.price;
      const ratio2 = t2.price;
      return ratio1 - ratio2;
    });

    sells = sells.sort((t1, t2) => {
      const ratio1 = t1.price;
      const ratio2 = t2.price;
      return ratio2 - ratio1;
    });

    this.setState({
      sells,
      buys
    });
  };

  getUserPortfolioQuantity = () => {
    let portfolioQuantity = 0;
    this.state.userShares.forEach(share => {
      console.log(share);
      portfolioQuantity = portfolioQuantity + share.quantity;
    });
    return portfolioQuantity;
  };

  getShareName = share => {
    if (share) {
      return this.state.exchangeMap[share.id].name;
    }
    return "";
  };

  getShareNameFromTrade = trade => {
    if (trade) {
      return this.state.exchangeMap[trade.shareId].name;
    }
    return "";
  };

  getSharePrice = share => {
    if (share && this.state.exchangeMap[share.id]) {
      return this.state.exchangeMap[share.id].price;
    }
    return "";
  };

  getTotal = share => {
    if (share) {
      return +this.getSharePrice(share) * +share.quantity;
    }
    return 0;
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  closeTrade = trade => {
    console.log(trade);
    this.exchangeTradesRef
      .doc(trade.id)
      .delete()
      .catch(error => {
        console.error("Error creating trade: ", error);
      });
  };

  getTradeButton = trade => {
    let tradeButtonText = "";
    if (trade.type === "Selling") {
      tradeButtonText = "Buy";
    } else {
      tradeButtonText = "Sell";
    }

    if (
      trade.uid === firebase.auth().currentUser.uid ||
      trade.completed === true
    ) {
      return (
        <button
          onClick={this.closeTrade.bind(this, trade)}
          className={"btn btn-danger"}
        >
          Close
        </button>
      );
    }

    return (
      <button
        onClick={this.completeTrade.bind(this, trade)}
        className={"btn btn-primary"}
      >
        {tradeButtonText}
      </button>
    );
  };

  getCreateButton = () => {
    return (
      <div className={"trade-add-button"}>
        <button
          onClick={this.addTrade.bind(this)}
          className={"btn btn-primary float-right"}
        >
          Create
        </button>
      </div>
    );
  };

  addTrade = () => {
    if (
      this.state.userShareMap &&
      this.state.quantity > 0 &&
      this.state.price > 0 &&
      this.state.shareToTrade !== "none" &&
      this.state.type !== "none"
    ) {
      if (
        this.state.type === "Buying" ||
        (this.state.userShareMap[this.state.shareToTrade] &&
          this.state.quantity <=
            this.state.userShareMap[this.state.shareToTrade].quantity)
      ) {
        this.exchangeTradesRef.add({}).then(docRef => {
          const newExchangeTradeRef = this.exchangeTradesRef.doc(docRef.id);
          newExchangeTradeRef
            .set({
              id: docRef.id,
              active: true,
              completed: false,
              dateTime: Math.round(new Date().getTime() / 1000),
              price: this.state.price,
              quantity: Number(this.state.quantity),
              type: this.state.type,
              uid: firebase.auth().currentUser.uid,
              shareId: this.state.shareToTrade
            })
            .catch(error => {
              console.error("Error creating trade: ", error);
            });
          this.setState({
            price: 0,
            quantity: 0,
            type: "none",
            shareToTrade: "none"
          });
        });
      }
    }
  };

  completeTrade = trade => {
    let fromUser = {};
    let toUser = {};

    this.usersRef.doc(this.props.match.params.uid).update({
      portfolioQuantity: this.getUserPortfolioQuantity()
    });

    if (trade.type === "Buying") {
      fromUser = this.state.userMap[this.props.match.params.uid];
      toUser = this.state.userMap[trade.uid];
    } else if (trade.type === "Selling") {
      fromUser = this.state.userMap[trade.uid];
      toUser = this.state.userMap[this.props.match.params.uid];
    }

    this.exchangeRef
      .doc(trade.shareId)
      .collection("users")
      .doc(fromUser.id)
      .get()
      .then(fromUserDoc => {
        if (
          fromUserDoc.exists &&
          trade.quantity <= fromUserDoc.data().quantity
        ) {
          const total = Math.round(trade.quantity * trade.price);

          if (total <= toUser.coin) {
            const fromUserCoin = +fromUser.coin;
            this.usersRef.doc(fromUser.id).update({
              coin: firebase.firestore.FieldValue.increment(Number(total))
            });
            addCoinToLedger(
              this.ledgerRef,
              "Share",
              "Trade",
              trade.id,
              fromUser.id,
              fromUserCoin,
              +total,
              toUser.id
            );

            const toUserCoin = +toUser.coin;
            this.usersRef.doc(toUser.id).update({
              coin: firebase.firestore.FieldValue.increment(-Number(total))
            });
            addCoinToLedger(
              this.ledgerRef,
              "Share",
              "Trade",
              trade.id,
              toUser.id,
              toUserCoin,
              +total,
              fromUser.id
            );

            const fromUserExchangeRef = this.usersRef
              .doc(fromUser.id)
              .collection("exchange")
              .doc(trade.shareId);
            fromUserExchangeRef.get().then(docSnapshot => {
              fromUserExchangeRef.update({
                quantity: docSnapshot.data().quantity - trade.quantity
              });
            });

            const toUserExchangeRef = this.usersRef
              .doc(toUser.id)
              .collection("exchange")
              .doc(trade.shareId);
            toUserExchangeRef.get().then(docSnapshot => {
              if (docSnapshot.exists) {
                toUserExchangeRef.update({
                  quantity: docSnapshot.data().quantity + trade.quantity
                });
              } else {
                toUserExchangeRef.set({
                  quantity: 1
                });
              }
            });

            const fromUserExchangeUserRef = this.exchangeRef
              .doc(trade.shareId)
              .collection("users")
              .doc(fromUser.id);
            fromUserExchangeUserRef.get().then(docSnapshot => {
              fromUserExchangeUserRef.update({
                quantity: docSnapshot.data().quantity - trade.quantity
              });
            });

            const toUserExchangeUserRef = this.exchangeRef
              .doc(trade.shareId)
              .collection("users")
              .doc(toUser.id);
            toUserExchangeUserRef.get().then(docSnapshot => {
              if (docSnapshot.exists) {
                toUserExchangeUserRef.update({
                  quantity: docSnapshot.data().quantity + trade.quantity
                });
              } else {
                toUserExchangeUserRef.set({
                  quantity: 1
                });
              }
            });

            this.exchangeTradesRef.doc(trade.id).update({
              completed: true
            });

            this.exchangeRef.doc(trade.shareId).update({
              previousPrice: +this.state.exchangeMap[trade.shareId].price,
              price: +trade.price
            });
          }
        }
      });
  };

  getUser = userId => {
    if (this.state.userMap[userId]) {
      if (this.state.userAccounts[userId]) {
        return {
          ...this.state.userMap[userId],
          displayName: this.state.userAccounts[userId].displayName,
          photoURL: this.state.userAccounts[userId].photoURL
        };
      }
      return this.state.userMap[userId];
    }
    return "";
  };

  getAddTradeBox = () => {
    if (this.state.userShares.length > 0) {
      return (
        <Card className="my-3">
          <Card.Header as="h5">Add trade</Card.Header>
          <Card.Body>
            <Form onSubmit={this.onSubmit}>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Share</Form.Label>
                    <Form.Control
                      as="select"
                      name="shareToTrade"
                      value={this.state.shareToTrade}
                      onChange={this.onChange}
                    >
                      <option value="none">Select...</option>
                      {this.state.exchangeList.map(share => (
                        <option value={share.id}>{share.name}</option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Type</Form.Label>
                    <Form.Control
                      as="select"
                      name="type"
                      value={this.state.type}
                      onChange={this.onChange}
                    >
                      <option value="none">Select...</option>
                      <option value="Buying">Buying</option>
                      <option value="Selling">Selling</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Price</Form.Label>
                    <InputGroup>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faBeer} />
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="number"
                        name="price"
                        value={this.state.price}
                        onChange={this.onChange}
                        placeholder="0"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="number"
                      name="quantity"
                      value={this.state.quantity}
                      onChange={this.onChange}
                      placeholder="0"
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              {this.getCreateButton()}
            </Form>
          </Card.Body>
        </Card>
      );
    }
    return "";
  };

  render() {
    return (
      <>
        <ExchangeNav
          location={this.props.location.pathname}
          uid={this.props.match.params.uid}
          user={this.state.userMap[this.state.uid]}
        />
        {this.getAddTradeBox()}
        <Card className="my-3">
          <Card.Header as="h5">Buys</Card.Header>
          <Card.Body>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th className="d-none d-sm-table-cell">Name</th>
                  <th>Share</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th className="d-none d-md-table-cell">Total</th>
                  <th className="d-none d-md-table-cell">Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.buys.map((trade, index) => (
                  <tr key={index}>
                    <td className="d-none d-sm-table-cell">
                      <DisplayNameSpan user={this.getUser(trade.uid)} />
                    </td>
                    <td>{this.getShareNameFromTrade(trade)}</td>
                    <td>{Number(trade.quantity)}</td>
                    <td>{trade.price}</td>
                    <td className="d-none d-md-table-cell">
                      {Number(trade.quantity) * trade.price}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {getDate(trade.dateTime)}
                    </td>
                    <td>{this.getTradeButton(trade)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Card className="my-3">
          <Card.Header as="h5">Sells</Card.Header>
          <Card.Body>
            <Table responsive size="sm">
              <thead>
                <tr>
                  <th className="d-none d-sm-table-cell">Name</th>
                  <th>Share</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th className="d-none d-md-table-cell">Total</th>
                  <th className="d-none d-md-table-cell">Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {this.state.sells.map((trade, index) => (
                  <tr key={index}>
                    <td className="d-none d-sm-table-cell">
                      <DisplayNameSpan user={this.getUser(trade.uid)} />
                    </td>
                    <td>{this.getShareNameFromTrade(trade)}</td>
                    <td>{Number(trade.quantity)}</td>
                    <td>{trade.price}</td>
                    <td className="d-none d-md-table-cell">
                      {Number(trade.quantity) * trade.price}
                    </td>
                    <td className="d-none d-md-table-cell">
                      {getDate(trade.dateTime)}
                    </td>
                    <td>{this.getTradeButton(trade)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default ExchangeTrades;
