import classNames from "classnames";
import React from "react";
import Col from "react-bootstrap/Col";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Row from "react-bootstrap/Row";
import { User } from "../../Types";
import UserAvatar from "../page/UserAvatar";
import "../sessions/Sessions.scss";
import { Session } from "../sessions/Types";
import { DisplayOptions } from "./Leaderboard";
import "./Leaderboard.scss";

interface Props {
  position: number;
  user: User;
  session?: Session;
  visits: number;
  display: DisplayOptions;
}

interface State {}

class VisitsRow extends React.PureComponent<Props, State> {
  public render() {
    const { position, user, session, visits } = this.props;
    const checkedIn = session !== undefined;

    return (
      <ListGroupItem className="leaderboard-row">
        <Row className={classNames("flex-nowrap")}>
          <Col className="col-auto d-flex align-items-center lead">
            {position}
          </Col>

          <Col className="col-auto d-flex align-items-center p-0">
            <UserAvatar user={user} photoURL={user.photoURL} />
          </Col>

          <Col className="d-flex flex-column align-items-start justify-content-center text-truncate">
            {user.displayName}
          </Col>

          <Col className="col-auto d-flex flex-column align-items-end justify-content-center text-truncate">
            {visits}
          </Col>
        </Row>
      </ListGroupItem>
    );
  }
}

export default VisitsRow;
