import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";

class TourAdd extends Component {
  constructor(props) {
    super(props);
    this.tourRef = firebase
      .firestore()
      .collection("tours")
      .doc(this.props.match.params.id);
    this.pubsRef = this.tourRef.collection("pubs");
    this.locationsRef = firebase.firestore().collection("locations");
    this.unsubscribeTour = null;
    this.unsubscribePubs = null;
    this.unsubscribeLocations = null;

    this.state = {
      tour: {},
      pubs: [],
      locations: [],
      locationMap: {},
      location: {}
    };
  }

  componentDidMount() {
    this.unsubscribeTour = this.tourRef.onSnapshot(this.onTourUpdate);
    this.unsubscribePubs = this.tourRef
      .collection("pubs")
      .orderBy("created", "asc")
      .onSnapshot(this.onPubsUpdate);
    this.unsubscribeLocations = this.locationsRef
      .orderBy("name", "asc")
      .onSnapshot(this.onLocationsUpdate);
  }

  onTourUpdate = doc => {
    const {
      created,
      creatorUid,
      creatorPhotoURL,
      title,
      date,
      kml,
      status
    } = doc.data();
    let tour = {
      id: doc.id,
      created,
      creatorUid,
      creatorPhotoURL,
      title,
      date,
      kml,
      status
    };
    this.setState({
      tour
    });
    if (tour.status === 0) {
      history.push("/tour/add/" + this.props.match.params.id);
    }
  };

  onLocationsUpdate = querySnapshot => {
    const locations = [];
    const locationMap = {};
    querySnapshot.forEach(doc => {
      const { id, name } = doc.data();
      locations.push({
        id: doc.id,
        name
      });
      locationMap[doc.id] = { id, name };
      this.setState({
        locations,
        locationMap
      });
    });
  };

  onPubsUpdate = querySnapshot => {
    const pubs = [];
    querySnapshot.forEach(doc => {
      const {
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        name
      } = doc.data();
      pubs.push({
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        name
      });
    });
    this.setState({
      pubs
    });
  };

  onTourChange = e => {
    const state = this.state;
    state.tour[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSaveTour = e => {
    e.preventDefault();
    this.tourRef
      .set(this.state.tour)
      .catch(error => {
        console.error("Error updating tour: ", error);
      })
      .then(function() {
        history.push("/tour");
      });
  };

  onPubChange = e => {
    this.setState({
      location: this.state.locationMap[e.target.value]
    });
  };

  addPub = e => {
    e.preventDefault();
    if (this.state.location) {
      const currentTime = Math.round(new Date().getTime() / 1000);

      this.pubsRef
        .doc(this.state.location.id)
        .set({
          id: this.state.location.id,
          creatorUid: firebase.auth().currentUser.uid,
          creatorName: firebase.auth().currentUser.displayName,
          creatorPhotoURL: firebase.auth().currentUser.photoURL,
          created: currentTime,
          name: this.state.location.name
        })
        .catch(error => {
          console.error("Error adding pub: ", error);
        });
    }
  };

  render() {
    let saveButton = "";
    if (
      firebase.auth().currentUser &&
      this.state.tour.title &&
      this.state.tour.date
    ) {
      saveButton = (
        <button type="submit" className="btn btn-primary">
          Save
        </button>
      );
    } else if (firebase.auth().currentUser) {
      saveButton = (
        <button className="btn btn-primary" disabled>
          Save
        </button>
      );
    } else {
      saveButton = (
        <button className="btn btn-primary" disabled>
          Please login
        </button>
      );
    }

    let addButton = "";
    if (firebase.auth().currentUser && this.state.location) {
      addButton = (
        <button type="submit" className="btn btn-primary">
          Add to end of tour
        </button>
      );
    } else if (firebase.auth().currentUser) {
      addButton = (
        <button className="btn btn-primary" disabled>
          Add
        </button>
      );
    } else {
      addButton = (
        <button className="btn btn-primary" disabled>
          Please login
        </button>
      );
    }

    return (
      <div>
        <div className="panel panel-default">
          <div className="card nav-bar">
            <div className="nav-bar-option-wrapper">
              <Link to={`/tour`}>
                <button className="btn btn-primary">Tours</button>
              </Link>
            </div>
          </div>
          <div className="card box">
            <label htmlFor="title">
              <div className="h4">Setup Tour</div>
            </label>
            <form onSubmit={this.onSaveTour}>
              <div className="form-group">
                <input
                  type="text"
                  required
                  className="form-control"
                  name="title"
                  value={this.state.tour.title}
                  onChange={this.onTourChange}
                  placeholder="Tour name"
                />
              </div>
              <div className="form-group">
                <input
                  type="date"
                  required
                  className="form-control"
                  name="date"
                  value={this.state.tour.date}
                  onChange={this.onTourChange}
                  placeholder="Tour date"
                />
              </div>
              <div className="form-group">
                <select
                  name="status"
                  className="form-control"
                  value={this.state.tour.status}
                  defaultValue="choose"
                  onChange={this.onTourChange}
                >
                  <option value="choose" disabled>
                    {" "}
                    Choose status
                  </option>
                  <option value="setup">Setup</option>
                  <option value="in_progress">In Progress</option>
                  <option value="finished">Finished</option>
                </select>
              </div>
              {saveButton}
            </form>
          </div>
          <div className="card box">
            <form onSubmit={this.addPub}>
              <div className="form-group">
                <label htmlFor="location">
                  <div className="h4">Add location</div>
                </label>
                <select
                  name="location"
                  defaultValue="choose"
                  className="form-control"
                  onChange={this.onPubChange}
                >
                  <option disabled value="choose">
                    Choose location
                  </option>
                  {this.state.locations.map(location => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
              {addButton}
            </form>
            <hr />
            <table className="table table-or">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Pub</th>
                  <th>Added by</th>
                </tr>
              </thead>
              <tbody>
                {this.state.pubs.map((pub, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{pub.name}</td>
                    <td>
                      <img
                        alt={pub.creatorName}
                        title={pub.creatorName}
                        className="user-img"
                        src={pub.creatorPhotoURL}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default TourAdd;
