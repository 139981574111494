import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import PubCoin from "../coin/PubCoin";
import { functions } from "firebase";
import { Session } from "./Types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  session: Session;
  earnings: number;
}

interface State {
  loading: {
    forfeit?: boolean;
    claim?: boolean;
    checkOut?: boolean;
  };
}

class SessionActions extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      loading: {}
    };
  }

  public render() {
    const { earnings } = this.props;
    const { loading } = this.state;

    const anyLoading = loading.forfeit || loading.claim || loading.checkOut;

    return (
      <Card.Footer className="align-right">
        <Button
          className="ml-auto"
          variant="danger"
          size="sm"
          onClick={this.forfeit}
          disabled={anyLoading}
        >
          Forfeit{" "}
          {loading.forfeit && <FontAwesomeIcon icon={faCircleNotch} spin />}
        </Button>
        <Button
          className="ml-2"
          variant="secondary"
          size="sm"
          onClick={this.claim}
          disabled={anyLoading}
        >
          Claim <PubCoin amount={Math.floor(earnings)} colours={false} />{" "}
          {loading.claim && <FontAwesomeIcon icon={faCircleNotch} spin />}
        </Button>
        <Button
          className="ml-2"
          size="sm"
          onClick={this.checkOut}
          disabled={anyLoading}
        >
          Check out{" "}
          {loading.checkOut && <FontAwesomeIcon icon={faCircleNotch} spin />}
        </Button>
      </Card.Footer>
    );
  }

  private forfeit = async () => {
    const { session } = this.props;

    try {
      this.setState({ loading: { forfeit: true } });
      await functions().httpsCallable("forfeit")({ sessionId: session.id });
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      this.setState({ loading: {} });
    }
  };

  private claim = async () => {
    const { session } = this.props;

    this.setState({ loading: { claim: true } });
    navigator.geolocation.getCurrentPosition(
      async location => {
        try {
          await functions().httpsCallable("claim")({
            sessionId: session.id,
            userLocation: {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            }
          });
        } catch (err) {
          console.error(err);
          alert(err.message);
        } finally {
          this.setState({ loading: {} });
        }
      },
      err => {
        alert(
          "Unable to get your location. Make sure you've enabled GPS! " +
            err.message
        );
        this.setState({ loading: {} });
      },
      {
        maximumAge: 1000,
        enableHighAccuracy: true
      }
    );
  };

  private checkOut = async () => {
    const { session } = this.props;

    this.setState({ loading: { checkOut: true } });
    navigator.geolocation.getCurrentPosition(
      async location => {
        try {
          await functions().httpsCallable("checkOut")({
            sessionId: session.id,
            userLocation: {
              latitude: location.coords.latitude,
              longitude: location.coords.longitude
            }
          });
        } catch (err) {
          console.error(err);
          alert(err.message);
        } finally {
          this.setState({ loading: {} });
        }
      },
      err => {
        alert(
          "Unable to get your location. Make sure you've enabled GPS! " +
            err.message
        );
        this.setState({ loading: {} });
      },
      {
        maximumAge: 1000,
        enableHighAccuracy: true
      }
    );
  };
}

export default SessionActions;
