import firebase from "../util/Firebase";
import firebaseui from "firebaseui";

const uiConfig = {
  signInSuccessUrl: "/auth",
  signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID, firebase.auth.FacebookAuthProvider.PROVIDER_ID],
  tosUrl: "/terms-of-service"
};

const firebaseUI = new firebaseui.auth.AuthUI(firebase.auth());

const startFirebaseUI = function(elementId) {
  firebaseUI.start(elementId, uiConfig);
};

firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    firebase
      .firestore()
      .collection("userAccounts")
      .where("uid", "==", user.uid)
      .get()
      .then(function(querySnapshot) {
        if (querySnapshot.size === 0) {
          firebase
            .firestore()
            .collection("userAccounts")
            .doc(user.uid)
            .set({
              uid: user.uid,
              email: user.email,
              displayName: user.displayName,
              photoURL: user.photoURL
            });
          firebase
            .firestore()
            .collection("users")
            .doc(user.uid)
            .set(
              {
                mapUserZoom: 7,
                mapUserCentreLon: -2.0,
                mapUserCentreLat: 52.25
              },
              { merge: true }
            );
        }
      });
  }
});

export default startFirebaseUI;
