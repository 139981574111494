import { faMapMarkerAlt, faTrophy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { firestore } from "firebase";
import moment from "moment";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/helpers";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import { convertSnapshotToType } from "../util/TypeUtils";
import CheckInButton from "./CheckInButton";
import { Location } from "./Types";

interface Props {}

interface State {
  loading: boolean;
  locations: ReadonlyArray<Location>;
  selectedLocation: string;
  locationInfo: string;
  locationOpen: boolean;
}

class CreateSessionCard extends React.PureComponent<Props, State> {
  private unsubscribe?: () => void;
  private interval?: number;

  public constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      locations: [],
      selectedLocation: "",
      locationInfo: "Please select a location...",
      locationOpen: false
    };
  }

  public componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = firestore()
      .collection("locations")
      .where(
        "updated",
        ">",
        moment()
          .utc()
          .subtract(3, "weeks")
          .toDate()
      )
      .orderBy("updated", "desc")
      .onSnapshot(snapshot => {
        this.setState({
          loading: false,
          locations: snapshot.docs.map(doc =>
            convertSnapshotToType<Location>(doc)
          ),
          selectedLocation:
            snapshot.docs.length > 0 && !this.state.selectedLocation
              ? snapshot.docs[0].id
              : this.state.selectedLocation
        });

        this.startLocationUpdates();
      });
  }

  public componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
    window.clearInterval(this.interval);
  }

  public render() {
    const {
      selectedLocation,
      locations,
      loading,
      locationInfo,
      locationOpen
    } = this.state;

    const selectedLocationDetail = this.getLocation();

    return (
      <Card>
        <Card.Body>
          <Card.Title>Check in</Card.Title>
          <Form>
            <Form.Row>
              <Col>
                <Form.Control
                  as="select"
                  value={selectedLocation}
                  onChange={this.handleChange}
                  disabled={loading}
                >
                  {loading && <option value="">Loading...</option>}
                  {locations.map(location => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </Form.Control>
                <small
                  className={classNames({
                    "text-success": locationOpen,
                    "text-muted": !locationOpen
                  })}
                >
                  {locationInfo}
                </small>
              </Col>
              <Col className="col-auto">
                <CheckInButton selectedLocation={selectedLocationDetail} />
              </Col>
            </Form.Row>
          </Form>
        </Card.Body>
        <Card.Footer>
          <Row>
            <Col className="text-truncate">
              <small>
                {selectedLocationDetail && selectedLocationDetail.vicinity && (
                  <a
                    title={String(selectedLocationDetail.vicinity)}
                    href={`http://www.google.com/maps?q=${selectedLocationDetail.name}+${selectedLocationDetail.vicinity}`}
                  >
                    <FontAwesomeIcon icon={faMapMarkerAlt} />{" "}
                    {String(selectedLocationDetail.vicinity)}
                  </a>
                )}
              </small>
            </Col>
            <Col className="col-auto ml-auto">
              <small>
                Not listed?{" "}
                <Link to="/championships">
                  Championship! <FontAwesomeIcon icon={faTrophy} />
                </Link>
              </small>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );
  }

  private getLocation = () =>
    this.state.locations.find(l => l.id === this.state.selectedLocation);

  private setLocationInfo = (location: Location) => {
    const day = Number(location.day);
    const time = Number(location.time);

    const now = moment.utc();
    const openFrom = now.clone().startOf("day");

    if (now.hour() < 6) {
      // It's before 6am so really it's still Thursday, right?
      openFrom.subtract(1, "days");
    }

    const openTo = openFrom
      .clone()
      .add(1, "days")
      .hours(6);

    if (day >= 0) {
      if (now.isoWeekday() > location.day) {
        openFrom.add(1, "weeks");
        openTo.add(1, "weeks");
      }

      openFrom.day(day);
      openTo.day(day + 1);
    }

    if (time >= 0) {
      openFrom.hours(time);
    }

    const locationOpen = now.isAfter(openFrom) && now.isBefore(openTo);
    if (time < 0 && day < 0) {
      this.setState({
        locationOpen,
        locationInfo: `Open every day at any time`
      });
    } else if (day < 0) {
      this.setState({
        locationOpen,
        locationInfo: locationOpen
          ? `Open every day from ${openFrom.format("h:mm a")} (now)`
          : `Open every day from ${openFrom.format(
              "h:mm a"
            )} (${openFrom.fromNow()})`
      });
    } else if (time < 0) {
      this.setState({
        locationOpen,
        locationInfo: locationOpen
          ? `Open any time today (now)`
          : `Opens ${openFrom.format(
              "dddd"
            )}s at any time (${openFrom.fromNow()})`
      });
    } else {
      this.setState({
        locationOpen,
        locationInfo: locationOpen
          ? `Open now!`
          : `Opens ${openFrom.calendar()} (${openFrom.fromNow()})`
      });
    }
  };

  private startLocationUpdates = () => {
    const location = this.getLocation();

    this.setState({ locationInfo: "Checking opening info..." });

    if (location) {
      this.setLocationInfo(location);
    }

    window.clearInterval(this.interval);
    window.setInterval(() => {
      const location = this.getLocation();

      if (location) {
        this.setLocationInfo(location);
      } else {
        this.setState({ locationInfo: "Please select a location..." });
        window.clearInterval(this.interval);
      }
    }, 1000);
  };

  private handleChange = (
    event: React.FormEvent<
      ReplaceProps<
        React.ElementType<any>,
        BsPrefixProps<React.ElementType<any>> & FormControlProps
      >
    >
  ) => {
    this.setState({ selectedLocation: event.currentTarget.value || "" });
    this.startLocationUpdates();
  };
}

export default CreateSessionCard;
