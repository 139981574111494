import {
  faCircleNotch,
  faCog,
  faTrophy
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { Championship } from "./Types";

interface Props {
  championship: Championship;
  onItemClick(championship: Championship): void;
}

class ChampionshipsListItem extends React.PureComponent<Props> {
  public render() {
    const { championship } = this.props;

    const created =
      championship.created instanceof Date
        ? championship.created
        : championship.created.toDate();

    return (
      <ListGroup.Item
        action
        onClick={this.handleItemClick}
        variant={
          championship.winnerName
            ? undefined
            : championship.round < 1
            ? "warning"
            : "info"
        }
      >
        <Row>
          <Col>
            <div>{championship.name}</div>
            <small>{moment(created).calendar()}</small>
          </Col>
          <Col className="col-auto">{this.getStatusText(championship)}</Col>
        </Row>
      </ListGroup.Item>
    );
  }

  private handleItemClick = () =>
    this.props.onItemClick(this.props.championship);

  private getStatusText(championship: Championship) {
    if (championship.winnerName) {
      return (
        <Row className="align-right">
          <Col>
            <div>
              <strong>
                <FontAwesomeIcon className="text-secondary" icon={faTrophy} />{" "}
                {championship.winnerName}
              </strong>
            </div>
            <small>Selected by {championship.winnerCreatorName}</small>
          </Col>
        </Row>
      );
    }

    if (championship.round < 1) {
      return (
        <Row className="align-right h-100">
          <Col>
            <div>Setup</div>
            <small>Add a pub</small>
          </Col>
          <Col className="col-auto d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faCog} spin />
          </Col>
        </Row>
      );
    }

    return (
      <Row className="align-right h-100">
        <Col>
          <div>In progress</div>
          <small>Round {championship.round}</small>
        </Col>
        <Col className="col-auto d-flex align-items-center justify-content-center">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </Col>
      </Row>
    );
  }
}

export default ChampionshipsListItem;
