import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../../App.css";
import firebase from "../util/Firebase";
import history from "../util/History";
import { validCheckIn } from "../util/Utils";
import { FUNCTIONS_URL } from "../util/Constants";

class TourView extends Component {
  constructor(props) {
    super(props);

    this.tourRef = firebase
      .firestore()
      .collection("tours")
      .doc(this.props.match.params.id);
    this.pubsRef = this.tourRef.collection("pubs");
    this.usersRef = firebase.firestore().collection("users");

    this.unsubscribeTour = null;
    this.unsubscribePubs = null;
    this.unsubscribeUser = null;

    this.state = {
      tour: {},
      pubs: [],
      user: {},
      checkingLocation: false
    };
  }

  componentDidMount() {
    var self = this;
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        self.unsubscribeUser = self.usersRef
          .doc(user.uid)
          .onSnapshot(self.onUserUpdate);
      }
    });
    this.unsubscribeTour = this.tourRef.onSnapshot(this.onTourUpdate);
    this.unsubscribePubs = this.pubsRef.onSnapshot(this.onPubsUpdate);
  }

  componentWillUnmount() {
    this.unsubscribeTour();
    this.unsubscribePubs();
  }

  onUserUpdate = docSnapshot => {
    const {
      uid,
      token,
      lastCheckInTime,
      lastCheckOutTime,
      lastCheckInLocationId,
      photoURL
    } = docSnapshot.data();
    let user = {
      uid,
      token,
      lastCheckInTime,
      lastCheckOutTime,
      lastCheckInLocationId,
      photoURL
    };
    this.setState({
      user
    });
  };

  onTourUpdate = doc => {
    const {
      created,
      creatorUid,
      creatorName,
      creatorPhotoURL,
      title,
      status,
      date,
      kml
    } = doc.data();
    let tour = {
      id: doc.id,
      created,
      creatorUid,
      creatorName,
      creatorPhotoURL,
      title,
      status,
      date,
      kml
    };
    this.setState({
      tour
    });
    if (tour.status === 0) {
      history.push("/tour/add/" + this.props.match.params.id);
    }
  };

  onPubsUpdate = querySnapshot => {
    const pubs = [];
    querySnapshot.forEach(doc => {
      const {
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        name,
        userPhotos
      } = doc.data();
      pubs.push({
        id,
        created,
        creatorUid,
        creatorName,
        creatorPhotoURL,
        name,
        userPhotos
      });
    });
    this.setState({
      pubs
    });
  };

  onChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);
  };

  onSubmit = e => {
    e.preventDefault();
  };

  couldCheckInHere = pub => {
    return (
      firebase.auth().currentUser &&
      !validCheckIn(
        this.state.user.lastCheckInTime,
        this.state.user.lastCheckOutTime
      )
    );
  };

  isCheckedInHere = pub => {
    return (
      this.state.user.lastCheckInLocationId === pub.id &&
      firebase.auth().currentUser &&
      validCheckIn(
        this.state.user.lastCheckInTime,
        this.state.user.lastCheckOutTime
      )
    );
  };

  isCheckedInElsewhere = pub => {
    return (
      this.state.user.lastCheckInLocationId !== pub.id &&
      firebase.auth().currentUser &&
      validCheckIn(
        this.state.user.lastCheckInTime,
        this.state.user.lastCheckOutTime
      )
    );
  };

  checkInButton = pub => {
    if (this.state.tour.status !== "in_progress") {
      return;
    } else if (this.state.checkingLocation) {
      return (
        <button type="submit" className="btn btn-primary" disabled>
          Checking...
        </button>
      );
    } else if (this.couldCheckInHere(pub)) {
      return (
        <button
          type="submit"
          className="btn btn-primary"
          onClick={() => this.checkIn(pub, "true")}
        >
          Check in
        </button>
      );
    } else if (this.isCheckedInHere(pub)) {
      return (
        <button
          className="btn btn-primary"
          onClick={() => this.checkIn(pub, "false")}
        >
          Check out
        </button>
      );
    } else if (this.isCheckedInElsewhere(pub)) {
      return (
        <button className="btn btn-primary" disabled>
          Check in
        </button>
      );
    } else {
      return (
        <button className="btn btn-primary" onClick={this.loginUser}>
          Please login
        </button>
      );
    }
  };

  loginUser() {
    history.push("/auth");
  }

  /*
   * Check user in
   *
   *  @paran pub the pub to check-in/out of
   *  @param checkIn true to check in or false to check out
   */
  checkIn = (pub, checkIn) => {
    this.setState({
      checkingLocation: true
    });
    navigator.geolocation.getCurrentPosition(location => {
      fetch(
        FUNCTIONS_URL +
          "/check" +
          "?cin=" +
          checkIn +
          "&loc=" +
          pub.id +
          "&uid=" +
          this.state.user.uid +
          "&lat=" +
          location.coords.latitude +
          "&lon=" +
          location.coords.longitude +
          "&tok=" +
          this.state.user.token
      )
        .then(res => res.json())
        .then(result => {
          if (result.distance === -1) {
            alert("Something has gone wrong, please try again later.");
          } else if (result.distance === -2) {
            alert("Please try again on the correct day.");
          } else if (result.distance === -3) {
            alert("Please try again at the correct time.");
          } else if (
            !result.checkSuccess &&
            validCheckIn(
              this.state.user.lastCheckInTime,
              this.state.user.lastCheckOutTime
            )
          ) {
            alert(
              "You're unable to checkout of your selected location, please move closer to it. You are " +
                Math.round(+result.distance * 1000) +
                " metres away."
            );
          } else if (
            !result.checkSuccess &&
            !validCheckIn(
              this.state.user.lastCheckInTime,
              this.state.user.lastCheckOutTime
            )
          ) {
            alert(
              "You're unable to check in to your selected location, please move closer to it. You are " +
                Math.round(+result.distance * 1000) +
                " metres away."
            );
          } else if (result.isCheckedIn && result.checkSuccess) {
            this.pubsRef
              .doc(pub.id)
              .collection("users")
              .doc(this.state.user.uid)
              .set({
                photoURL: this.state.user.photoURL
              });
          }
        })
        .catch(error => alert(error))
        .finally(() => {
          setTimeout(() => {
            this.setState({
              checkingLocation: false
            });
          }, 500);
        });
    });
  };

  render() {
    let mainDisplay = (
      <table className="table table-or">
        <thead>
          <tr>
            <th>&nbsp;</th>
            <th>Pub</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {this.state.pubs.map((pub, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{pub.name}</td>
              <td>{this.checkInButton(pub)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );

    return (
      <div>
        <div className="panel panel-default">
          <div className="card nav-bar">
            <div className="nav-bar-option-wrapper">
              <Link to={`/tour`}>
                <button className="btn btn-primary">Tours</button>
              </Link>
            </div>
          </div>
          <div className="card box">
            <div className="card-title h4">{this.state.tour.title}</div>
            {mainDisplay}
          </div>
        </div>
      </div>
    );
  }
}

export default TourView;
