import { Championship } from "./Types";
import Button from "react-bootstrap/Button";
import React from "react";
import { functions, auth } from "firebase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

interface Props {
  disabled?: boolean;
  championship: Championship;
}

interface State {
  loading?: boolean;
}

class NextRoundButton extends React.PureComponent<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { championship, disabled } = this.props;

    const currentUser = auth().currentUser;

    if (
      championship.winner ||
      !currentUser ||
      currentUser.uid !== championship.creator
    ) {
      return null;
    }

    return (
      <Button
        onClick={this.handleNextRound}
        disabled={disabled || this.state.loading}
      >
        {championship.round < 1 ? "Start championship" : "Next round"}{" "}
        {this.state.loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
      </Button>
    );
  }

  private handleNextRound = async () => {
    const { championship } = this.props;

    try {
      this.setState({ loading: true });
      await functions().httpsCallable("nextRound")({
        championshipId: championship.id
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    } finally {
      this.setState({ loading: false });
    }
  };
}

export default NextRoundButton;
